import styled from "styled-components";

export const TitleSearchWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 300px;
  margin-bottom: 10px;
  margin-left: 10px;
  .ant-input-search-button {
    background-color: #254060;
  }
`;
