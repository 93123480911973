import styled from "styled-components";

export const PatientFunnelViewWrapper = styled.div`
  .title-view {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    padding: 0px;
    display: flex;
    width: 100%;
    margin: 5px 0px 5px;
    align-items: center;
    justify-content: space-between;
    .ant-radio-button-wrapper {
      white-space: nowrap;
    }
  }
`;
export const CLLPatientsViewWrapper = styled.div`
  margin-top: 0px;
`;

export const CardViewWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100px;
  box-shadow: 0px 0px 20px #25405f2a;
  border-radius: 15px;
  margin: 10px;
  flex: 1;
  padding: 15px 10px 0px;
`;
