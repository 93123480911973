import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Popover, Radio, Row, Segmented, Spin, Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BarChart from "../../../components/RepApp/BarChart";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import {
  getIndicationType,
  getSelectedGeoTerriroties,
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import {
  CallReachChartWrapper,
  CardViewWrapper,
} from "./CallReachChart.styles";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const CallReachChart = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isReachChartPercent, setIsReachChartPercent] = useState(true);
  const [prescribersChartType, setPrescribersChartType] = useState(
    "ALL_TARGETS"
  );
  const [prescribersType, setPrescribersType] = useState("HCP");
  const [currChartData, setCurrChartData] = useState([]);
  var datesStrArr = [];
  if (datesStrArr && datesStrArr.length <= 0) {
    var todayDate = moment().format("YYYY-MM-DD");
    var startDate = moment().subtract(6, "month").format("YYYY-MM-DD");
    datesStrArr = [startDate, todayDate];
  }
  const [dateRange, setDateRange] = useState([
    moment(datesStrArr[0]).isValid() ? moment(datesStrArr[0], dateFormat) : "",
    moment(datesStrArr[1]).isValid() ? moment(datesStrArr[1], dateFormat) : "",
  ]);
  const [dateRangeStr, setDateRangeStr] = useState([
    datesStrArr[0],
    datesStrArr[1],
  ]);
  const dispatch = useDispatch();
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  useEffect(() => {
    getCallReachData();
    //setCurrChartData(callReachNumbers);
  }, [
    indicationType,
    dateRange,
    prescribersChartType,
    prescribersType,
    isReachChartPercent,
    selectedGeoTerriroties,
  ]);

  const getCallReachData = () => {
    setIsLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getCallReachData}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        isPercentage: isReachChartPercent,
        callReachType: prescribersChartType,
        dataType: prescribersType,
        fromDt: dateRangeStr[0],
        toDt: dateRangeStr[1],
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            var _data = response.data;
            var cData = [];
            _data.map((_dt) => {
              var cObj = {
                label: _dt.name,
                count: _dt.value,
                targetValue: _dt.targetValue
              };
              cData.push(cObj);
            });
            setCurrChartData(cData);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <CallReachChartWrapper>
      <CardViewWrapper>
        <Spin spinning={isLoading}>
          <Row>
            <Col xs={24}>
              <div className="title-container">
                <div className="title-view">
                  <Popover
                    placement="right"
                    content={
                      <div style={{ maxWidth: 400 }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: "Call execution against call plan targets.",
                          }}
                        ></p>
                      </div>
                    }
                    title={""}
                    trigger={"click"}
                  >
                    Call Reach (Any In-Person or Remote Detailing){" "}
                    <InfoCircleOutlined />
                  </Popover>
                </div>
                <Switch
                  checkedChildren="Percentage"
                  unCheckedChildren="Percentage"
                  defaultChecked={isReachChartPercent}
                  onChange={(checked) => {
                    setIsReachChartPercent(checked);
                  }}
                />
              </div>
            </Col>
            <Col xs={24}>
              <div className="callreach-toggle-container">
                <div className="date-picker-container">
                  <RangePicker
                    allowEmpty={[false, false]}
                    allowClear={false}
                    value={dateRange}
                    inputReadOnly={true}
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    onChange={(dates, datestr) => {
                      setDateRange(dates);
                      setDateRangeStr(datestr);
                    }}
                    format={dateFormat}
                  />
                </div>
                <div className="call-reach-type-tab">
                  <Segmented
                    style={{ fontSize: 14 }}
                    value={prescribersChartType}
                    onChange={(e) => {
                      setPrescribersChartType(e);
                    }}
                    options={[
                      {
                        label: "All Targets",
                        value: "ALL_TARGETS",
                      },
                      {
                        label: "Focused Accounts",
                        value: "FOCUS_ACCOUNTS",
                      },
                    ]}
                  />
                  {/*<Radio.Group
                    value={prescribersChartType}
                    onChange={(e) => {
                      setPrescribersChartType(e.target.value);
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="ALL_TARGETS">All Targets</Radio.Button>
                    <Radio.Button value="FOCUS_ACCOUNTS">
                      Focused Accounts
                    </Radio.Button>
                  </Radio.Group>*/}
                  <Radio.Group
                    value={prescribersType}
                    onChange={(e) => {
                      setPrescribersType(e.target.value);
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="HCP">HCPs</Radio.Button>
                    <Radio.Button value="ACCOUNT">Accounts</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <BarChart
                chartData={currChartData}
                isPercent={isReachChartPercent}
              />
            </Col>
          </Row>
        </Spin>
      </CardViewWrapper>
    </CallReachChartWrapper>
  );
};
export default CallReachChart;
