import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import {
  getIndicationType,
  getSelectedGeoTerriroties,
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
var segmentation_chart = null;
const AccountSegmentationChart = (props) => {
  const { type, ...restprops } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [segmentation, setSegmentation] = useState("");
  const [priority, setPriority] = useState("");
  let { accountId } = useParams();
  const CHART_ID = "accountsegmentationChart";
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  const sbChart = useRef(null);
  useEffect(() => {
    getSegmentation();
  }, [indicationType, selectedGeoTerriroties]);

  useEffect(() => {
    return () => {
      if (segmentation_chart) {
        segmentation_chart.dispose();
      }
    };
  }, []);

  const getSegmentation = () => {
    var url = ``;
    var data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
    };
    if (type == "parent") {
      url = `${CONFIG.api.getParentSegmentation}`;
      data.parent_id = accountId;
    } else {
      url = `${CONFIG.api.getTopParentPriority}`;
      data.top_parent_az_id = accountId;
    }
    PostAPIRequest({
      url: `${url}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            setSegmentation(response);
            loadSBChart(response);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const loadSBChart = (response) => {
    am4core.useTheme(am4themes_animated);
    if (segmentation_chart) {
      segmentation_chart.dispose();
    }
    segmentation_chart = am4core.create(CHART_ID, am4charts.XYChart);
    segmentation_chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    segmentation_chart.maskBullets = false;

    let xAxis = segmentation_chart.xAxes.push(new am4charts.CategoryAxis());
    let yAxis = segmentation_chart.yAxes.push(new am4charts.CategoryAxis());
    if (type == "top_parent") {
      xAxis.dataFields.category = "mclPriority";
      yAxis.dataFields.category = "cllPriority";
    } else {
      xAxis.dataFields.category = "segmentation";
      yAxis.dataFields.category = "priority";
    }

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 10;
    xAxis.renderer.labels.template.rotation = -90;
    xAxis.renderer.labels.template.horizontalCenter = "right";
    xAxis.renderer.labels.template.verticalCenter = "middle";

    if (type == "top_parent") {
      xAxis.title.fontWeight = 800;
      xAxis.title.text = "MCL Priority";
      xAxis.title.fontWeight = "bold";
      xAxis.title.fill = "#013765";
      yAxis.title.fontWeight = 800;
      yAxis.title.text = "CLL Priority";
      yAxis.title.fontWeight = "bold";
      yAxis.title.fill = "#013765";
    }

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 10;

    let series = segmentation_chart.series.push(new am4charts.ColumnSeries());
    if (type == "top_parent") {
      series.dataFields.categoryX = "mclPriority";
      series.dataFields.categoryY = "cllPriority";
    } else {
      series.dataFields.categoryX = "segmentation";
      series.dataFields.categoryY = "priority";
    }

    series.dataFields.value = "value";
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;

    // Set up column appearance
    let column = series.columns.template;
    column.strokeWidth = 1;
    column.strokeOpacity = 1;
    column.stroke = am4core.color("#999999");
    //column.tooltipText = "{x}, {y}: {value.workingValue.formatNumber('#.')}";
    column.width = am4core.percent(100);
    column.height = am4core.percent(100);
    column.column.cornerRadius(0, 0, 0, 0);
    column.propertyFields.fill = "color";

    // Set up bullet appearance
    let bullet1 = series.bullets.push(new am4charts.CircleBullet());
    //bullet1.circle.propertyFields.radius = "value";
    bullet1.circle.fill = am4core.color("#000");
    bullet1.circle.radius = 30;
    bullet1.circle.strokeWidth = 0;
    bullet1.circle.fillOpacity = 0.8;
    bullet1.interactionsEnabled = false;
    bullet1.circle.propertyFields.fill = "bubbleColor";

    let bullet2 = series.bullets.push(new am4charts.LabelBullet());
    bullet2.label.text = "{value}";
    bullet2.label.fill = am4core.color("#fff");
    bullet2.zIndex = 1;
    bullet2.fontSize = 11;
    bullet2.interactionsEnabled = false;

    var gradient = new am4core.RadialGradient();
    gradient.addColor(am4core.color("#7C67E9"));
    gradient.addColor(am4core.color("#594D99"));
    gradient.addColor(am4core.color("#7C67E9"));
    gradient.cx = am4core.percent(0);
    gradient.cy = am4core.percent(100);
    var gradient1 = new am4core.LinearGradient();
    gradient1.addColor(am4core.color("#FFFFFF"));
    gradient1.addColor(am4core.color("#FFFFFF"));

    response.map((p, idx) => {
      if (p.value > 0) {
        p.color = "#EBE6FF";
        p.bubbleColor = gradient;
      } else {
        p.color = "#ffffff";
        p.bubbleColor = gradient1;
      }
    });
    segmentation_chart.data = response;
    let baseWidth = Math.min(
      segmentation_chart.plotContainer.maxWidth,
      segmentation_chart.plotContainer.maxHeight
    );
    let maxRadius =
      baseWidth / Math.sqrt(segmentation_chart.data.length) / 2 - 2; // 2 is jast a margin
    series.heatRules.push({
      min: 10,
      max: maxRadius,
      propertpriority: "radius",
      target: bullet1.circle,
    });

    segmentation_chart.plotContainer.events.on("maxsizechanged", function () {
      let side = Math.min(
        segmentation_chart.plotContainer.maxWidth,
        segmentation_chart.plotContainer.maxHeight
      );
      bullet1.circle.clones.each(function (clone) {
        clone.scale = side / baseWidth;
      });
    });
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <div
        id={CHART_ID}
        style={{
          width: "100%",
          height: "460px",
        }}
      ></div>
    </Spin>
  );
};

export default AccountSegmentationChart;
