import styled from "styled-components";
export const HBarLegend = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-top: -20px;
`;
export const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  .legend-bullet {
    width: 16px;
    height: 16px;
    border-radius: 13px;
    background: #000000;
  }
  .legend-label {
    margin-left: 5px;
    color: #606060;
  }
`;
