import styled from "styled-components";

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 90px 0px 40px;
  flex-wrap: wrap;
  background: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
  .ant-table {
    tr:nth-child(even) {
      background-color: #ffffff !important;
    }
    tr > td {
      padding: 10px 14px;
      overflow-wrap: normal;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px;
  padding: 0px 0px;
  flex-wrap: wrap;
  background: #ffffff;
  .site-layout-background {
    background: #e3ebf0;
    border-top-right-radius: 0px;
    border: 1px solid #eeeeee;
    padding: 60px 10px;
    color: #3e3e3e;
    .menu-header {
      font-size: 20px;
      padding: 10px;
      font-weight: bold;
    }
    .pending {
      color: #f85c1e;
      font-weight: bold;
    }
    .completed {
      color: #028140;
      font-weight: bold;
    }
    .expired {
      color: #b10000;
      font-weight: bold;
    }
  }
  .site-layout-background ul li {
    margin: 0px !important;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
  }
  .content-table {
    flex: 1;
    width: 100%;
    padding: 10px 0px;
    svg {
      font-size: 12px;
    }
    td svg {
      font-size: 20px;
    }
  }
  .content-search {
    max-width: 200px;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;

    .title {
      font-size: 14px;
      color: #ffffff;
    }
    .count {
      color: #ffffff;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // border-radius: 16px;
      // border: 1px solid #eeeeee;
      // background: #ffffff;
      // width: 30px;
      // height: 22px;
    }
  }
`;

export const FunnelWrapper = styled.div`
  margin: 10px;
  padding: 40px 10px;
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  #funnel {
    width: 900px;
    max-width: 900px;
  }
`;
export const FilterWrapper = styled.div`
  margin: 5px 10px;
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  .filterBox {
    margin: 0px 10px;
    width: 200px;
  }
`;

export const TopViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 10px 10px;
  .content-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .content-form-item {
    margin: 10px 10px;
    .ant-form-item-control-input {
      width: 200px;
    }
  }
`;

export const TableButtonWrapper = styled.button`
  background-color: #204b73;
  /* background: #3BA2D6; */
  width: 100%;
  border-radius: 5px;
  border: none;
  text-align: center;
  color: #ffffff;
  border-color: #2c6c8f;
`;
