import { Col, Layout, Row, Spin, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import UnauthorisedErrorView from "../../components/UnauthorisedErrorView/UnauthorisedErrorView";
import CONFIG from "../../constants";
import { GetAPIRequest } from "../../services";
import {
  setTriggers,
  setTriggerType,
  updateTriggerIdx,
} from "../../store/triggerSlice";
import { checkUnauthorised } from "../../utils/cookie-helper";
import { HomeWrapper, TableButtonWrapper } from "./Home.styles";

const { TabPane } = Tabs;

const Home = (props) => {
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  var chart = null;
  const [sortedInfo, setSortedInfo] = useState({});
  const [columns, setColumns] = useState([]);

  const [triggerCount, setTriggerCount] = useState({});
  const [triggerObject, setTriggerObject] = useState([]);
  const [isUnauthorised, setIsUnauthorised] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [triggerList, setTriggerList] = useState([]);
  const [triggerData, setTriggerData] = useState({});
  const [isProductVisible, setIsProductVisible] = useState(true);
  const [isStatusDescColumn, setIsStatusDescColumn] = useState(true);
  const [status, setStatus] = useState("Pending");
  const [isLoading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _response = {
    errorCode: 401,
    errorMessage:
      "You are not authorised to access this page. Please contact the AZBrain support team ",
    supportEmail: "support@azbrain.com",
  };
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    getTriggersCount();
  }, []);
  useEffect(() => {
    getTriggersList(status);
  }, [status]);

  useEffect(() => {
    getColumns();
  }, [isStatusDescColumn, sortedInfo]);

  const getTriggersCount = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getTriggersCount}`,
    }).then((response) => {
      if (response) {
        var r = checkUnauthorised(response);
        if (r.status) {
          setIsUnauthorised(true);
          setErrorMessage(r.message);
        } else {
          let triggerObject = Object.keys(response);
          setTriggerObject(triggerObject);
          setTriggerCount(response);
        }
      }
    });
  };

  const getTriggersList = (type) => {
    setLoading(true);
    GetAPIRequest({
      url: `${CONFIG.api.getTriggersList}?type=${type}`,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (r.status) {
            setIsUnauthorised(true);
            setErrorMessage(r.message);
          } else {
            if (response) {
              if (response.data) {
                setTriggerList(response.data);

                dispatch(setTriggers(response.data));
                dispatch(setTriggerType(type));
                setTriggerData(response);
                setIsProductVisible(response.isProductColumn);
                setIsStatusDescColumn(response.isStatusDescColumn);
              }
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getItemsByType = (key) => {
    var k = triggerObject[key];
    var _k = k[0].toUpperCase() + k.substring(1);
    setStatus(_k);
    setActiveIndex(key);
  };
  const isNullOrEmpty = (str) => {
    return str == null || str == "";
  };

  const getColumns = (type) => {
    var _columns = [
      {
        title: "Provider",
        dataIndex: "provider",
        key: "provider",
        sorter: (a, b) => a.provider.localeCompare(b.provider),
        sortDirections: ["ascend", "descend"],
        sortOrder: sortedInfo.columnKey === "provider" && sortedInfo.order,
      },
      {
        title: "Primary Affiliation",
        dataIndex: "affiliations",
        key: "affiliations",
        sorter: (a, b) => {
          return isNullOrEmpty(a.affiliations)
            ? isNullOrEmpty(b.affiliations)
              ? 0
              : 1
            : isNullOrEmpty(b.affiliations)
            ? -1
            : a.affiliations.localeCompare(b.affiliations);
        },
        sortDirections: ["ascend", "descend"],
        sortOrder: sortedInfo.columnKey === "affiliations" && sortedInfo.order,
      },
    ];
    if (isProductVisible) {
      _columns.push({
        title: "Product",
        dataIndex: "product",
        key: "product",
        sortDirections: ["ascend", "descend"],
        onCell: (record) => ({ style: { color: "#e44404" } }),
        render: (text, record) => {
          return <div>{text}</div>;
        },
        sorter: (a, b) => a.product.localeCompare(b.product),
        sortOrder: sortedInfo.columnKey === "product" && sortedInfo.order,
      });
    }

    _columns.push(
      {
        title: "Indication",
        dataIndex: "indication",
        key: "indication",
        sorter: (a, b) => a.indication.localeCompare(b.indication),
        sortOrder: sortedInfo.columnKey === "indication" && sortedInfo.order,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Type",
        dataIndex: "trigger_type",
        key: "trigger_type",
        sorter: (a, b) => a.trigger_type.localeCompare(b.trigger_type),
        sortOrder: sortedInfo.columnKey === "trigger_type" && sortedInfo.order,
        sortDirections: ["descend", "ascend"],
        render: (text, record) => {
          if (record.trigger_type && record.trigger_type != "") {
            return text;
          } else {
            return "ELIGIBILITY";
          }
        },
      }
    );
    if (isStatusDescColumn) {
      _columns.push({
        title: "Reason",
        dataIndex: "statusDesc",
        key: "statusDesc",
        render: (text, record) => {
          return <div style={{ wordBreak: "keep-all" }}>{text}</div>;
        },
      });
    }

    _columns.push(
      {
        title: "Due Date",
        dataIndex: "dueDate",
        key: "dueDate",
        sortDirections: ["descend", "ascend"],
        width: 130,
        sorter: (a, b) => {
          var a1 = new Date(a.dueDate);
          var b1 = new Date(b.dueDate);
          if (sortedInfo.order == "ascend") {
            return a1 < b1;
          } else {
            return a1 > b1;
          }
        },

        sortOrder: sortedInfo.columnKey === "dueDate" && sortedInfo.order,
      },
      {
        title: "",
        key: "operation",
        width: 100,
        render: (data, record, index) => (
          <TableButtonWrapper
            onClick={() => {
              dispatch(updateTriggerIdx(index));
              navigate(
                `/trigger/${record.uniqueId}/${record.providerId}/${record.dueDate}/${record.status}/${index}`
              );
            }}
          >
            View
          </TableButtonWrapper>
        ),
      }
    );
    setColumns(_columns);
  };

  function onChange(pagination, filters, sorter, extra) {
    setSortedInfo(sorter);
  }

  return (
    <>
      {isUnauthorised ? (
        <UnauthorisedErrorView message={errorMessage} />
      ) : (
        <>
          <Header
            hideIndications={true}
            triggerData={triggerObject}
            triggerCount={triggerCount}
            triggerValue={activeIndex}
            onTriggerTypeChange={getItemsByType}
          />
          <HomeWrapper>
            <Spin spinning={isLoading}>
              <Layout>
                <Row>
                  <Col
                    className="content home-content"
                    style={{ width: "100%" }}
                  >
                    {/*<Tabs value={activeIndex} onChange={getItemsByType}>
                      {triggerObject.length > 0 &&
                        triggerObject.map((item, index) => {
                          return (
                            <TabPane
                              tab={
                                item.toUpperCase() +
                                " (" +
                                triggerCount[item] +
                                ")"
                              }
                              key={index}
                            />
                          );
                        })}
                    </Tabs>*/}
                    <Table
                      style={{ width: width - 20 }}
                      scroll={{ x: width - 20 }}
                      showSorterTooltip={false}
                      rowKey={(record) => record.uniqueId}
                      className="content-table"
                      columns={columns}
                      dataSource={triggerList}
                      onChange={onChange}
                      pagination={false}
                      sticky={true}
                    />
                  </Col>
                </Row>
              </Layout>
            </Spin>
          </HomeWrapper>
          <Footer
            style={{ position: "absolute", bottom: 0, right: 0, left: 0 }}
          />
        </>
      )}
    </>
  );
};

export default Home;
