import styled from "styled-components";

export const CallReachChartWrapper = styled.div`
  .callreach-toggle-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0px;
  }
  .title-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .ant-switch {
      width: 120px;
    }
  }
  .title-view {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    padding: 0px;
    display: flex;
    width: 100%;
    margin: 0px 0px 5px;
    align-items: center;
    justify-content: space-between;
    .ant-radio-button-wrapper {
      white-space: nowrap;
    }
  }
  .callreach-toggle-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .date-picker-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    min-width: 220px;
    margin: 5px 0px;
    .ant-picker-range {
      padding: 4px;
    }
    .ant-picker-range-separator {
      padding: 0px;
    }
    .ant-picker-input > input {
      max-width: 85px;
    }
    .ant-picker-range .ant-picker-active-bar {
      margin-left: 0px;
    }
  }
  .call-reach-type-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px 0px;
    span {
      white-space: nowrap;
    }
    .ant-radio-group {
      margin-left: 5px;
    }
  }
`;

export const CardViewWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100px;
  box-shadow: 0px 0px 20px #25405f2a;
  border-radius: 15px;
  margin: 10px;
  flex: 1;
  padding: 15px 10px 0px;
`;
