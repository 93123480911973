import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import triggerReducer from "./triggerSlice";

export const store = configureStore({
  reducer: {
    trigger: triggerReducer,
    app: appSlice,
  },
});
