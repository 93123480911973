import styled from "styled-components";

export const PerformanceChartWrapper = styled.div`
  padding-bottom: 15px;
  padding-top: 15px;
  .ant-table-column-title,
  th.ant-table-cell,
  .ant-table-column-sorters:hover .ant-table-column-sorter {
  }
  .ant-table-thead {
    th {
      border-bottom: 2px solid #dedede;
      overflow-wrap: normal;
      text-align: center;
      padding: 5px 0px;
    }
  }
  .ant-table {
    margin-top: 5px;
    tr:nth-child(even) {
      background-color: #f9f9f9 !important;
    }
    tr > td {
      padding: 5px 14px;
      overflow-wrap: normal;
      text-align: center;
    }
  }
  .ant-skeleton-content .ant-skeleton-title {
    margin-top: 5px;
  }
  .ant-skeleton-paragraph {
    margin-top: 0px !important;
  }
  .titleView {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    padding: 5px 0px 0px;
    display: flex;
    width: 100%;
    margin: 0px 0px;
    align-items: center;
    justify-content: space-between;
    .ant-radio-button-wrapper {
      white-space: nowrap;
    }
  }
  .ant-segmented {
    background-color: #eeeeee;
    color: #000000;
  }
  .chart-type-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 0px 10px;
  }
  .source-link {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-style: italic;
    margin: 5px;
    a {
      margin-left: 5px;
    }
  }
`;
export const CardViewWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100px;
  box-shadow: 0px 0px 20px #25405f2a;
  border-radius: 15px;
  margin: 10px;
  flex: 1;
  padding: 15px 10px 20px;
`;
