import {
  ContainerOutlined,
  PieChartOutlined,
  ProfileOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Menu, Modal, Segmented, Select, Tag } from "antd";
import { nest } from "d3-collection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import salesRegionData from "../../components/SalesRegionMap/salesRegionInfo";
import {
  getIndicationType,
  getInsightsMenuValue,
  getSelectedGeoFilterName,
  getSelectedGeoFilters,
  getSelectedGeoTerriroties,
  setCurrentView,
  setIndicationType,
  setInsightsMenuValue,
  setIsFiltersClearedByUser,
  setSelectedGeoFilterName,
  setSelectedGeoTerriroties,
} from "../../store/appSlice";
import { isUndefined } from "../../utils/utils";
import Brand from "../Brand";
import SalesRegionMap from "../SalesRegionMap/SalesRegionMap";
import UserInfo from "../UserInfo";
import { HeaderWrapper } from "./Header.styles";
const { Option } = Select;

const items = [
  /*{
    label: "Insights",
    key: "insights",
    icon: <PieChartOutlined />,
  },*/
  {
    label: "Customers",
    key: "customers",
    icon: <ProfileOutlined />,
  },
  {
    label: "Triggers",
    key: "triggers",
    icon: <ContainerOutlined />,
  },
];
var regionDistrictTerritory = [];
var geoTerritory = [];
var selTerritories = [];
var _geoTerritoryObj = {};
var _regionTerritoryObj = {};
var _districtTerritoryObj = {};
var _districtTerritoryNameObj = {};
const AZHeader = (props) => {
  const insightsMatch = useMatch("/");
  const match = useMatch("/triggers");
  const custmatch = useMatch("/customers");
  const obmmatch = useMatch("/obm");
  const executivematch = useMatch("/executive");
  const [isShrunk, setShrunk] = useState(false);
  const [current, setCurrent] = useState(
    match ? "triggers" : custmatch ? "customers" : "insights"
  );
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const indicationType = useSelector(getIndicationType);
  const insightsMenuValue = useSelector(getInsightsMenuValue);
  const [indType, setIndType] = useState(indicationType);
  const [triggerMenu, setTriggerMenu] = useState([]);
  const [insightsMenuClassName, setInsightsMenuClassName] = useState(
    match
      ? "insightsmenu"
      : custmatch
      ? "insightsmenu"
      : "insightsmenu-highlight"
  );
  const [currentInsightsMenu, setCurrentInsightsMenu] = useState(
    insightsMenuValue
  );
  const [enableInsights, setEnableInsights] = useState(
    match ? true : custmatch ? true : false
  );
  const [triggerValue, setTriggerValue] = useState(0);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  const selectedGeoFilterName = useSelector(getSelectedGeoFilterName);
  const selectedGeoFilters = useSelector(getSelectedGeoFilters);
  const [regionTerritoryObj, setRegionTerritoryObj] = useState({});
  const [districtTerritoryObj, setDistrictTerritoryObj] = useState({});
  const [geoTerritoryObj, setGeoTerritoryObj] = useState({});
  const handleChange = (value) => {};
  const onTypeChange = (value) => {
    dispatch(setIndicationType(value));
    setIndType(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (props.triggerData) {
      var _options = [];
      var _triggerData = props.triggerData;
      _triggerData.map((item, index) => {
        _options.push({
          label: `${item.toUpperCase()} (${props.triggerCount[item]})`,
          value: index,
        });
      });
      setTriggerMenu(_options);
    }
  }, [props.triggerData, props.triggerCount]);

  useEffect(() => {
    setTriggerValue(props.triggerValue);
  }, [props.triggerValue]);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };
    loadGeoData();
    if(insightsMatch){
      dispatch(setInsightsMenuValue('insights'));
      setCurrentInsightsMenu('insights');
    }
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const onClick = (e) => {
    setCurrent(e.key);
    setCurrentView(e.key);
    if (e.key == "triggers") {
      navigate("/triggers", { replace: true });
      setInsightsMenuClassName("insightsmenu");
      setEnableInsights(true);
    } else if (e.key == "customers") {
      navigate("/customers", { replace: true });
      setInsightsMenuClassName("insightsmenu");
      setEnableInsights(true);
    } else {
      navigate("/", { replace: true });
      setEnableInsights(false);
      setInsightsMenuClassName("insightsmenu-highlight");
    }
  };
  const getTerritoryName = (territoryId) => {
    var result = salesRegionData.find((obj) => obj.territoryId == territoryId);
    return isUndefined(result) ? null : result;
  };
  const getTerritoryId = (territory) => {
    var result = salesRegionData.find((obj) => obj.territory == territory);
    return isUndefined(result) ? null : result;
  };
  const loadGeoData = () => {
    setTimeout(() => {
      regionDistrictTerritory = nest()
        .key(function (d) {
          return (
            d.region +
            "-" +
            d.district +
            "-" +
            d.territoryId +
            "-" +
            d.territory
          );
        })
        .rollup(function (d) {
          return d.map(function (g) {
            return g.fip;
          });
        })
        .object(salesRegionData);

      geoTerritory = nest()
        .key(function (d) {
          return d.geo + "-" + d.territoryId;
        })
        .rollup(function (d) {
          return d.map(function (g) {
            return g.fip;
          });
        })
        .object(salesRegionData);

      Object.keys(geoTerritory).forEach((k) => {
        var _k = k.split("-");
        if (_geoTerritoryObj[_k[0]]) {
          if (_geoTerritoryObj[_k[0]].indexOf(_k[1]) < 0) {
            _geoTerritoryObj[_k[0]].push(_k[1]);
          }
        } else {
          _geoTerritoryObj[_k[0]] = [];
          if (_geoTerritoryObj[_k[0]].indexOf(_k[1]) < 0) {
            _geoTerritoryObj[_k[0]].push(_k[1]);
          }
        }
      });
      setGeoTerritoryObj(_geoTerritoryObj);
      Object.keys(regionDistrictTerritory).forEach((k) => {
        var _k = k.split("-");
        if (_regionTerritoryObj[_k[0]]) {
          if (_regionTerritoryObj[_k[0]].indexOf(_k[2]) < 0) {
            _regionTerritoryObj[_k[0]].push(_k[2]);
          }
        } else {
          _regionTerritoryObj[_k[0]] = [];
          if (_regionTerritoryObj[_k[0]].indexOf(_k[2]) < 0) {
            _regionTerritoryObj[_k[0]].push(_k[2]);
          }
        }
        if (_districtTerritoryObj[_k[1]]) {
          if (_districtTerritoryObj[_k[1]].indexOf(_k[2]) < 0) {
            _districtTerritoryObj[_k[1]].push(_k[2]);
          }
        } else {
          _districtTerritoryObj[_k[1]] = [];
          if (_districtTerritoryObj[_k[1]].indexOf(_k[2]) < 0) {
            _districtTerritoryObj[_k[1]].push(_k[2]);
          }
        }
        if (_districtTerritoryNameObj[_k[1]]) {
          if (_districtTerritoryNameObj[_k[1]].indexOf(_k[3]) < 0) {
            _districtTerritoryNameObj[_k[1]].push(_k[3]);
          }
        } else {
          _districtTerritoryNameObj[_k[1]] = [];
          if (_districtTerritoryNameObj[_k[1]].indexOf(_k[3]) < 0) {
            _districtTerritoryNameObj[_k[1]].push(_k[3]);
          }
        }
      });
      setRegionTerritoryObj(_regionTerritoryObj);
      setDistrictTerritoryObj(_districtTerritoryObj);
    }, 100);
  };
  const updateTerritories = (_selectedGeoFilterName) => {
    var _selterritories = [];
    _selectedGeoFilterName.map((_sd) => {
      var _s = _sd.split(" | ");
      if (_sd.includes(" Geo")) {
        var _s1 = _sd.split(" Geo");
        let territories = _geoTerritoryObj[_s1[0]];
        territories.map((terr) => {
          _selterritories.push(terr);
        });
      }
      if (_s.length == 1) {
        if (_s[0] == "All Geos") {
          Object.keys(geoTerritory).forEach((k) => {
            let _k = k.split("-");
            _selterritories.push(_k[1]);
          });
        } else {
          if (_sd.includes(" Geo")) {
            let _s1 = _sd.split(" Geo");
            let territories = _geoTerritoryObj[_s1[0]];
            territories.map((terr) => {
              _selterritories.push(terr);
            });
          } else {
            let territories = _regionTerritoryObj[_s[0]];
            territories.map((terr) => {
              _selterritories.push(terr);
            });
          }
        }
      } else if (_s.length == 2) {
        let territories = _districtTerritoryObj[_s[1]];
        territories.map((terr) => {
          _selterritories.push(terr);
        });
      } else if (_s.length == 3) {
        _selterritories.push(_s[2]);
      }
    });
    dispatch(setSelectedGeoTerriroties(_selterritories));
  };
  const onClose = (e) => {
    var _selectedGeoFilterName = [...selectedGeoFilterName];

    selectedGeoFilterName.map((_sd) => {
      var _s = e.split(" | ");
      if (_s.length == 3) {
        let terr = getTerritoryId(_s[2]);
        var _sdstr = `${_s[0]} | ${_s[1]} | ${terr.territoryId}`;
        var idx = _selectedGeoFilterName.indexOf(_sdstr);
        if (idx >= 0) {
          _selectedGeoFilterName.splice(idx, 1);
        }
      } else {
        if (e == _sd) {
          var idx = _selectedGeoFilterName.indexOf(_sd);
          if (idx >= 0) {
            _selectedGeoFilterName.splice(idx, 1);
          }
        }
      }
    });
    if (_selectedGeoFilterName.length == 0) {
      dispatch(setIsFiltersClearedByUser(true));
    }
    dispatch(setSelectedGeoFilterName(_selectedGeoFilterName));
    updateTerritories(_selectedGeoFilterName);
  };
  const handleMenuChange = (value) => {
    setInsightsMenuClassName("insightsmenu-highlight");
    dispatch(setInsightsMenuValue(value));
    setCurrentInsightsMenu(value);
    if (value == "insights") {
      navigate("/", { replace: true });
    } else if (value == "obm") {
      navigate("/obm", { replace: true });
    } else if (value == "executive") {
      navigate("/executive", { replace: true });
    }
  };
  return (
    <HeaderWrapper>
      <div className="header">
        <Brand />
        <span
          style={{ display: "inline-flex" }}
          onClick={(e) => {
            if (enableInsights) {
              if (currentInsightsMenu == "insights") {
                navigate("/", { replace: true });
              } else if (currentInsightsMenu == "obm") {
                navigate("/obm", { replace: true });
              } else if (currentInsightsMenu == "executive") {
                navigate("/executive", { replace: true });
              }
            }
          }}
        >
          <PieChartOutlined
            style={{
              color: insightsMenuClassName == "insightsmenu-highlight" ? "#ffffff" : "#000000",
              position: "relative",
              top: 7,
              left: 45,
              zIndex: 1000000,
            }}
          />
          <Select
            suffixIcon={<CaretDownOutlined />}
            className={insightsMenuClassName}
            value={currentInsightsMenu}
            onSelect={handleMenuChange}
            disabled={true}
            showArrow={false}
          >
            <Option value="insights">Insights</Option>
            <Option value="obm">OBM</Option>
            <Option value="executive">Executive</Option>
          </Select>
        </span>
        <Menu
          selectedKeys={[current]}
          onClick={onClick}
          mode="horizontal"
          items={items}
        />
        <div className="rightMenu">
          {current !== "triggers" && (
            <span className="chooseBtn" onClick={showModal}>
              Choose Geo
            </span>
          )}
          <UserInfo />
        </div>
      </div>
      {
        /*current == "insights" && */ <div className="sub-menu-container">
          <div className="subMenu">
            {/*<span className="subMenuProduct">CALQUENCE</span>*/}{" "}
            <Select
              style={{ background: "#eeeeee" }}
              className="subMenuProduct"
              onChange={handleChange}
              defaultValue={"CALQUENCE"}
              showArrow={false}
            >
              <Option value="CALQUENCE">CALQUENCE</Option>
            </Select>
            {triggerMenu && triggerMenu.length > 0 && (
              <Segmented
                onChange={props.onTriggerTypeChange}
                value={triggerValue}
                options={triggerMenu}
              />
            )}
            {!props.hideIndications && (
              <Segmented
                onChange={onTypeChange}
                value={indType}
                options={[
                  {
                    label: "TOTAL",
                    value: "ALL",
                  },
                  {
                    label: "CLL",
                    value: "CLL",
                  },
                  {
                    label: "MCL",
                    value: "MCL",
                  },
                ]}
              />
            )}
            {current !== "triggers" && selectedGeoFilterName.length > 0 && (
              <div className="tagWrapper">
                <span className="tagWrapper-tags">
                  <span className="tagWrapper-title">Selected Geos:</span>
                  {selectedGeoFilterName.map((_t, idx) => {
                    var _tname = _t;
                    var _tarr = _t.split(" | ");
                    if (_tarr.length == 3) {
                      var result = getTerritoryName(_tarr[2]);
                      if (result && result.territory) {
                        _tname = `${_tarr[0]} | ${_tarr[1]} | ${result.territory}`;
                      } else {
                        _tname = "";
                      }
                    }
                    if (_tname != "") {
                      return (
                        <Tag
                          key={_tname}
                          className="tags"
                          color="blue"
                          closable
                          onClose={() => {
                            onClose(_tname);
                          }}
                        >
                          {_tname}
                        </Tag>
                      );
                    } else {
                      return null;
                    }
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
      }
      <Modal
        centered
        width={"100%"}
        height={"90vh"}
        visible={isModalVisible}
        onCancel={handleCancel}
        className="salesMap"
        destroyOnClose={true}
        footer={null}
      >
        <SalesRegionMap
          onCancel={() => {
            setIsModalVisible(false);
          }}
          visible={isModalVisible}
        />
      </Modal>
    </HeaderWrapper>
  );
};

export default AZHeader;
