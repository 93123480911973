import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Col, Divider, Radio, Row, Segmented, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import {
  getIndicationType,
  getIsAccountChartPercent,
  getSelectedGeoTerriroties,
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import {
  AccountAddress,
  AccountStackedBarChartWrapper,
  CardTitle,
  MessageTitle,
} from "./AccountStackedBarChart.styles";
//import * as am5xy from "@amcharts/amcharts5/xy";
//import * as am5percent from "@amcharts/amcharts5/percent";
//import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useNavigate, useParams } from "react-router-dom";
var hcp_bar_chart = null;
var account_bar_chart = null;
const AccountStackedBarChart = (props) => {
  const { showAccountInfo, type } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isTopAccountLoading, setIsTopAccountLoading] = useState(false);
  const [msChartType, setMSChartType] = useState("QUARTERLY");
  const [chartType, setChartType] = useState("trx");
  const [currentActiveLegends, setCurrentActiveLegends] = useState({
    cal: false,
    ibr: false,
    ven: false,
    bru: false,
    cit: false,
    cd20: false,
    other: false,
  });
  let { accountId } = useParams();
  const navigate = useNavigate();
  const drugs = ["cal", "ibr", "ven", "zanu", "cit", "cd20", "others"];
  const hcpSeries = [];
  const accountSeries = [];

  const HCP_BAR_CHART_ID = `SBChart-HCP-${props.chartId}`;
  const ACCOUNT_BAR_CHART_ID = `SBChart-Account-${props.chartId}`;
  const [sbChartDataType, setSBChartDataType] = useState("QUARTERLY");
  const [hcpTrxData, setHcpTrxData] = useState([]);
  const [hcpNpsData, setHcpNpsData] = useState([]);
  const [address, setAddress] = useState("");
  const [topParentName, setTopParentName] = useState("");
  const [topParentId, setTopParentId] = useState("-");

  const [azid, setAzid] = useState("");
  const [accountTrxData, setAccountTrxData] = useState([]);
  const [accountNpsData, setAccountNpsData] = useState([]);
  const [msTitle, setMsTitle] = useState("All Market Share");
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  const isAccountChartPercent = useSelector(getIsAccountChartPercent);
  useEffect(() => {
    return () => {
      if (hcp_bar_chart) {
        hcp_bar_chart.dispose();
      }
      if (account_bar_chart) {
        account_bar_chart.dispose();
      }
    };
  }, []);
  useEffect(() => {
    var it = indicationType;
    if (it == "ALL") {
      setMsTitle("Total Market Share");
    } else if (it == "CLL") {
      setMsTitle("CLL Market Share");
    } else if (it == "MCL") {
      setMsTitle("MCL Market Share");
    }
  }, [indicationType]);

  useEffect(() => {
    setIsLoading(true);
    getNationalTrxInfo();
    getNationalNpsInfo();
  }, [sbChartDataType, indicationType, selectedGeoTerriroties, isAccountChartPercent]);

  useEffect(() => {
    setIsTopAccountLoading(true);
    if (type == "parent") {
      var accInfo = props.accountInfo;
      if (accInfo && accInfo.topParentAddress) {
        var _topParentAddress = accInfo.topParentAddress;
        var addressStr = `${_topParentAddress.address}, ${_topParentAddress.city}, ${_topParentAddress.state},, ${_topParentAddress.zip}`;
        setAddress(addressStr);
        setTopParentName(_topParentAddress.name || "");
        setTopParentId(_topParentAddress.account_az_id);
        getTopAccountTrxInfo(_topParentAddress.account_az_id);
        getTopAccountNpsInfo(_topParentAddress.account_az_id);
      }
    }
  }, [
    sbChartDataType,
    indicationType,
    selectedGeoTerriroties,
    props.accountInfo,
    topParentId,
  ]);

  useEffect(() => {
    am4core.addLicense("CH332248795");
    am4core.useTheme(am4themes_animated);
    return () => {};
  }, []);

  const parseData = (response) => {
    let rArr = [];
    response.map((res) => {
      var rObj = {
        category: res.name,
        cal: null,
        ibr: null,
        ven: null,
        bru: null,
        cit: null,
        cd20: null,
        others: null,
      };
      res.data.map((d) => {
        if (d.value > 0) {
          if (d.name == "Acalabrutinib") {
            rObj["cal"] = d.value;
          } else if (d.name == "Ibrutinib") {
            rObj["ibr"] = d.value;
          } else if (d.name == "Venetoclax") {
            rObj["ven"] = d.value;
          } else if (d.name == "CIT") {
            rObj["cit"] = d.value;
          } else if (d.name == "CD20") {
            rObj["cd20"] = d.value;
          } else if (d.name == "Brukinsa") {
            rObj["zanu"] = d.value;
          } else if (d.name == "Others") {
            rObj["others"] = d.value;
          } else {
            rObj[d.name] = d.value;
          }
        }
      });
      rArr.push(rObj);
    });
    rArr = rArr.reverse();
    return rArr;
  };
  const getNationalTrxInfo = async (response) => {
    let rpArr = [];
    var data = {
      timeInterval: sbChartDataType,
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isAccountChartPercent
    };
    var url = `${CONFIG.api.getParentTrx}`;
    if (type == "parent") {
      data.parent_id = accountId;
      url = `${CONFIG.api.getParentTrx}`;
    } else {
      data.top_parent_az_id = accountId;
      url = `${CONFIG.api.getTopParentTrx}`;
    }
    PostAPIRequest({
      url: url,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            rpArr = parseData(response.account);
            setHcpTrxData(rpArr);
            if (chartType == "trx") {
              setTimeout(() => {
                loadHCPSBChart(rpArr);
              }, 1000);
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const getTopAccountTrxInfo = async (topParentId) => {
    let rpArr = [];
    var data = {
      timeInterval: sbChartDataType,
      tumorType: indicationType,
      territories: [],
      top_parent_az_id: topParentId,
      isPercentage: isAccountChartPercent
    };
    var url = `${CONFIG.api.getTopParentTrx}`;
    PostAPIRequest({
      url: url,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            rpArr = parseData(response.account);
            setAccountTrxData(rpArr);
            if (chartType == "trx") {
              setTimeout(() => {
                loadAccountSBChart(rpArr);
              }, 1000);
            }
            setIsTopAccountLoading(false);
          }
        }
      })
      .catch((error) => {
        setIsTopAccountLoading(false);
      });
  };
  const getNationalNpsInfo = async (response) => {
    let rpArr = [];
    var data = {
      timeInterval: sbChartDataType,
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isAccountChartPercent
    };
    var url = `${CONFIG.api.getTopParentNps}`;
    if (type == "parent") {
      data.parent_id = accountId;
      url = `${CONFIG.api.getParentNps}`;
    } else {
      data.top_parent_az_id = accountId;
      url = `${CONFIG.api.getTopParentNps}`;
    }
    PostAPIRequest({
      url: url,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            rpArr = parseData(response.account);
            setHcpNpsData(rpArr);
            if (chartType == "nps") {
              setTimeout(() => {
                loadHCPSBChart(rpArr);
              }, 1000);
            }
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const getTopAccountNpsInfo = async (topParentId) => {
    let rpArr = [];
    var data = {
      timeInterval: sbChartDataType,
      tumorType: indicationType,
      territories: [],
      top_parent_az_id: topParentId,
      isPercentage: isAccountChartPercent
    };
    var url = `${CONFIG.api.getTopParentNps}`;
    PostAPIRequest({
      url: url,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            rpArr = parseData(response.account);
            setAccountNpsData(rpArr);
            if (chartType == "nps") {
              setTimeout(() => {
                loadAccountSBChart(rpArr);
              }, 1000);
            }
          }
        }
        setIsTopAccountLoading(false);
      })
      .catch((error) => {
        setIsTopAccountLoading(false);
      });
  };

  const loadHCPSBChart = async (rArr) => {
    if (hcp_bar_chart) {
      hcp_bar_chart.dispose();
    }
    hcp_bar_chart = am4core.create(HCP_BAR_CHART_ID, am4charts.XYChart);
    hcp_bar_chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    hcp_bar_chart.data = rArr;
    //chart.colors.step = 2;
    hcp_bar_chart.padding(10, 10, 0, 10);
    hcp_bar_chart.colors.list = [
      am4core.color("#E44405"),
      am4core.color("#00B0F0"),
      am4core.color("#FFC72C"),
      am4core.color("#7030A0"),
      am4core.color("#00B050"),
      am4core.color("#92D050"),
      am4core.color("#3e3e3e"),
    ];

    /* 
     chart.colors.list = [
      am4core.color("#584D8D"),
      am4core.color("#EB5F5E"),
      am4core.color("#4EBCB3"),
     ];
     chart.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871"),
    ];*/
    hcp_bar_chart.padding(0, 0, 0, 0);
    hcp_bar_chart.legend = new am4charts.Legend();
    hcp_bar_chart.legend.position = "top";
    hcp_bar_chart.legend.paddingBottom = 20;
    hcp_bar_chart.legend.itemContainers.template.events.on("hit", function (
      ev
    ) {
      //alert("Clicked on " + ev.target.dataItem.dataContext.name);
      var name = ev.target.dataItem.dataContext.name;

      name = name.toLowerCase();

      var _currentActiveLegends = currentActiveLegends;
      var idx = drugs.indexOf(name);
      if (idx > -1) {
        if (accountSeries[idx]) {
          if (accountSeries[idx].isHidden) {
            accountSeries[idx].show();
            _currentActiveLegends[name] = false;
          } else {
            accountSeries[idx].hide();
            _currentActiveLegends[name] = true;
          }
        }
      }
      setCurrentActiveLegends(_currentActiveLegends);
    });
    hcp_bar_chart.legend.useDefaultMarker = true;
    var marker = hcp_bar_chart.legend.markers.template;
    marker.width = 25;
    marker.height = 25;
    marker.disposeChildren();
    /* Add custom image instead */
    var flag = marker.createChild(am4core.Sprite);
    flag.width = 10;
    flag.height = 10;
    flag.scale = 0.18;
    flag.fillOpacity = 0.9;
    flag.path =
      "M7.246,0h108.389c1.992,0,3.805,0.815,5.117,2.128s2.129,3.125,2.129,5.117 v108.388c0,1.993-0.816,3.805-2.129,5.117c-1.313,1.313-3.125,2.129-5.117,2.129H7.246c-1.993,0-3.804-0.815-5.118-2.129 C0.815,119.438,0,117.627,0,115.634V7.246c0-1.993,0.815-3.804,2.128-5.117C3.442,0.815,5.253,0,7.246,0L7.246,0z M32.036,68.054 l0.011,0.01l0.006,0.006l0.012,0.01v0.001l16.98,15.397c0.399,0.361,0.852,0.629,1.329,0.803c0.496,0.181,1.018,0.261,1.536,0.241 c0.503-0.019,1.003-0.131,1.47-0.334c0.451-0.196,0.873-0.479,1.241-0.848l0.012-0.01l0.033-0.028l0.001-0.001 c0.012-0.011,0.023-0.021,0.032-0.03l36.346-38.065c0.379-0.398,0.66-0.853,0.846-1.334v0c0.193-0.5,0.281-1.033,0.27-1.561 c-0.014-0.528-0.129-1.055-0.348-1.547c-0.209-0.473-0.514-0.913-0.91-1.291L90.9,39.471c-0.396-0.378-0.852-0.66-1.334-0.845l0,0 c-0.5-0.192-1.031-0.282-1.561-0.269c-0.527,0.013-1.055,0.129-1.545,0.347c-0.473,0.209-0.912,0.513-1.291,0.91l-0.002,0.001 L51.642,74.729l-0.09,0.094l-0.097-0.087l-13.972-12.67c-0.406-0.367-0.867-0.638-1.354-0.812c-0.505-0.18-1.036-0.256-1.562-0.23 c-0.523,0.026-1.042,0.154-1.524,0.381c-0.464,0.219-0.895,0.53-1.262,0.932l-0.003,0.003c-0.007,0.007-0.016,0.017-0.024,0.026 l-0.002,0.003c-0.363,0.405-0.629,0.864-0.8,1.348c-0.178,0.501-0.253,1.029-0.227,1.552c0.026,0.523,0.153,1.042,0.379,1.523 C31.323,67.256,31.634,67.687,32.036,68.054L32.036,68.054L32.036,68.054z";
    flag.verticalCenter = "center";
    flag.horizontalCenter = "center";

    flag.propertyFields.fill = "fill";
    var as = hcp_bar_chart.legend.labels.template.states.getKey("active");
    as.properties.fillOpacity = 0.5;
    var markerlabel = hcp_bar_chart.legend.labels.template;
    markerlabel.fontSize = 10;
    hcp_bar_chart.legend.itemContainers.template.marginRight = 5;
    hcp_bar_chart.legend.itemContainers.template.marginLeft = 5;
    var as2 = hcp_bar_chart.legend.valueLabels.template.states.getKey("active");
    as.properties.fillOpacity = 0.5;
    let categoryAxis = hcp_bar_chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = hcp_bar_chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis.renderer.minWidth = 50;
    //if (isAccountChartPercent) {
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      });
    /*} else {
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text;
      });
    }*/
    valueAxis.renderer.minGridDistance = 30;

    // Create series
    function createSeries(field, name) {
      var series = hcp_bar_chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(80);

      series.tooltip.pointerOrientation = "vertical";
      series.dataFields.valueY = field;
      if (isAccountChartPercent) {
        series.columns.template.tooltipText =
          "{name}: {valueY.totalPercent.formatNumber('#.')}%";
        
      } else {
        series.columns.template.tooltipText = "{name}: {valueY}";
      }
      series.dataFields.valueYShow = "totalPercent";
      series.dataItems.template.locations.categoryX = 0.5;
      series.dataFields.categoryX = "category";
      series.stacked = true;
      series.showOnInit = false;
      series.name = name;
      series.hidden = currentActiveLegends[field];

      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationY = 0.5;
      labelBullet.interactionsEnabled = false;
      if (isAccountChartPercent) {
        labelBullet.label.text = "{valueY.totalPercent.formatNumber('#.')}%";
      } else {
        labelBullet.label.text = "{valueY}";
      }
      //labelBullet.label.text = "{valueY.formatNumber('#.#')}";
      labelBullet.label.fill = am4core.color("#ffffff");
      //hide label when valueX = 0
      labelBullet.label.adapter.add("textOutput", function (text, target) {
        if (target.dataItem && target.dataItem.valueY == 0) return "";
        return text;
      });
      series.columns.template.events.on("sizechanged", function (ev) {
        if (ev.target.dataItem && ev.target.dataItem.bullets) {
          var height = ev.target.pixelHeight;
          ev.target.dataItem.bullets.each(function (id, bullet) {
            if (height > 20) {
              bullet.show();
            } else {
              bullet.hide();
            }
          });
        }
      });
      hcpSeries.push(series);
    }

    createSeries("cal", "CAL");
    createSeries("ibr", "IBR");
    createSeries("ven", "VEN");
    createSeries("zanu", "ZANU");
    createSeries("cit", "CIT");
    createSeries("cd20", "CD20");
    createSeries("others", "Others");
    setIsLoading(false);
  };

  const loadAccountSBChart = (rArr) => {
    if (account_bar_chart) {
      account_bar_chart.dispose();
    }
    account_bar_chart = am4core.create(ACCOUNT_BAR_CHART_ID, am4charts.XYChart);
    account_bar_chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    account_bar_chart.data = rArr;

    //chart.colors.step = 2;
    account_bar_chart.padding(30, 10, 0, 10);
    account_bar_chart.colors.list = [
      am4core.color("#E44405"),
      am4core.color("#00B0F0"),
      am4core.color("#FFC72C"),
      am4core.color("#7030A0"),
      am4core.color("#00B050"),
      am4core.color("#92D050"),
      am4core.color("#3e3e3e"),
    ];

    let acc_categoryAxis = account_bar_chart.xAxes.push(
      new am4charts.CategoryAxis()
    );
    acc_categoryAxis.dataFields.category = "category";
    acc_categoryAxis.renderer.grid.template.location = 0;
    acc_categoryAxis.renderer.minGridDistance = 30;

    let acc_valueAxis = account_bar_chart.yAxes.push(new am4charts.ValueAxis());
    //if (isAccountChartPercent) {
      acc_valueAxis.min = 0;
      acc_valueAxis.max = 110;
      acc_valueAxis.strictMinMax = true;
      acc_valueAxis.calculateTotals = true;
      acc_valueAxis.renderer.labels.template.adapter.add("text", function (
        text
      ) {
        return text + "%";
      });
    /*} else {
      acc_valueAxis.renderer.labels.template.adapter.add("text", function (
        text
      ) {
        return text;
      });
    }*/

    acc_valueAxis.renderer.minWidth = 50;

    acc_valueAxis.renderer.minGridDistance = 30;

    // Create series
    function createSeries(field, name) {
      var acc_series = account_bar_chart.series.push(
        new am4charts.ColumnSeries()
      );
      acc_series.columns.template.width = am4core.percent(80);
      if (isAccountChartPercent) {
        acc_series.columns.template.tooltipText =
          "{name}: {valueY.totalPercent.formatNumber('#.')}%";
      } else {
        acc_series.columns.template.tooltipText = "{name}: {valueY}";
      }
      acc_series.tooltip.pointerOrientation = "vertical";
      acc_series.dataFields.valueY = field;
      //if (isAccountChartPercent) {
        acc_series.dataFields.valueYShow = "totalPercent";
      //}
      acc_series.dataItems.template.locations.categoryY = 0.5;
      acc_series.dataFields.categoryX = "category";
      acc_series.stacked = true;
      acc_series.name = name;
      acc_series.hidden = currentActiveLegends[field];
      var labelBullet = acc_series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationY = 0.5;
      labelBullet.interactionsEnabled = false;
      if (isAccountChartPercent) {
        labelBullet.label.text = "{valueY.totalPercent.formatNumber('#.')}%";
      } else {
        labelBullet.label.text = "{valueY}";
      }
      labelBullet.label.fill = am4core.color("#ffffff");
      labelBullet.label.adapter.add("textOutput", function (text, target) {
        if (target.dataItem && target.dataItem.valueY == 0) return "";
        return text;
      });
      acc_series.columns.template.events.on("sizechanged", function (ev) {
        if (ev.target.dataItem && ev.target.dataItem.bullets) {
          var height = ev.target.pixelHeight;
          ev.target.dataItem.bullets.each(function (id, bullet) {
            if (height > 25) {
              bullet.show();
            } else {
              bullet.hide();
            }
          });
        }
      });

      accountSeries.push(acc_series);
    }

    createSeries("cal", "CAL");
    createSeries("ibr", "IBR");
    createSeries("ven", "VEN");
    createSeries("zanu", "ZANU");
    createSeries("cit", "CIT");
    createSeries("cd20", "CD20");
    createSeries("others", "Others");

    setIsTopAccountLoading(false);
  };

  function onChange(e) {
    setChartType(e.target.value);
    if (e.target.value == "trx") {
      loadHCPSBChart(hcpTrxData);
      if (type == "parent") {
        loadAccountSBChart(accountTrxData);
      }
    } else if (e.target.value == "nps") {
      loadHCPSBChart(hcpNpsData);
      if (type == "parent") {
        loadAccountSBChart(accountNpsData);
      }
    }
  }

  function goToAccount(id) {
    navigate(`/parent-account/${id}`);
  }

  return (
    <AccountStackedBarChartWrapper>
      <Spin spinning={isLoading}>
        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={24}>
                <div className="title-container">
                  <div className="message-title" style={{ fontSize: 14 }}>
                    {
                      msTitle /*(props.prescriberInfo &&
                  props.prescriberInfo.marketShareText) ||
                  ""*/
                    }
                  </div>
                  <div className="message-button-group">
                    <Space size={"small"}>
                      <Segmented
                        style={{ fontSize: 12 }}
                        defaultValue={sbChartDataType}
                        onChange={(e) => {
                          setSBChartDataType(e);
                        }}
                        options={["WEEKLY", "MONTHLY", "QUARTERLY"]}
                      />
                      <Radio.Group
                        value={chartType}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        buttonStyle="solid"
                      >
                        <Radio.Button value="trx">TRx</Radio.Button>
                        <Radio.Button value="nps">NPS</Radio.Button>
                      </Radio.Group>
                    </Space>
                  </div>
                </div>
              </Col>
            </Row>
            <div
              id={HCP_BAR_CHART_ID}
              style={{
                width: "100%",
                height:
                  type == "parent"
                    ? topParentId == "INDP"
                      ? "480px"
                      : "260px"
                    : "350px",
                marginTop: 5,
              }}
            ></div>
          </Col>
          {type == "parent" && (
            <>
              <Divider />
              <Col xs={24}>
                <Row style={{ marginTop: topParentId == "INDP" ? 0 : -10 }}>
                  <Col xs={14}>
                    <CardTitle>Parent Account</CardTitle>
                    {topParentId == "INDP" ? (
                      <MessageTitle>
                        <div>{topParentName || ""}</div>
                      </MessageTitle>
                    ) : (
                      <MessageTitle>
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            goToAccount(topParentId);
                          }}
                        >
                          {topParentName || ""}
                        </div>
                        <AccountAddress>{address || ""}</AccountAddress>
                      </MessageTitle>
                    )}
                  </Col>
                  <Col xs={10}>
                    <CardTitle>AZ Account Id</CardTitle>
                    {topParentId == "INDP" ? (
                      <div>
                        <MessageTitle>{topParentId || "-"}</MessageTitle>
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          goToAccount(topParentId);
                        }}
                      >
                        <MessageTitle>{topParentId || "-"}</MessageTitle>
                      </div>
                    )}
                  </Col>
                </Row>
                <div
                  id={ACCOUNT_BAR_CHART_ID}
                  style={{
                    width: "100%",
                    height: topParentId == "INDP" ? "0px" : "250px",
                    marginTop: -10,
                  }}
                ></div>
              </Col>
              {topParentId == "INDP" && (
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              )}
            </>
          )}
        </Row>
      </Spin>
    </AccountStackedBarChartWrapper>
  );
};

export default AccountStackedBarChart;
