import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TimelineBlock from "../TimelineBlock/TimelineBlock";
import tlData from "../../../data/timelineData";
import CONFIG from "../../../constants";
import { GetAPIRequest } from "../../../services";
import { getIndicationType } from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import "./TimelineHistory.styles.jsx";
import { useParams } from "react-router-dom";
import { TimelineHistoryWrapper } from "./TimelineHistory.styles.jsx";
const TimelineView = (props) => {
  return (
    <>
      {props.tdata &&
        props.tdata.map((d, idx) => {
          var monthname = d.month.split(" ");
          var month = monthname[0].toLowerCase();
          var year = monthname[1];
          return (
            <TimelineBlock
              key={idx}
              activities={props.activities}
              actData={d}
              month={month}
              year={year}
              maxNoActivities={props.maxNoActivities}
            />
          );
        })}
    </>
  );
};
var colors = [
  "#1F77B4",
  "#FF7F27",
  "#2CA02C",
  "#D62728",
  "#9467BD",
  "#8C564B",
  "#8C564B",
  "#E377C3",
  "#7F7F7F",
  "#17BECF",
  "#57A9E2",
  "#FFB574",
  "#5FD35F",
  "#E77C7C",
  "#C6AEDC",
  "#BC8B81",
  "#F4CCE8",
  "#E2E362",
  "#5FE0ED",
  "#103D5D",
  "#A74E15",
  "#165016",
  "#801718",
  "#613A84",
  "#4A2D27",
  "#CA2A99",
  "#4C4C4C",
  "#666716",
  "#0D6A73",
  "#1E77B4",
  "#FE7F27",
  "#2CA02C",
  "#FCB441",
  "#005CDB",
  "#056492",
  "#FFE382",
  "#1A3B69",
  "#E03D19",
  "#219DA2",
];
const TimelineHistory = (props) => {
  const slider = useRef(null);
  const monthColor = {
    jan: "#0070c2",
    feb: "#006881",
    mar: "#396301",
    apr: "#738900",
    may: "#b3b000",
    jun: "#ffb60f",
    jul: "#ff9e0f",
    aug: "#f67800",
    sep: "#e81400",
    oct: "#ba0001",
    nov: "#bb0073",
    dec: "#71379e",
  };
  /*
  "Text Ad": "#ffb70d",
      "Email": "#0071c1",
      "Display": "#f77827",
      "Newsletter": "#bb0573",
      "Speaker Program": "#e81617",
    "In - Person Meet": "#71379e",
    "Sampling (R.S.) Calquence": "#006881",
    "Remote Detail": "#b3b02f"
      */
  const [isLoading, setLoading] = useState(true);
  const [moduleColor, setModuleColor] = useState("#e81400");
  const [prevEnabled, setPrevEnabled] = useState(true);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [activities, setActivities] = useState([]);
  const [nppActivities, setNppActivities] = useState([]);
  const [ppActivities, setPpActivities] = useState([]);
  const [maxNoActivities, setMaxNoActivities] = useState(0);
  let { providerId } = useParams();
  const indicationType = useSelector(getIndicationType);
  const fetchTimelineData = (id, _response) => {
    GetAPIRequest({
      url: `${CONFIG.api.timeLineApi}${id}`,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            var _timelineData;
            var timelineDataArr = [];
            var pp_activities = [];
            var npp_activities = [];
            var activities = [];
            var maxActivities = 0;
            response.map((r, idx) => {
              if (r.pp_activities) {
                if (r.pp_activities.length > maxActivities) {
                  maxActivities = r.pp_activities.length;
                }
                r.pp_activities.map((ppdata, i) => {
                  if (pp_activities.indexOf(ppdata.name) < 0) {
                    pp_activities.push(ppdata.name);
                    activities.push(ppdata.name);
                  }
                });
              }
              if (r.npp_activities) {
                if (r.npp_activities.length > maxActivities) {
                  maxActivities = r.npp_activities.length;
                }
                r.npp_activities.map((nppdata, i) => {
                  if (npp_activities.indexOf(nppdata.name) < 0) {
                    npp_activities.push(nppdata.name);
                    activities.push(nppdata.name);
                  }
                });
              }
            });
            setNppActivities(npp_activities);
            setPpActivities(pp_activities);
            var activitiesObj = {};
            activities.map((act, idx) => {
              activitiesObj[act] = colors[idx];
            });
            setActivities(activitiesObj);
            setMaxNoActivities(maxActivities);
            setTimelineData(response);
            setLoading(false);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    //setModuleColor(monthColor[props.month]);
    fetchTimelineData(providerId, tlData);
  }, [indicationType]);

  return (
    <TimelineHistoryWrapper>
      <div className="timelineContainer">
        <Spin spinning={isLoading}>
          <div className="timelineWrapper">
            <Row>
              <Col xs={24}>
                <div className="timelineHeader">
                  HISTORY{" "}
                  <Popover
                    placement="right"
                    content={
                      <div style={{ maxWidth: 400 }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              "Personal Promotion (PP) and Non Personal Promotion (NPP) activities the HCP has been exposed to over time (please click on individual bubbles to learn more).",
                          }}
                        ></p>
                      </div>
                    }
                    title={""}
                    trigger={"click"}
                  >
                    {" "}
                    <InfoCircleOutlined />
                  </Popover>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="activityContent1">
                  <div className="title">PP Activity</div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="activityContent1">
                  <div className="title">NPP Activity</div>
                </div>
              </Col>
            </Row>
            <div
              className="activityContentWrapper11"
              id="activityContentWrapper1"
            >
              <div className="timelineDiv">
                <TimelineView
                  maxNoActivities={maxNoActivities}
                  activities={activities}
                  tdata={timelineData}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </TimelineHistoryWrapper>
  );
};

export default TimelineHistory;
