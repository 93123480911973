import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Admin from "./components/Admin/Admin";
import CONFIG from "./constants";
import AccountView from "./containers/AccountView";
import CustomersView from "./containers/CustomersView/CustomersView";
import DetailView from "./containers/DetailView";
import ExecutiveView from "./containers/ExecutiveView";
import TriggersView from "./containers/Home";
import MaintenanceView from "./containers/MaintenanceView/MaintenanceView";
import NationalView from "./containers/NationalView";
import OBMView from "./containers/OBMView";
import ParentAccountView from "./containers/ParentAccountView";
import PhysicianView from "./containers/PhysicianView";
import { GetAPIRequest } from "./services";
import { checkAuth, getCookie } from "./utils/cookie-helper";

function App() {
  /*const [token, setToken] = useState("");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    let token = getCookie("access-token");
    setToken(token);
    if (token === null || token === "") {
      checkAuth();
    }
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    const prid_id = getCookie("sub");
    GetAPIRequest({
      url: `${CONFIG.api.getUserDetails}/${prid_id}`,
    })
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((err) => {});
  };*/

  return (
    <MaintenanceView />
  );
}
export default App;
