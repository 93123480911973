import {
  CaretLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../constants";
import {
  DeleteAPIRequest,
  GetAPIRequest,
  PostAPIRequest,
} from "../../services";
import { getCookie } from "../../utils/cookie-helper";
import AdminHeader from "../AdminHeader";
import { AdminViewWrapper } from "./Admin.styles";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const Admin = () => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState("Add User");
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filteredInfo, setFilteredInfo] = useState({});
  //const [isSearch, setIsSearch] = useState(false);
  const [usersForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const [isEdit, setEdit] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [selectedTerritories, setSelectedTerritories] = useState([""]);
  const [territories, setTerritories] = useState([""]);
  const [roles, setRoles] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const navigate = useNavigate();

  useEffect(() => {
    getTerritories();
    getUserRoles();
    getUserList();
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const getUserRoles = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getUserRoles}`,
    }).then((res) => {
      setRoles(res);
    });
  };

  const getUserList = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getUserList}`,
    }).then((res) => {
      setUsers(res);
    });
  };

  //getTerritories

  const getTerritories = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getUserTerritories}`,
    }).then((res) => {
      console.log("territories", res);
      setTerritories(res);
    });
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setEdit(false);
    setVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    var row = await form.validateFields();

    console.log("Received values of form: ", row);

    row = {
      system_user_id: row.key,
      email_id: row.email_id,
      prid_id: row.prid_id,
      role_id: row.role_id,
      territory_ids: row.territory_ids,
    };

    PostAPIRequest({
      url: `${CONFIG.api.saveUser}`,
      data: row,
    })
      .then((response) => {
        setConfirmLoading(true);
        setTimeout(() => {
          setVisible(false);
          setConfirmLoading(false);
        }, 1000);
        form.resetFields();
        getUserList();
        if (row.key) message.success("Successfully updated");
        else message.success("Successfully created");
        let prid = getCookie("sub");

        if (row.prid_id.toLowerCase() == prid.toLowerCase())
          window.location.reload();
      })
      .catch((err) => {
        message.error("Something went wrong. Please try again later.");
      });
    setEdit(false);
  };

  const clearAll = () => {
    setSortedInfo({});
    setFilteredInfo({});
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  };

  const columns = [
    // {
    //     title: "User",
    //     dataIndex: "system_user_id",
    //     inputType: "text",
    //     key: "system_user_id",
    //     editable: true,
    //     filteredValue: filteredInfo.data || null,
    //     onFilter: (value, record) => record.system_user_id.includes(value),
    //     sorter: (a, b) => a.system_user_id - b.system_user_id,
    //     sortOrder: sortedInfo.columnKey === "system_user_id" && sortedInfo.order,
    //     ellipsis: true,
    //     hidden: true
    // },
    {
      title: "PRID",
      dataIndex: "prid_id",
      inputType: "text",
      key: "prid_id",
      editable: true,

      filteredValue: filteredInfo.data || null,
      onFilter: (value, record) => record.prid_id.includes(value),
      sorter: (a, b) => a.prid_id.localeCompare(b.prid_id),
      sortOrder: sortedInfo.columnKey === "prid_id" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Territories",
      dataIndex: "territory_ids",
      inputType: "select",
      key: "territory_ids",

      filteredValue: filteredInfo.territory_ids || null,
      onFilter: (value, record) => record.territory_ids.includes(value),
      sorter: (a, b) => a.territory_ids.length - b.territory_ids.length,
      sortOrder: sortedInfo.columnKey === "territory_ids" && sortedInfo.order,
      editable: true,
      render: (_, record) => {
        return record.territory_ids.map((item, index) => {
          console.log("item", item);
          let territory_name =
            territories.filter((x) => x.territoryId == item).length > 0
              ? territories.filter((x) => x.territoryId == item)[0].territory
              : "";

          return <Tag>{territory_name}</Tag>;
        });
      },
    },
    {
      title: "Email",
      dataIndex: "email_id",
      inputType: "text",
      key: "email_id",

      filteredValue: filteredInfo.email || null,
      onFilter: (value, record) => record.email_id.includes(value),
      sorter: function (a, b) {
        if (a.email_id) return a.email_id.localeCompare(b.email_id);
        else if (b.email_id);
        return b.email_id.localeCompare(a.email_id);
      },
      sortOrder: sortedInfo.columnKey === "email_id" && sortedInfo.order,
      editable: true,
    },
    {
      title: "Action",
      width: 150,
      dataIndex: "action",
      render: (_, record) => {
        return (
          <>
            <div className="edit-container">
              <div className="edit-ctn">
                <Space>
                  <Typography.Link
                    disabled={editingKey !== ""}
                    onClick={() => edit(record)}
                  >
                    <EditOutlined /> Edit
                  </Typography.Link>
                </Space>
              </div>

              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => {
                  handleDelete(record);
                }}
              >
                {" "}
                <div className="delete-ctn">
                  <DeleteOutlined /> delete
                </div>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const edit = (record) => {
    setTitle("Edit User");

    usersForm.setFieldsValue({
      ...record,
    });

    showModal();
    setFormField(record);
    setEdit(true);
    //setEditingKey(record.key);
  };

  function setFormField(record) {
    const arr = [];

    for (let i = 0; i < record.territory_ids.length; i++)
      arr.push(record.territory_ids[i]);

    setSelectedTerritories(arr);
    console.log("selectedBrands", arr);
    form.setFieldsValue({
      key: record.system_user_id,
      role_id: record.role_id,
      territory_ids: arr,
      prid_id: record.prid_id,
      email_id: record.email_id,
    });
  }

  const handleDelete = (record) => {
    DeleteAPIRequest({
      url: `${CONFIG.api.deleteUser}/${record.prid_id}`,
    })
      .then((res) => {
        message.success("Successfully deleted");
        getUserList();
      })
      .catch((err) => {
        message.error("Something went wrong. Please try again later.");
      });
  };

  const gotoHome = () => {
    navigate("/");
  };

  const mockVal = (str, repeat = 1) => ({
    value: str.repeat(repeat),
  });

  const [emailList, setEmailList] = useState([]);
  const [emails, setEmails] = useState([]);
  const onEmailSearch = (searchText) => {
    GetAPIRequest({
      url: `${CONFIG.api.getEmailList}/${searchText}`,
    })
      .then((response) => {
        setEmails(response);
        let emailList = response.map((item, inedx) => {
          return { value: item.email_id };
        });
        setEmailList(emailList);
      })
      .catch((error) => {});
  };

  const getPrid = (email_id) => {
    let seletedObject = emails.filter((x) => x.email_id == email_id)[0];
    if (seletedObject) {
      form.setFieldsValue({
        prid_id: seletedObject.prid_id,
      });
    }
  };

  return (
    <AdminViewWrapper>
      <AdminHeader />
      <div
        style={{
          width: width,
          maxWidth: "100%",
          height: height,
          maxHeight: "100%",
        }}
      >
        <Layout
          className="layout"
          align="center"
          style={{ margin: "0px auto", height: "100%" }}
        >
          <div className="users-container admin-table-view">
            <div
              style={{ width: "100%", margin: "0px auto", padding: "0px 20px" }}
            >
              <Row>
                <Col span={12}>
                  <div align="left" style={{ marginTop: "33px" }}>
                    <a
                      onClick={() => {
                        gotoHome();
                      }}
                      className="back-btn"
                    >
                      {" "}
                      <CaretLeftOutlined /> Back
                    </a>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    className="flex-container"
                    align="right"
                    style={{ marginTop: "20px" }}
                  >
                    <Space style={{ marginBottom: 16 }}>
                      <Button
                        className="add-btn"
                        size="large"
                        type="primary"
                        onClick={() => {
                          showModal();
                          setTitle("Add User");
                        }}
                      >
                        <PlusCircleOutlined />
                        Add User
                      </Button>

                      {/*   <Button size="large" onClick={clearAll} className="clear-btn">Clear filters</Button>
                       */}
                    </Space>
                    <Modal
                      bodyStyle={{
                        overflowX: "auto",
                        maxHeight: "calc(80vh - 200px)",
                      }}
                      title={title}
                      visible={visible}
                      width={600}
                      onCancel={handleCancel}
                      footer={[
                        <Button
                          key="back"
                          className="cancel-btn atn-btn"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={confirmLoading}
                          onClick={handleOk}
                        >
                          Submit
                        </Button>,
                      ]}
                    >
                      <Form
                        {...formItemLayout}
                        form={form}
                        name="user"
                        initialValues={{
                          residence: ["zhejiang", "hangzhou", "xihu"],
                          prefix: "86",
                        }}
                        scrollToFirstError
                      >
                        <Form.Item name="key" style={{ display: "none" }}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name="role_id"
                          label="User"
                          rules={[
                            {
                              required: true,
                              message: "Please input user!",
                            },
                          ]}
                        >
                          <Select>
                            {roles &&
                              roles.map((item, index) => {
                                return (
                                  <Option
                                    key={item.role_id}
                                    value={item.role_id}
                                  >
                                    {item.role_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="email_id"
                          label="Email"
                          rules={[
                            {
                              type: "email",
                              required: true,
                              message: "Please input email!",
                              whitespace: true,
                            },
                          ]}
                        >
                          <AutoComplete
                            options={emailList}
                            onSearch={onEmailSearch}
                            onSelect={getPrid}
                            // style={{
                            //     width: 200,
                            // }}
                          />
                          {/* <Input /> */}
                        </Form.Item>

                        <Form.Item
                          name="prid_id"
                          label="PRID"
                          rules={[
                            {
                              required: true,
                              message: "Please input PRID!",
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name="territory_ids"
                          label="Territories"
                          rules={[
                            {
                              required: true,
                              message: "Please input territories!",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={selectedTerritories}
                          >
                            {territories &&
                              territories.map((item, index) => {
                                return (
                                  <Option
                                    key={item.territoryId}
                                    value={item.territoryId}
                                  >
                                    {item.territory}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={24}>
                <Form form={usersForm} component={false}>
                  <Table
                    style={{
                      width: "100%",
                      margin: "0px auto",
                      padding: "0px 20px",
                    }}
                    className="content-table admin-table"
                    // components={{
                    //     body: {
                    //         cell: EditableCell,
                    //     },
                    // }}
                    onChange={handleChange}
                    bordered
                    dataSource={users}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={false}
                    // pagination={{
                    //     onChange: cancel,
                    // }}
                  ></Table>
                </Form>
              </Col>
            </Row>
          </div>
        </Layout>
      </div>
      {/* <Footer /> */}
    </AdminViewWrapper>
  );
};

export default Admin;
