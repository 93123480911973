import styled from "styled-components";

export const AdminViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 61px 0px 0px;
  background: #ffffff;
  overflow-y: scroll;
  .ant-table-column-title,
  th.ant-table-cell,
  .ant-table-column-sorters:hover .ant-table-column-sorter {
  }

  .ant-message > div > div {
    right: 0px;
    text-align: right;
  }
  .ant-segmented {
    background-color: #eeeeee;
    color: #000000;
  }
  .ant-table-thead {
    th {
      border-bottom: 0px solid #dedede;
      overflow-wrap: normal;
      text-align: center;
      padding: 5px 0px;
      color: #ffffff;
      border-right: 0px !important;
    }
  }
  .ant-table {
    tr:nth-child(even) {
      background-color: #f9f9f9 !important;
    }
    tr > td {
      padding: 5px 14px;
      overflow-wrap: normal;
      text-align: left;
    }
    tr > td::last-child {
      text-align: center;
    }
  }
  .add-btn {
    color: #ffffff;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
    background-color: #2e547a;
    padding: 5px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 2px 3px #00000022;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    border: 1px solid #2e547a;
  }
  .clear-btn {
    font-size: 14px;
    color: #2e547a;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
    border-color: #2e547a;
    padding: 5px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 2px 3px #00000022;
    cursor: pointer;
    white-space: nowrap;
  }
`;
