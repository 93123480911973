import { max, min, range } from "d3-array";
import { scaleLinear, scaleLog, scaleOrdinal, scaleSqrt } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { getCookie } from "./cookie-helper";

export function choose(array, random) {
  return array[Math.floor(random() * array.length)];
}

export function getDefaultColors() {
  return range(20)
    .map((number) => number.toString())
    .map(scaleOrdinal(schemeCategory10));
}
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getFontScale(words, fontSizes, scale) {
  const minSize = min(words, (word) => Number(word.value));
  const maxSize = max(words, (word) => Number(word.value));
  let scaleFunction;
  switch (scale) {
    case "log":
      scaleFunction = scaleLog;
      break;
    case "sqrt":
      scaleFunction = scaleSqrt;
      break;
    case "linear":
    default:
      scaleFunction = scaleLinear;
      break;
  }

  const fontScale = scaleFunction().domain([minSize, maxSize]).range(fontSizes);
  return fontScale;
}

export function getFontSize(word) {
  return `${word.size}px`;
}

export function getText(word) {
  return word.text;
}

export function getTransform(word) {
  const translate = `translate(${word.x}, ${word.y})`;
  const rotate =
    typeof word.rotate === "number" ? `rotate(${word.rotate})` : "";
  return translate + rotate;
}

export function rotate(rotations, rotationAngles, random) {
  if (rotations < 1) {
    return 0;
  }

  let angles = [];
  if (rotations === 1) {
    angles = [rotationAngles[0]];
  } else {
    angles = [...rotationAngles];
    const increment = (rotationAngles[1] - rotationAngles[0]) / (rotations - 1);
    let angle = rotationAngles[0] + increment;
    while (angle < rotationAngles[1]) {
      angles.push(angle);
      angle += increment;
    }
  }

  return choose(angles, random);
}
export function isUndefined(obj) {
  return typeof obj === "undefined" || obj === "undefined" || obj == null
    ? true
    : false;
}
export function defaultValue(obj) {
  return isUndefined(obj) ? "NA" : obj;
}
export function convertToTicks(dtStr) {
  // ToDo: Need to do in a better way
  var from = dtStr.split("-");
  var dNew = new Date(from[0], from[1] - 1, from[2]);
  var dStart = new Date(1970, 0, 1);
  var seconds = dNew.getTime();
  var secondsStart = dStart.getTime();
  var dateDifference = seconds - secondsStart;
  return dateDifference;
}
export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}
export function getUserName() {
  var username = "";
  if (getCookie("userName")) {
    let usernameFull = getCookie("userName");
    username = usernameFull
      .match(/(\b\S)?/g)
      .join("")
      .toUpperCase();
  }
  return username;
  // $(".userInfo").html(username);
}

export function getProfileInfo() {
  let _curSelProfile = "";
  var defaultProfile = localStorage.getItem("SSN_USER_PROFILE");
  if (defaultProfile) {
    _curSelProfile = defaultProfile;
    let _curSelDataSource = "";
    var defaultDataSource = localStorage.getItem("SSN_USER_DATASOURCE");
    if (defaultDataSource) {
      _curSelDataSource = " - " + defaultDataSource;
    }
    let dateStr = "";
    var frmDt = localStorage.getItem("SSN_FROMDT");
    var toDt = localStorage.getItem("SSN_TODT");
    if (frmDt) {
      dateStr = " (" + frmDt;
      if (toDt) {
        dateStr += " - " + toDt + ")";
      } else {
        dateStr += " - today)";
      }
    }
    return `${_curSelProfile}${_curSelDataSource} - ${dateStr}`;
  }
  return null;
}

export function setCurrentUrl(tab) {
  localStorage.setItem("CURRENTURL", window.location.protocol);
  localStorage.setItem("CURRENTURLHOST", window.location.host);
  localStorage.setItem("CURRENTURLPATHNAME", window.location.pathname);
  localStorage.setItem("CURRENTTAB", tab);
}

export function rgbToHex(color) {
  color = "" + color;
  if (!color || color.indexOf("rgb") < 0) {
    return;
  }

  if (color.charAt(0) == "#") {
    return color;
  }

  var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
    r = parseInt(nums[2], 10).toString(16),
    g = parseInt(nums[3], 10).toString(16),
    b = parseInt(nums[4], 10).toString(16);

  return (
    "#" +
    ((r.length == 1 ? "0" + r : r) +
      (g.length == 1 ? "0" + g : g) +
      (b.length == 1 ? "0" + b : b))
  );
}

export function lightOrDark(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 170.5) {
    return "light";
  } else {
    return "dark";
  }
}
