import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  .userInfoIcon {
    background-color: #cccccc;
  }
`;
export const User = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 20px;
`;

export const UserInfoName = styled.span`
  color: #000000;
  text-align: right;
`;

export const UserInfoDesignation = styled.span`
  color: #000000;
  width: 150px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
