import { Input } from "antd";
import React from "react";
import { TitleSearchWrapper } from "./TitleSearch.styles";

const Search = Input.Search;
const TitleSearch = ({ value, onChange, onSearch, ...props }) => (
  <TitleSearchWrapper {...props}>
    <Search
      placeholder="Search HCP/Account"
      onSearch={onSearch}
      allowClear
      value={value}
      onChange={onChange}
      enterButton="Search"
    />
  </TitleSearchWrapper>
);

TitleSearch.propTypes = {
  // bla: PropTypes.string,
};

TitleSearch.defaultProps = {
  // bla: 'test',
};

export default TitleSearch;
