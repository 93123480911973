import {
  CloseOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Modal,
  PageHeader,
  Popover,
  Row,
  Skeleton,
  Spin,
  Switch,
  Table,
  Tabs,
  Tag,
} from "antd";
import D3Funnel from "d3-funnel";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import "react-vertical-timeline-component/style.min.css";
import callIcn from "../../assets/img/callIcn.png";
import EfficacyIcon from "../../assets/img/efficacy.png";
import emailIcn from "../../assets/img/emailIcn.png";
import ImbruvicaIcon from "../../assets/img/imbruvica.png";
import Footer from "../../components/Footer";
import SegmentationChart from "../../components/SegmentationChart";
import StackedBarChart from "../../components/StackedBarChart";
import StackedHorizontalBarChart from "../../components/StackedHorizontalBarChart";
import TimelineHistory from "../../components/TimelineHistory";
import UnauthorisedErrorView from "../../components/UnauthorisedErrorView/UnauthorisedErrorView";
import CONFIG from "../../constants";
import { GetAPIRequest, PostAPIRequest } from "../../services";
import { getIsTriggerChartPercent, setIsTriggerChartPercent } from "../../store/appSlice";
import { store } from "../../store/store";
import {
  setTriggers,
  setTriggerType,
  updateTriggerIdx,
} from "../../store/triggerSlice";
import { checkUnauthorised } from "../../utils/cookie-helper";
import CompleteTriggerAction from "../TriggerAction/CompleteTriggerAction";
import DismissTriggerAction from "../TriggerAction/DismissTriggerAction";
import {
  CardMessage,
  CardRecommendedMessage,
  CardTags,
  CardTitle,
  CardViewWrapper,
  CLLPatientsViewWrapper,
  HomeWrapper,
} from "./DetailView.styles";
const { TabPane } = Tabs;
export const useSwipe = ({ left, right, up, down }) => {
  const touchCoordsRef = useRef({
    touchStart: { x: 0, y: 0, time: new Date() },
  });
  const fnsRef = useRef({ up, down, left, right });
  fnsRef.current = {
    up,
    left,
    down,
    right,
  };
  useEffect(() => {
    const handleTouchStart = (e) => {
      touchCoordsRef.current.touchStart.x = e.targetTouches[0].clientX;
      touchCoordsRef.current.touchStart.y = e.targetTouches[0].clientY;
      touchCoordsRef.current.touchStart.time = new Date();
    };
    const handleTouchEnd = (e) => {
      const threshold = 150;
      const swipeSpeed = 1; // sec;
      const touchEndX = e.changedTouches[0].clientX;
      const touchEndY = e.changedTouches[0].clientY;
      const touchStartX = touchCoordsRef.current.touchStart.x;
      const touchStartY = touchCoordsRef.current.touchStart.y;
      const elapsedTime =
        (new Date() - touchCoordsRef.current.touchStart.time) / 1000;
      if (elapsedTime > swipeSpeed) {
        return;
      }
      const xDistance = touchStartX - touchEndX;
      const yDistance = touchStartY - touchEndY;

      if (Math.abs(xDistance) < threshold && Math.abs(yDistance) < threshold) {
        return;
      }

      if (Math.abs(xDistance) >= Math.abs(yDistance)) {
        xDistance > 0 ? fnsRef.current.right?.() : fnsRef.current.left?.();
      } else {
        yDistance > 0 ? fnsRef.current.down?.() : fnsRef.current.up?.();
      }
    };
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  });
};
const DetailView = (props) => {
  const [isLoading, setLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const [hcpInfo, setHcpInfo] = useState({});
  const [prescriberInfo, setPrescriberInfo] = useState({});
  const [prescriberExtInfo, setPrescriberExtInfo] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [segmentation, setSegmentation] = useState("");
  const [priority, setPriority] = useState("");
  const [dismissList, setDismissList] = useState([]);
  const [markAsCompleteList, setMarkAsCompleteList] = useState([]);
  const [insightsMessage, setInsightsMessage] = useState("");
  const [isSfDataLoading, setIsSfDataLoading] = useState(true);
  const [caVisible, setCaVisible] = useState(false);
  const [psVisible, setPsVisible] = useState(false);
  const [showPrevBtn, setShowPrevBtn] = useState(true);
  const [showNextBtn, setShowNextBtn] = useState(true);
  const [isTriggerPercent, setIsTriggerPercent] = useState(true);
  var isTriggerChartPercent = useSelector(getIsTriggerChartPercent);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOnClick = useCallback(() => {
    navigate("/triggers", { replace: true });
    dispatch(updateTriggerIdx(0));
  }, [navigate]);
  let {
    triggerid,
    providerId,
    affiliations,
    providerName,
    duedate,
    status,
    currentIndex,
  } = useParams();
  /* width from window */
  const [width, setWidth] = useState(window.innerWidth);
  const [triggerDate, setTriggerDate] = useState("");
  const [height, setHeight] = useState(window.innerHeight);
  const [timelineData, setTimelineData] = useState([]);
  const [adoptionStage, setAdoptionStage] = useState(null);
  const [isUnauthorised, setIsUnauthorised] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [currentTriggerCount, setCurrentTriggerCount] = useState(0);
  const [currentTriggerIdx, setCurrentTriggerIdx] = useState(0);
  var adoptionStages = null;
  var chart = null;
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    if (adoptionStages) {
      loadFunnel(adoptionStages);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      console.log("User Left Swiped!", eventData);
      handleNextClick();
    },
    onSwipedRight: (eventData) => {
      console.log("User Right Swiped!", eventData);
      handlePreviousClick();
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    if (duedate) {
      setTriggerDate(duedate);
    }
    getTriggerActionList();
    getTriggerInfo();
    getPrescriberInfo1();
    getStageInfo();
    const currentIdx = store.getState().trigger.currentIdx;
    const triggers = store.getState().trigger.triggerData;
    if (triggers.length == 0) {
      getTriggersList(status);
    } else {
      setCurrentTriggerIdx(currentIdx + 1);
      setCurrentTriggerCount(triggers.length);
      if (currentIdx == triggers.length - 1) {
        setShowNextBtn(false);
      }
      if (currentIdx == 0) {
        setShowPrevBtn(false);
      }
    }

    window.scrollTo(0, 0);
    return () => {
      adoptionStages = null;
      chart = null;
    };
  }, [location]);

  const getTriggersList = (type) => {
    setLoading(true);
    GetAPIRequest({
      url: `${CONFIG.api.getTriggersList}?type=${type}`,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (r.status) {
            setIsUnauthorised(true);
            setErrorMessage(r.message);
          } else {
            if (response) {
              if (response.data) {
                dispatch(setTriggers(response.data));
                dispatch(setTriggerType(type));
                dispatch(updateTriggerIdx(parseInt(currentIndex)));
                setCurrentTriggerIdx(parseInt(currentIndex) + 1);
                setCurrentTriggerCount(response.data.length);
                if (parseInt(currentIndex) == response.data.length - 1) {
                  setShowNextBtn(false);
                }
                if (parseInt(currentIndex) == 0) {
                  setShowPrevBtn(false);
                }
              }
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getStageInfo = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getAdoptionStage}/${providerId}/uniqueTriggerId/${triggerid}`,
    }).then((res) => {
      if (res) {
        var r = checkUnauthorised(res);
        if (!r.status) {
          adoptionStages = res;
          setAdoptionStage(res);
          loadFunnel(res);
        }
      }
    });
  };
  const loadFunnel = (_response) => {
    /*const data = [
      { label: "Awareness", value: 500, labelColor: "#000000" },
      { label: "Learn/Evaluate", value: 450, labelColor: "#000000" },
      { label: "Trial", value: 400, labelColor: "#000000" },
      { label: "Convert", value: 350, labelColor: "#000000" },
      { label: "Advocate", value: 300, labelColor: "#000000" },
    ];*/
    var scale = [];
    var resArray = [];
    if (Array.isArray(_response)) {
      for (var i = 0; i < _response.length; i++) {
        var p = {};
        var val = 500 - i * 10;
        if (_response[i].value === true) {
          p.labelColor = "#ffffff";
          scale.push("#584E8D");
        } else {
          scale.push("#ECF5FD");
          p.labelColor = "#000000";
        }
        p.value = val;
        p.status = _response[i].value;
        p.label = _response[i].name;
        resArray.push(p);
      }

      const options = {
        chart: {
          height: 330,
        },
        block: {
          dynamicHeight: false,
          minHeight: 15,
          fill: {
            type: "solid",
            scale: scale,
          },
        },
        label: {
          format: "{l}",
        },
      };
      if (chart) {
        chart.destroy();
      }
      chart = new D3Funnel("#enagagementChart");
      chart.draw(resArray, options);
    }
  };
  const getTriggerInfo = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getTriggerDetailsById}/${triggerid}`,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);

          if (!r.status) {
            setHcpInfo(response);
            if (response.drivers) {
              /*var drivers = response.drivers;
              var driversArr = drivers.split(",");
              setDrivers(driversArr);*/
              setDrivers(response.drivers);
            }
            if (response.insights) {
              var insights = response.insights;
              var insightsmessage = "";
              var insightsArr = insights.split("<p>");
              insightsArr.map((insight, idx) => {
                var t = insight.replaceAll("</p><br>", "</p>");
                if (idx == 2) {
                  t = t.replaceAll("<br>", "");
                  insightsmessage += t;
                } else {
                  insightsmessage += t;
                }
              });
              var t = insightsArr[2].replace("</p>", "");
              t = t.replaceAll("<br>", "");
              setInsightsMessage(t);
              response.insights = insightsmessage;
            }
          } else {
            setIsUnauthorised(true);
            setErrorMessage(r.message);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getPrescriberInfo1 = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getPrescriberDetailsById1}/${providerId}/uniqueTriggerId/${triggerid}/internal`,
    }).then((response) => {
      if (response) {
        var r = checkUnauthorised(response);
        if (!r.status) {
          setPrescriberInfo(response);
          setPriority(response.priority);
          setSegmentation(response.segmentation);
          loadFunnel(response);
          if (response.dueDate) {
            setTriggerDate(response.dueDate);
          }
        }
      }
    });
    GetAPIRequest({
      url: `${CONFIG.api.getPrescriberDetailsById1}/${providerId}/uniqueTriggerId/${triggerid}/external`,
    })
      .then((response) => {
        setIsSfDataLoading(false);
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            setPrescriberExtInfo(response);
          }
        }
      })
      .catch((error) => {
        setIsSfDataLoading(false);
      });
  };

  const getTriggerActionList = () => {
    GetAPIRequest({
      url: `${CONFIG.api.dismissedTriggerActions}`,
    }).then((response) => {
      if (response) {
        var r = checkUnauthorised(response);
        if (!r.status) {
          setDismissList(response);
        }
      }
    });

    GetAPIRequest({
      url: `${CONFIG.api.completedTriggerActions}`,
    }).then((response) => {
      if (response) {
        var r = checkUnauthorised(response);
        if (!r.status) {
          setMarkAsCompleteList(response);
        }
      }
    });
  };

  const submitTriggerAction = (requestObject) => {
    setLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.triggerActionSave}`,
      data: requestObject,
    })
      .then((response) => {
        setLoading(false);
        handleOnClick();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleEmailClick = () => {
    if (prescriberExtInfo && prescriberExtInfo.sfAccountId) {
      window.open(
        `oce://deeplink/tab/OCE__Accounts/sobject-name/Account/record-id/${prescriberExtInfo.sfAccountId}/quick-action/OCE__EMAIL`
      );
    }
  };
  const handleCallClick = () => {
    if (prescriberExtInfo && prescriberExtInfo.sfAccountId) {
      window.open(
        `oce://deeplink/modal/sobject-name/OCE__Call__c/action/new?uid=${prescriberExtInfo.sfAccountId}`
      );
    }
  };

  const HcpModal = (props) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <b>{text}</b>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => <span style={{ fontWeight: 500 }}>{text}</span>,
      },
    ];
    const data = [
      {
        key: "1",
        name: "BTKi Defaulters",
        description:
          "CLL treaters who take a more standardized approach to treatment, with high ibrutinib use across current 1L and R/R; slower adopters of new therapies, and prefer treat to progression therapies for HR patients; high ibrutinib usage in AF and hypertensive patients.",
      },
      {
        key: "2",
        name: "BTKi Progressives",
        description:
          "CLL treaters who prefer to use BTKi in front line and reserve Ven for R/R therapy. Believe in the importance of biomarker testing and prefer treat to progression for HR patients.",
      },
      {
        key: "3",
        name: "Treatment Customizers",
        description:
          "High volume CLL treaters who take a customized treatment approach depending on the patient and don’t shy away from any particular class of therapy. Tend to be scientific leaders and early adopters",
      },
      {
        key: "4",
        name: "CIT Traditionalists",
        description:
          "CLL treaters who experience relatively low CLL volume and still rely on CITs for a majority of their CLL patients today.",
      },
      {
        key: "5",
        name: "DR/-",
        description:
          "Unsegmented CLL treater, or CLL treater with data restiction (DR) request.",
      },
    ];
    const pdata = [
      {
        key: "1",
        name: "High",
        description:
          "High volume CLL treater (top 1-4 deciles) among BTKi Progressives, Treatment Customizers or BTKi Defaulters segment; or Medium volume CLL treater (top 5-8 deciles) among BTKi Progressives segment.",
      },
      {
        key: "2",
        name: "Medium",
        description:
          "Medium volume CLL treater (top 5-8 deciles) among Treatment Customizers, BTKi Defaulters or CIT Traditionalists segment; or Low volume CLL treater (top 9-10 deciles) among BTKi Progressives segment.",
      },
      {
        key: "3",
        name: "Low",
        description:
          "Low volume CLL treater (top 9-10 deciles) among Treatment Customizers or BTKi Defaulters segment.",
      },
      {
        key: "4",
        name: "Very Low",
        description:
          "Low volume CLL treater (top 9-10 deciles) among CIT Traditionalists segment.",
      },
      {
        key: "5",
        name: "Opp",
        description:
          "CLL treater not qualifying for High, Medium, Low or Very Low priority groups, but having actionable unmet needs based on Ibru or CIT use.",
      },
      {
        key: "6",
        name: "DR/-",
        description:
          "Non-prioritized CLL treater, or CLL treater with data restiction (DR) request.",
      },
    ];
    const mcl_data = [
      {
        key: "1",
        name: "Patient-Engaged Followers",
        description:
          "MCL treaters who are most concerned about patient compliance and out-of-pocket cost, look for guidance from KOLs and tend to avoid making decisions based on clinical data alone.",
      },
      {
        key: "2",
        name: "Traditionalists",
        description:
          "MCL treaters who lack MCL expertise, have difficulty in selecting later line therapy and refer out patients.",
      },
      {
        key: "3",
        name: "MCL Experts",
        description:
          "MCL treaters who are independent and consider themselves an expert in MCL, relying on clinical data over peer KOLs and seeking continual learning about MCL.",
      },
      {
        key: "4",
        name: "MCL Dabblers",
        description:
          "MCL treaters who have least expertise/interest in MCL, refer out patients, have the greatest difficulty selecting later line therapies, and are not interested in learning more about MCL.",
      },
      {
        key: "5",
        name: "DR/-",
        description:
          "Unsegmented MCL treater, or MCL treater with data restiction (DR) request.",
      },
    ];
    const mcl_pdata = [
      {
        key: "1",
        name: "High",
        description: "High volume MCL treater (top 1-4 deciles).",
      },
      {
        key: "2",
        name: "Medium",
        description: "Medium volume MCL treater (top 5-8 deciles).",
      },
      {
        key: "3",
        name: "Low",
        description: "Low volume MCL treater (top 9-10 deciles).",
      },
      {
        key: "4",
        name: "DR/-",
        description:
          "Non-prioritized CLL treater, or CLL treater with data restiction (DR) request.",
      },
    ];
    return (
      <Modal
        title=""
        centered
        footer={null}
        visible={psVisible}
        onCancel={() => setPsVisible(false)}
        width={"90vw"}
        className="hcpmodal"
        closeIcon={
          <span style={{ color: "#243E5E", fontSize: 35 }}>
            <CloseOutlined />
          </span>
        }
      >
        <div className="popup-title">
          Key attributes of the HCP based on treatment volume (defines the
          Priority), behavioral profile (defines the Segment and the Priority),
          and conversion stage (Engagement Stage is defined by exposure to
          promotional activity, prescribing behavior and/or key customer team
          activity).
        </div>

        <Tabs className="hcptabs" defaultActiveKey="1" centered>
          <TabPane tab="HCP Segmentation" key="1">
            <Table
              showHeader={false}
              pagination={false}
              bordered={true}
              columns={columns}
              dataSource={props.type == "CLL" ? data : mcl_data}
            />
          </TabPane>
          <TabPane tab="HCP Priority" key="2">
            <Table
              showHeader={false}
              pagination={false}
              bordered={true}
              columns={columns}
              dataSource={props.type == "CLL" ? pdata : mcl_pdata}
            />
          </TabPane>
          <TabPane tab="Engagement Stage" key="3">
            <div className="engagement-stage-container">
              <div className="funnel-chart-container">
                <div className="trapezoid" style={{ width: 350 }}>
                  <span>Awareness</span> <div className="guideline"></div>
                </div>
                <div className="trapezoid secondblock" style={{ width: 290 }}>
                  <span>Learn/Evaluate</span>
                  <div
                    className="guideline"
                    style={{ left: 240, width: 45 }}
                  ></div>
                </div>
                <div className="trapezoid" style={{ width: 230 }}>
                  <span>Trial</span>
                  <div
                    className="guideline"
                    style={{ left: 180, width: 80 }}
                  ></div>
                </div>
                <div className="trapezoid" style={{ width: 170 }}>
                  <span>Convert</span>
                  <div
                    className="guideline"
                    style={{ left: 110, width: 125 }}
                  ></div>
                </div>
                <div className="trapezoid" style={{ width: 110 }}>
                  <span>Advocate</span>
                  <div
                    className="guideline"
                    style={{ left: 50, width: 150 }}
                  ></div>
                </div>
              </div>
              <div className="funnel-chart-content-container">
                <div className="funnel-chart-content">
                  HCP is aware of CALQUENCE (has been exposed to and taken
                  action on non personal promotion).
                </div>
                <div className="funnel-chart-content">
                  HCP in interested in CALQUENCE (has recently been exposed to
                  personal promotion and/or engaged more closely on non personal
                  promotion channels).
                </div>
                <div className="funnel-chart-content">
                  HCP has recently used CALQUENCE and been exposed to personal
                  and/or non personal promotion activity.
                </div>
                <div className="funnel-chart-content">
                  HCP has become a repeat user of CALQUENCE and keep being
                  engaged with personal and/or non personal promotion activity.
                </div>
                <div className="funnel-chart-content">
                  HCP has high market share of CALQUENCE and/or has been
                  identified by the Key Customer Team as an Advocate.
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    );
  };

  const CAModal = (props) => {
    return (
      <Modal
        title=""
        centered={true}
        footer={null}
        visible={caVisible}
        onCancel={() => setCaVisible(false)}
        width={"90vw"}
        className="camodal"
        closeIcon={
          <span style={{ color: "#243E5E", fontSize: 35 }}>
            <CloseOutlined />
          </span>
        }
      >
        <>
          <div className="popup-title">
            Promotional channels which work best with the particular HCP, based
            on historical HCP behavioral activity data (shown in the order of
            next best activity sequence).
          </div>
          <div className="popup-button">Channel Affinities</div>
          <table className="pstable">
            <tbody>
              <tr className="pstable-row">
                <td className="pstable-column-title">
                  <span>Personal</span>
                </td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>In person sales representative</li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Personal remote</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>Telesales</li>
                    <li>
                      Live electronic rep interaction (videoconference, web
                      chat)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Peer interaction</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>
                      In person peer programs (speaker programs, lunch & learns,
                      etc.)
                    </li>
                    <li>
                      Remote, interactive peer programs (webinar, satellite
                      webcast, teleconference)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Digital pull</td>
                <td className="pstable-column-description">
                  <ul>
                    <li>Web or mobile microsite</li>
                    <li>Recorded video</li>
                  </ul>
                </td>
                <td className="pstable-column-description">
                  <ul>
                    <li>Self-guided e-details</li>
                    <li>Online advertising</li>
                  </ul>
                </td>
                <td className="pstable-column-description">
                  <ul>
                    <li>
                      Online resource requests (e-samples, e-vouchers, etc.)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Digital push</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>Email</li>
                    <li>Mobile or web portal alerts</li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Direct</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>Direct mail</li>
                    <li>Fax</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </Modal>
    );
  };

  const handleNextClick = () => {
    const currentIdx = store.getState().trigger.currentIdx;
    const triggers = store.getState().trigger.triggerData;
    var nextIdx = currentIdx + 1;
    if (nextIdx <= triggers.length - 1) {
      dispatch(updateTriggerIdx(nextIdx));
      var nextTrigger = triggers[nextIdx];
      navigate(
        `/trigger/${nextTrigger.uniqueId}/${nextTrigger.providerId}/${nextTrigger.dueDate}/${nextTrigger.status}/${nextIdx}`
      );
      setShowPrevBtn(true);
      setShowNextBtn(true);
    } else {
      setShowPrevBtn(true);
      setShowNextBtn(false);
    }
  };
  const handlePreviousClick = () => {
    const currentIdx = store.getState().trigger.currentIdx;
    const triggers = store.getState().trigger.triggerData;
    var prevIdx = currentIdx - 1;
    if (prevIdx >= 0) {
      dispatch(updateTriggerIdx(prevIdx));
      console.log("currentIdx", currentIdx);
      var prevTrigger = triggers[prevIdx];
      navigate(
        `/trigger/${prevTrigger.uniqueId}/${prevTrigger.providerId}/${prevTrigger.dueDate}/${prevTrigger.status}/${prevIdx}`
      );
      setShowPrevBtn(true);
      setShowNextBtn(true);
    } else {
      setShowPrevBtn(false);
      setShowNextBtn(true);
    }
  };

  return (
    <>
      <HomeWrapper {...handlers}>
        <Spin spinning={isLoading}>
          {isUnauthorised ? (
            <UnauthorisedErrorView message={errorMessage} />
          ) : (
            <>
              <PageHeader
                className="site-page-header"
                onBack={() => {
                  handleOnClick();
                }}
                backIcon={
                  <div className="trigger-title-back-wrapper">
                    <LeftOutlined />
                    <div className="trigger-title-wrapper">
                      <span className="trigger-title">Calquence Trigger</span>
                      <span className="trigger-date">{triggerDate}</span>
                    </div>
                  </div>
                }
                title=""
                extra={
                  prescriberInfo.showTriggerStatusBtns
                    ? [
                        <Button
                          key={0}
                          className="outline-btn"
                          onClick={handleCallClick}
                        >
                          <Image preview={false} src={callIcn} width={15} />{" "}
                          <span style={{ marginLeft: 10 }}>
                            Schedule a call
                          </span>
                        </Button>,

                        <Button
                          key={1}
                          className="outline-btn"
                          onClick={handleEmailClick}
                        >
                          <Image preview={false} src={emailIcn} width={15} />{" "}
                          <span style={{ marginLeft: 10 }}>Send an email</span>
                        </Button>,
                        <DismissTriggerAction
                          key={2}
                          data={dismissList}
                          submitTriggerAction={submitTriggerAction}
                          uniqueId={triggerid}
                        ></DismissTriggerAction>,
                        <CompleteTriggerAction
                          key={3}
                          data={markAsCompleteList}
                          submitTriggerAction={submitTriggerAction}
                          uniqueId={triggerid}
                        ></CompleteTriggerAction>,
                      ]
                    : [
                        <Button
                          key={4}
                          className="outline-btn"
                          onClick={handleCallClick}
                        >
                          <Image preview={false} src={callIcn} width={15} />{" "}
                          <span style={{ marginLeft: 10 }}>
                            Schedule a call
                          </span>
                        </Button>,
                        <Button
                          key={5}
                          className="outline-btn"
                          onClick={handleEmailClick}
                        >
                          <Image preview={false} src={emailIcn} width={15} />{" "}
                          <span style={{ marginLeft: 10 }}>Send an email</span>
                        </Button>,
                      ]
                }
              />
              <Row style={{ paddingTop: 60, paddingBottom: 20 }}>
                <Col xs={24}>
                  <div className="navigation-btn-group">
                    <div className="outline-btn navigation-btn-group-prev-btn">
                      {showPrevBtn && (
                        <span
                          onClick={handlePreviousClick}
                          className="navigation-btn-group-btn"
                        >
                          <LeftOutlined style={{ marginRight: 0 }} />
                          <LeftOutlined style={{ marginLeft: -10 }} /> Previous
                          Trigger
                        </span>
                      )}
                    </div>
                    <div className="navigation-btn-group-title">
                      Trigger {currentTriggerIdx} of {currentTriggerCount}
                    </div>
                    <div className="outline-btn navigation-btn-group-next-btn">
                      {showNextBtn && (
                        <span
                          onClick={handleNextClick}
                          className="navigation-btn-group-btn"
                        >
                          Next Trigger{" "}
                          <RightOutlined style={{ marginRight: -10 }} />
                          <RightOutlined style={{ marginRight: 5 }} />{" "}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col xs={24} lg={12} className="topLeftCardContainer">
                      <Row>
                        <Col xs={24}>
                          <CardViewWrapper className="topLeftCardWrapper">
                            <Row>
                              <Col xs={24} lg={14}>
                                <Row>
                                  <Col xs={24}>
                                    <CardTitle>Indication</CardTitle>
                                    <CardMessage>
                                      {hcpInfo.indication || ""}
                                    </CardMessage>
                                  </Col>
                                  <Col xs={24}>
                                    <CardTitle>
                                      Drivers{" "}
                                      <Popover
                                        placement="right"
                                        content={
                                          <div style={{ maxWidth: 400 }}>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  "Underlining reasons for the trigger (click on each separate driver to learn more).",
                                              }}
                                            ></p>
                                          </div>
                                        }
                                        title={""}
                                        trigger={"click"}
                                      >
                                        {" "}
                                        <InfoCircleOutlined />
                                      </Popover>
                                    </CardTitle>
                                    <CardTags>
                                      {drivers.map((driver, idx) => (
                                        <Tag key={idx} color="blue">
                                          <Popover
                                            placement="right"
                                            content={
                                              <div style={{ maxWidth: 400 }}>
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html: `<div>${
                                                      driver.description ||
                                                      driver.name
                                                    }</div>`,
                                                  }}
                                                ></p>
                                              </div>
                                            }
                                            title={""}
                                            trigger={"click"}
                                          >
                                            {driver.name}
                                            <InfoCircleOutlined
                                              style={{ marginLeft: 10 }}
                                            />
                                          </Popover>
                                        </Tag>
                                      ))}
                                    </CardTags>
                                  </Col>
                                  <Col xs={24}>
                                    <CardTitle>Insights</CardTitle>
                                    <CardMessage>
                                      {" "}
                                      <Popover
                                        placement="right"
                                        content={
                                          <div style={{ maxWidth: 400 }}>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: hcpInfo.insights || "",
                                              }}
                                            ></p>
                                          </div>
                                        }
                                        title="Insights"
                                        trigger="click"
                                      >
                                        {insightsMessage.substring(0, 120) +
                                          "..." || ""}
                                        <u>view more</u>
                                      </Popover>
                                    </CardMessage>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={24} lg={10}>
                                <CardRecommendedMessage>
                                  <div className="tag-topic-view">
                                    <div className="tag-topic">
                                      <div className="tag-topic-left"></div>
                                      <div className="tag-topic-middle">
                                        {(hcpInfo.topic &&
                                          hcpInfo.topic.toUpperCase()) ||
                                          ""}
                                      </div>
                                      <div className="tag-topic-right"></div>
                                    </div>
                                  </div>
                                  <div className="message-title">
                                    Recommended Message:
                                    <Popover
                                      placement="right"
                                      content={
                                        <div style={{ maxWidth: 400 }}>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                "Key Message recommendation for interaction/email based on trigger and HCP profile.",
                                            }}
                                          ></p>
                                        </div>
                                      }
                                      title={""}
                                      trigger={"click"}
                                    >
                                      <InfoCircleOutlined />
                                    </Popover>
                                  </div>
                                  <div className="message-content">
                                    {prescriberInfo.recommended_messages &&
                                      prescriberInfo.recommended_messages.map(
                                        (d, idx) => {
                                          return (
                                            <div className="icon-ctn" key={idx}>
                                              <Image
                                                src={
                                                  d == "Elevate efficacy"
                                                    ? EfficacyIcon
                                                    : ImbruvicaIcon
                                                }
                                                preview={false}
                                                width={20}
                                              />
                                              <span>{d}</span>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </CardRecommendedMessage>
                              </Col>
                            </Row>
                          </CardViewWrapper>
                        </Col>
                        <Col xs={24}>
                          <CardViewWrapper className="topLeftCardWrapper">
                            <Row>
                              <Col xs={24}>
                                <CardTitle>
                                  Channel affinities{" "}
                                  <InfoCircleOutlined
                                    onClick={() => {
                                      setCaVisible(true);
                                    }}
                                  />
                                </CardTitle>
                                <CardMessage>
                                  {prescriberInfo.channelaffinity &&
                                  Array.isArray(
                                    prescriberInfo.channelaffinity
                                  ) &&
                                  prescriberInfo.channelaffinity.length > 0
                                    ? prescriberInfo.channelaffinity.join(", ")
                                    : "-"}
                                </CardMessage>
                                <CAModal />
                              </Col>
                              <Col xs={24}>
                                <div className="message-title">
                                  HCP Priority, Segmentation & Engagement Stage{" "}
                                  <InfoCircleOutlined
                                    onClick={() => {
                                      setPsVisible(true);
                                    }}
                                  />
                                  <HcpModal
                                    type={
                                      hcpInfo.indication &&
                                      (hcpInfo.indication.includes("MCL") ||
                                        hcpInfo.indication.includes("mcl"))
                                        ? "MCL"
                                        : "CLL"
                                    }
                                  />
                                </div>
                                <Row>
                                  <Col xs={24} lg={16}>
                                    <SegmentationChart
                                      priority={priority}
                                      segmentation={segmentation}
                                    />
                                  </Col>
                                  <Col xs={24} lg={8}>
                                    <div
                                      id="enagagementChart"
                                      className="funnel-chart"
                                    ></div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardViewWrapper>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} lg={12} className="topRightCardContainer">
                      <CardViewWrapper className="topRightCardWrapper">
                        <Row>
                          <Col xs={24}>
                            <Row>
                              <Col xs={24} >
                              <div className="title-container-1">
                                  <Switch
                                    checkedChildren="Percentage"
                                    unCheckedChildren="Percentage"
                                    defaultChecked={isTriggerChartPercent}
                                    onChange={(checked) => {
                                      dispatch(
                                        setIsTriggerChartPercent(checked)
                                      );
                                      setIsTriggerPercent(checked);
                                    }}
                                  />
                                  </div>
                              </Col>
                              <Col xs={24} lg={12}>
                                <CardTitle>HCP Name</CardTitle>
                                <CardMessage>
                                  <Skeleton
                                    style={{ margin: 0, padding: 0 }}
                                    size={"small"}
                                    active={prescriberInfo.hcpName == null}
                                    paragraph={{ rows: 0 }}
                                    loading={prescriberInfo.hcpName == null}
                                  >
                                    {prescriberInfo.hcpName}
                                  </Skeleton>
                                </CardMessage>
                              </Col>
                              <Col xs={24} lg={12}>
                                <CardTitle>Level of Access</CardTitle>
                                <CardMessage>
                                  <Skeleton
                                    style={{ margin: 0, padding: 0 }}
                                    size={"small"}
                                    active={isSfDataLoading}
                                    paragraph={{ rows: 0 }}
                                    loading={isSfDataLoading}
                                  >
                                    {prescriberExtInfo.level_of_access || ""}
                                  </Skeleton>
                                </CardMessage>
                              </Col>
                              <Col xs={24} lg={12}>
                                <CardTitle>AZ ID</CardTitle>
                                <CardMessage>
                                  <Skeleton
                                    style={{ margin: 0, padding: 0 }}
                                    size={"small"}
                                    active={isSfDataLoading}
                                    paragraph={{ rows: 0 }}
                                    loading={isSfDataLoading}
                                  >
                                    {prescriberExtInfo.azid}
                                  </Skeleton>
                                </CardMessage>
                              </Col>
                              <Col xs={24} lg={12}>
                                <CardTitle>Communication Pref</CardTitle>
                                <CardMessage>
                                  <Skeleton
                                    style={{ margin: 0, padding: 0 }}
                                    size={"small"}
                                    active={true}
                                    paragraph={{ rows: 0 }}
                                    loading={isSfDataLoading}
                                  >
                                    {prescriberExtInfo.communication_preference ||
                                      ""}
                                  </Skeleton>
                                </CardMessage>
                              </Col>

                              <Col xs={24}>
                                <CLLPatientsViewWrapper className="cllPatientsViewWrapper">
                                  <Row>
                                    <Col xs={24}>
                                      <StackedHorizontalBarChart
                                        hcpInfo={hcpInfo}
                                        prescriberInfo={prescriberInfo}
                                      />
                                    </Col>
                                  </Row>
                                </CLLPatientsViewWrapper>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs={24}>
                            <StackedBarChart
                              hcpInfo={hcpInfo}
                              prescriberInfo={prescriberInfo}
                            />
                          </Col>
                        </Row>
                      </CardViewWrapper>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col xs={24}>
                      <div className="cardViewWrapper">
                        <div className="timelineContentWrapper">
                          <TimelineHistory data={timelineData} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Footer />
            </>
          )}
        </Spin>
      </HomeWrapper>
    </>
  );
};

export default DetailView;
