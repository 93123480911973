import { Footer } from "antd/lib/layout/layout";
import React from "react";
import { FooterWrapper } from "./Footer.styles";

const AZFooter = (props) => (
  <FooterWrapper>
    <Footer className="footer" style={props.style}>
      <span>© 2022 AstraZeneca. All Rights Reserved. </span>
      <span className="emaillink">
        Contact Us:{" "}
        <a href="mailto:azbrain-oce-support@astrazeneca.com">
          azbrain-oce-support@astrazeneca.com
        </a>{" "}
      </span>
      {/* <ul>
        <li>Privacy Policy</li>
        <li>Terms of Service</li>
        <li><InstagramOutlined /></li>
        <li><TwitterOutlined /></li>
        <li><FacebookOutlined /></li>
      </ul> */}
    </Footer>
  </FooterWrapper>
);

export default AZFooter;
