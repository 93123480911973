import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  triggerData: [],
  type: "pending",
  currentIdx: 0,
};

export const triggerSlice = createSlice({
  name: "trigger",
  initialState,
  reducers: {
    setTriggerType: (state, action) => {
      state.type = action.payload;
    },
    setTriggers: (state, action) => {
      state.triggerData = action.payload;
    },
    updateTriggerIdx: (state, action) => {
      state.currentIdx = action.payload;
    },
  },
});

export const {
  updateTriggerIdx,
  setTriggers,
  setTriggerType,
} = triggerSlice.actions;

export const getTriggerType = (state) => state.trigger.type;
export const getTriggers = (state) => state.trigger.triggerData;
export const getCurrentIdx = (state) => state.trigger.currentIdx;

export default triggerSlice.reducer;
