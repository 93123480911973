import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

let savedTerritories = sessionStorage.getItem("territories");
if (savedTerritories) {
  savedTerritories = savedTerritories.split(",");
}
let savedFilters = sessionStorage.getItem("filters");
if (savedFilters) {
  savedFilters = savedFilters.split(",");
}
let isFiltersClearedByUser = sessionStorage.getItem("isFiltersClearedByUser");
if (isFiltersClearedByUser == null) {
  sessionStorage.setItem("isFiltersClearedByUser", false);
}

const initialState = {
  currentView: "triggers",
  currCustomerType: "HCP",
  indicationType: sessionStorage.getItem("indicationType") || "ALL",
  selectedGeoTerritories: savedTerritories || [],
  selectedGeoFilters: [],
  selectedGeoFilterName: savedFilters || [],
  isFiltersClearedByUser: isFiltersClearedByUser,
  insightsMenuValue: "insights",
  isNationalLevelChartPercent: true,
  isHcpLevelChartPercent: true,
  isAccountChartPercent: true,
  isParentAccountChartPercent: true,
  isTriggerChartPercent: true,
  isCustomerTablePercent: true,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setIndicationType: (state, action) => {
      sessionStorage.setItem("indicationType", action.payload);
      state.indicationType = action.payload;
    },
    setCurrCustomerType: (state, action) => {
      sessionStorage.setItem("currCustomerType", action.payload);
      state.currCustomerType = action.payload;
    },
    setSelectedGeoTerriroties: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.isFiltersClearedByUser = false;
        sessionStorage.setItem("isFiltersClearedByUser", false);
      }
      sessionStorage.setItem("territories", [...action.payload]);
      state.selectedGeoTerritories = [...action.payload];
    },
    setSelectedGeoFilters: (state, action) => {
      //sessionStorage.setItem("filters", [...action.payload]);
      state.selectedGeoFilters = [...action.payload];
    },
    setSelectedGeoFilterName: (state, action) => {
      sessionStorage.setItem("filters", [...action.payload]);
      state.selectedGeoFilterName = [...action.payload];
    },
    setIsFiltersClearedByUser: (state, action) => {
      sessionStorage.setItem("isFiltersClearedByUser", action.payload);
      state.isFiltersClearedByUser = action.payload;
    },
    setInsightsMenuValue: (state, action) => {
      sessionStorage.setItem("insightsMenuValue", action.payload);
      state.insightsMenuValue = action.payload;
    },
    setIsNationalLevelChartPercent: (state, action) => {
      sessionStorage.setItem("isNationalLevelChartPercent", action.payload);
      state.isNationalLevelChartPercent = action.payload;
    },
    setIsHcpLevelChartPercent: (state, action) => {
      sessionStorage.setItem("isHcpLevelChartPercent", action.payload);
      state.isHcpLevelChartPercent = action.payload;
    },
    setIsAccountChartPercent: (state, action) => {
      sessionStorage.setItem("isAccountChartPercent", action.payload);
      state.isAccountChartPercent = action.payload;
    },
    setIsParentAccountChartPercent: (state, action) => {
      sessionStorage.setItem("isParentAccountChartPercent", action.payload);
      state.isParentAccountChartPercent = action.payload;
    },
    setIsTriggerChartPercent: (state, action) => {
      sessionStorage.setItem("isTriggerChartPercent", action.payload);
      state.isTriggerChartPercent = action.payload;
    },
    setIsCustomerTablePercent: (state, action) => {
      sessionStorage.setItem("isCustomerTablePercent", action.payload);
      state.isCustomerTablePercent = action.payload;
    },
  },
});

export const {
  setCurrentView,
  setIndicationType,
  setSelectedGeoTerriroties,
  setSelectedGeoFilters,
  setSelectedGeoFilterName,
  setCurrCustomerType,
  setIsFiltersClearedByUser,
  setInsightsMenuValue,
  setIsNationalLevelChartPercent,
  setIsHcpLevelChartPercent,
  setIsAccountChartPercent,
  setIsParentAccountChartPercent,
  setIsCustomerTablePercent,
  setIsTriggerChartPercent
} = appSlice.actions;

export const getCurrentView = (state) => state.app.currentView;
export const getIndicationType = (state) => state.app.indicationType;
export const getCurrCustomerType = (state) => state.app.currCustomerType;
export const getSelectedGeoTerriroties = (state) =>
  state.app.selectedGeoTerritories;
export const getSelectedGeoFilters = (state) => state.app.selectedGeoFilters;
export const getSelectedGeoFilterName = (state) =>
  state.app.selectedGeoFilterName;
export const getIsFiltersClearedByUser = (state) =>
  state.app.isFiltersClearedByUser;
export const getInsightsMenuValue = (state) => state.app.insightsMenuValue;
export const getIsNationalLevelChartPercent = (state) =>
  state.app.isNationalLevelChartPercent;
export const getIsHcpLevelChartPercent = (state) =>
  state.app.isHcpLevelChartPercent;
export const getIsAccountChartPercent = (state) =>
  state.app.isAccountChartPercent;
export const getIsParentAccountChartPercent = (state) =>
  state.app.isParentAccountChartPercent;
export const getIsCustomerTablePercent = (state) =>
  state.app.isCustomerTablePercent;
export const getIsTriggerChartPercent = (state) =>
state.app.isTriggerChartPercent;
export default appSlice.reducer;
