import { Image } from "antd";
import React from "react";
import FooterLogo from "../../assets/img/az_brain_logo.png";
import topLogo from "../../assets/img/calicon.png";
import azLogo from "../../assets/img/logo-az-white.png";
import { UnauthorisedErrorViewWrapper } from "./UnauthorisedErrorView.styles";
const CircleView = () => <div className="circle-dot"></div>;
const UnauthorisedErrorView = (props) => (
  <UnauthorisedErrorViewWrapper>
    <div className="cal-header-wrapper">
      <Image src={azLogo} style={{ width: 100 }} preview={false} />
    </div>
    <div className="cal-error-wrapper">
      <div className="cal-top-wrapper">
        <div className="cal-icon">
          <Image
            src={topLogo}
            style={{ width: 395, marginRight: -150, marginTop: -20 }}
            preview={false}
          />
        </div>
        <div className="cal-content">
          <span className="cal-title">Welcome to the AZBrain app</span>
          <span className="cal-desc">
            <b>
              This platform is currently accessible to the US Hematology team.
              <br />
              Additional tumor teams, functions and countries will be supported
              soon.
            </b>
            <br />
            For further information or suggestions, please email:
            <br />
            <a
              style={{ marginLeft: 0 }}
              href="mailto:azbrain-oce-support@astrazeneca.com"
            >
              azbrain-oce-support@astrazeneca.com
            </a>
          </span>
        </div>
      </div>
      <div className="cal-footer">
        <span className="cal-footer-message">
          The AZ Brain app is a learning system powered by advanced algorithms
          such as machine learning and artificial intelligence (ML/AI),
          providing actionable insights, competitive intelligence and
          recommended messaging
        </span>
        <span className="cal-footer-logo">
          <Image src={FooterLogo} style={{ width: 100 }} preview={false} />
        </span>
      </div>
    </div>
    {/*<Image preview={false} className="errorIcon" src={ErrorIcon} />
    <div className="errorMessageContainer">
      <span className="errorTitle">Unauthorized Access</span>
      <span
        className="errorDescription"
        dangerouslySetInnerHTML={{
          __html:
            props.message ||
            "You are not authorised to access this page. Please contact the AZBrain support team.",
        }}
      ></span>
    </div>*/}
  </UnauthorisedErrorViewWrapper>
);

export default UnauthorisedErrorView;
