import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Skeleton, Spin, Switch, Table, Tabs } from "antd";
import D3Funnel from "d3-funnel";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";
import SegmentationChart from "../../components/RepApp/SegmentationChart";
import StackedBarChart from "./StackedBarChart";
import StackedHorizontalBarChart from "./StackedHorizontalBarChart";
import TimelineHistory from "../../components/RepApp/TimelineHistory";
import UnauthorisedErrorView from "../../components/UnauthorisedErrorView/UnauthorisedErrorView";
import CONFIG from "../../constants";
import { GetAPIRequest, PostAPIRequest } from "../../services";
import { checkUnauthorised } from "../../utils/cookie-helper";
import {
  CardMessage,
  CardTitle,
  CardViewWrapper,
  CLLPatientsViewWrapper,
  PhysicianViewWrapper,
} from "./PhysicianView.styles";

import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import PhyHeader from "../../components/PhyHeader";
import {
  getIndicationType,
  getIsHcpLevelChartPercent,
  getSelectedGeoTerriroties,
  setIsHcpLevelChartPercent,
  setSelectedGeoFilterName,
  setSelectedGeoFilters,
  setSelectedGeoTerriroties,
} from "../../store/appSlice";
import PerformanceChart from "./PerformanceChart/PerformanceChart";

const { TabPane } = Tabs;
const PhysicianView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hcpInfo, setHcpInfo] = useState({});
  const [prescriberInfo, setPrescriberInfo] = useState({});
  const [isSfDataLoading, setIsSfDataLoading] = useState(true);
  const [caVisible, setCaVisible] = useState(false);
  const [psVisible, setPsVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* width from window */
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [timelineData, setTimelineData] = useState([]);
  const [adoptionStage, setAdoptionStage] = useState(null);
  const [isUnauthorised, setIsUnauthorised] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isHcpChartPercent, setIsHcpChartPercent] = useState(true);
  const [lotData, setLotData] = useState([]);
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  const isHcpLevelChartPercent = useSelector(
    getIsHcpLevelChartPercent
  );
  let { providerId } = useParams();
  var adoptionStages = null;
  var chart = null;
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    if (adoptionStages) {
      loadFunnel(adoptionStages);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    getLotData();
    getAdoptionLadder();
    getPrescriberInfo();
    window.scrollTo(0, 0);
    return () => {
      adoptionStages = null;
      chart = null;
    };
  }, [indicationType, selectedGeoTerriroties, isHcpLevelChartPercent]);
  useEffect(() => {
    var filters = sessionStorage.getItem("filters");
    var isFiltersClearedByUser = sessionStorage.getItem(
      "isFiltersClearedByUser"
    );
    if (
      (filters == null || filters == "") &&
      isFiltersClearedByUser == "false"
    ) {
      GetAPIRequest({
        url: `${CONFIG.api.getTerritories}`,
      })
        .then((response) => {
          if (response) {
            var r = checkUnauthorised(response);
            if (!r.status) {
              //setIsUnauthorised(false);
              var territories = [];
              var filtersArr = [];
              var filtersNameArr = [];
              response.map((res) => {
                if (res && res.funct_territory_id) {
                  filtersArr.push(
                    `${res.area_name} | ${res.district_name} | ${res.funct_territory_id}`
                  );
                  filtersNameArr.push(
                    `${res.area_name} | ${res.district_name} | ${res.territory_name}`
                  );
                  territories.push(res.funct_territory_id);
                }
              });
              dispatch(setSelectedGeoTerriroties(territories));
              dispatch(setSelectedGeoFilters(filtersNameArr));
              dispatch(setSelectedGeoFilterName(filtersArr));
            } else {
              setIsUnauthorised(true);
            }
          }
        })
        .catch((error) => {
          console.log("getTerritories--Error", error);
        });
    }
  }, []);
  const getLotData = () => {
    PostAPIRequest({
      url: `${CONFIG.api.getPrescriberTherapies}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        prescriberId: providerId,
        isPercentage: isHcpLevelChartPercent
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            var _lotInfo = {
              startDt: response.startDt,
              endDt: response.endDt,
              totalPatients: response.totalCllPatients,
            };
            if (response.data) {
              var _data = response.data;
              _lotInfo.data = _data;
              setLotData(_lotInfo);
            }
          } else {
            setIsUnauthorised(true);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const loadFunnel = (_response) => {
    /*const data = [
      { label: "Awareness", value: 500, labelColor: "#000000" },
      { label: "Learn/Evaluate", value: 450, labelColor: "#000000" },
      { label: "Trial", value: 400, labelColor: "#000000" },
      { label: "Convert", value: 350, labelColor: "#000000" },
      { label: "Advocate", value: 300, labelColor: "#000000" },
    ];*/
    var scale = [];
    var resArray = [];
    if (Array.isArray(_response)) {
      for (var i = 0; i < _response.length; i++) {
        var p = {};
        var val = 500 - i * 10;
        if (_response[i].value === true) {
          p.labelColor = "#ffffff";
          scale.push("#584E8D");
        } else {
          scale.push("#ECF5FD");
          p.labelColor = "#000000";
        }
        p.value = val;
        p.status = _response[i].value;
        p.label = _response[i].name;
        resArray.push(p);
      }

      const options = {
        chart: {
          height: 260,
        },
        block: {
          dynamicHeight: false,
          minHeight: 15,
          fill: {
            type: "solid",
            scale: scale,
          },
        },
        label: {
          format: "{l}",
        },
      };
      if (chart) {
        chart.destroy();
      }
      chart = new D3Funnel("#enagagementChart");
      chart.draw(resArray, options);
    }
  };

  const getAdoptionLadder = () => {
    PostAPIRequest({
      url: `${CONFIG.api.getAdoptionLadder}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        prescriberId: providerId,
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            adoptionStages = response;
            setAdoptionStage(response);
            loadFunnel(response);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getPrescriberInfo = () => {
    PostAPIRequest({
      url: `${CONFIG.api.getPrescriberInfo}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        prescriberId: providerId,
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            setPrescriberInfo(response);
          }
        }
        setIsLoading(false);
        setIsSfDataLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsSfDataLoading(false);
      });
  };

  const HcpModal = (props) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text) => <b>{text}</b>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => <span style={{ fontWeight: 500 }}>{text}</span>,
      },
    ];
    const data = [
      {
        key: "1",
        name: "BTKi Defaulters",
        description:
          "CLL treaters who take a more standardized approach to treatment, with high ibrutinib use across current 1L and R/R; slower adopters of new therapies, and prefer treat to progression therapies for HR patients; high ibrutinib usage in AF and hypertensive patients.",
      },
      {
        key: "2",
        name: "BTKi Progressives",
        description:
          "CLL treaters who prefer to use BTKi in front line and reserve Ven for R/R therapy. Believe in the importance of biomarker testing and prefer treat to progression for HR patients.",
      },
      {
        key: "3",
        name: "Treatment Customizers",
        description:
          "High volume CLL treaters who take a customized treatment approach depending on the patient and don’t shy away from any particular class of therapy. Tend to be scientific leaders and early adopters",
      },
      {
        key: "4",
        name: "CIT Traditionalists",
        description:
          "CLL treaters who experience relatively low CLL volume and still rely on CITs for a majority of their CLL patients today.",
      },
      {
        key: "5",
        name: "DR/-",
        description:
          "Unsegmented CLL treater, or CLL treater with data restiction (DR) request.",
      },
    ];
    const pdata = [
      {
        key: "1",
        name: "High",
        description:
          "High volume CLL treater (top 1-4 deciles) among BTKi Progressives, Treatment Customizers or BTKi Defaulters segment; or Medium volume CLL treater (top 5-8 deciles) among BTKi Progressives segment.",
      },
      {
        key: "2",
        name: "Medium",
        description:
          "Medium volume CLL treater (top 5-8 deciles) among Treatment Customizers, BTKi Defaulters or CIT Traditionalists segment; or Low volume CLL treater (top 9-10 deciles) among BTKi Progressives segment.",
      },
      {
        key: "3",
        name: "Low",
        description:
          "Low volume CLL treater (top 9-10 deciles) among Treatment Customizers or BTKi Defaulters segment.",
      },
      {
        key: "4",
        name: "Very Low",
        description:
          "Low volume CLL treater (top 9-10 deciles) among CIT Traditionalists segment.",
      },
      {
        key: "5",
        name: "Opp",
        description:
          "CLL treater not qualifying for High, Medium, Low or Very Low priority groups, but having actionable unmet needs based on Ibru or CIT use.",
      },
      {
        key: "6",
        name: "DR/-",
        description:
          "Non-prioritized CLL treater, or CLL treater with data restiction (DR) request.",
      },
    ];
    const mcl_data = [
      {
        key: "1",
        name: "Patient-Engaged Followers",
        description:
          "MCL treaters who are most concerned about patient compliance and out-of-pocket cost, look for guidance from KOLs and tend to avoid making decisions based on clinical data alone.",
      },
      {
        key: "2",
        name: "Traditionalists",
        description:
          "MCL treaters who lack MCL expertise, have difficulty in selecting later line therapy and refer out patients.",
      },
      {
        key: "3",
        name: "MCL Experts",
        description:
          "MCL treaters who are independent and consider themselves an expert in MCL, relying on clinical data over peer KOLs and seeking continual learning about MCL.",
      },
      {
        key: "4",
        name: "MCL Dabblers",
        description:
          "MCL treaters who have least expertise/interest in MCL, refer out patients, have the greatest difficulty selecting later line therapies, and are not interested in learning more about MCL.",
      },
      {
        key: "5",
        name: "DR/-",
        description:
          "Unsegmented MCL treater, or MCL treater with data restiction (DR) request.",
      },
    ];
    const mcl_pdata = [
      {
        key: "1",
        name: "High",
        description: "High volume MCL treater (top 1-4 deciles).",
      },
      {
        key: "2",
        name: "Medium",
        description: "Medium volume MCL treater (top 5-8 deciles).",
      },
      {
        key: "3",
        name: "Low",
        description: "Low volume MCL treater (top 9-10 deciles).",
      },
      {
        key: "4",
        name: "DR/-",
        description:
          "Non-prioritized CLL treater, or CLL treater with data restiction (DR) request.",
      },
    ];
    return (
      <Modal
        title=""
        centered
        footer={null}
        visible={psVisible}
        onCancel={() => setPsVisible(false)}
        width={"90vw"}
        className="hcpmodal"
        closeIcon={
          <span style={{ color: "#243E5E", fontSize: 35 }}>
            <CloseOutlined />
          </span>
        }
      >
        <div className="popup-title">
          Key attributes of the HCP based on treatment volume (defines the
          Priority), behavioral profile (defines the Segment and the Priority),
          and conversion stage (Engagement Stage is defined by exposure to
          promotional activity, prescribing behavior and/or key customer team
          activity).
        </div>

        <Tabs className="hcptabs" defaultActiveKey="1" centered>
          <TabPane tab="HCP Segmentation" key="1">
            <Table
              showHeader={false}
              pagination={false}
              bordered={true}
              columns={columns}
              dataSource={props.type == "CLL" ? data : mcl_data}
            />
          </TabPane>
          <TabPane tab="HCP Priority" key="2">
            <Table
              showHeader={false}
              pagination={false}
              bordered={true}
              columns={columns}
              dataSource={props.type == "CLL" ? pdata : mcl_pdata}
            />
          </TabPane>
          <TabPane tab="Engagement Stage" key="3">
            <div className="engagement-stage-container">
              <div className="funnel-chart-container">
                <div className="trapezoid" style={{ width: 350 }}>
                  <span>Awareness</span> <div className="guideline"></div>
                </div>
                <div className="trapezoid secondblock" style={{ width: 290 }}>
                  <span>Learn/Evaluate</span>
                  <div
                    className="guideline"
                    style={{ left: 240, width: 45 }}
                  ></div>
                </div>
                <div className="trapezoid" style={{ width: 230 }}>
                  <span>Trial</span>
                  <div
                    className="guideline"
                    style={{ left: 180, width: 80 }}
                  ></div>
                </div>
                <div className="trapezoid" style={{ width: 170 }}>
                  <span>Convert</span>
                  <div
                    className="guideline"
                    style={{ left: 110, width: 125 }}
                  ></div>
                </div>
                <div className="trapezoid" style={{ width: 110 }}>
                  <span>Advocate</span>
                  <div
                    className="guideline"
                    style={{ left: 50, width: 150 }}
                  ></div>
                </div>
              </div>
              <div className="funnel-chart-content-container">
                <div className="funnel-chart-content">
                  HCP is aware of CALQUENCE (has been exposed to and taken
                  action on non personal promotion).
                </div>
                <div className="funnel-chart-content">
                  HCP in interested in CALQUENCE (has recently been exposed to
                  personal promotion and/or engaged more closely on non personal
                  promotion channels).
                </div>
                <div className="funnel-chart-content">
                  HCP has recently used CALQUENCE and been exposed to personal
                  and/or non personal promotion activity.
                </div>
                <div className="funnel-chart-content">
                  HCP has become a repeat user of CALQUENCE and keep being
                  engaged with personal and/or non personal promotion activity.
                </div>
                <div className="funnel-chart-content">
                  HCP has high market share of CALQUENCE and/or has been
                  identified by the Key Customer Team as an Advocate.
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    );
  };
  const CAModal = (props) => {
    return (
      <Modal
        title=""
        centered={true}
        footer={null}
        visible={caVisible}
        onCancel={() => setCaVisible(false)}
        width={"90vw"}
        className="camodal"
        closeIcon={
          <span style={{ color: "#243E5E", fontSize: 35 }}>
            <CloseOutlined />
          </span>
        }
      >
        <>
          <div className="popup-title">
            Promotional channels which work best with the particular HCP, based
            on historical HCP behavioral activity data (shown in the order of
            next best activity sequence).
          </div>
          <div className="popup-button">Channel Affinities</div>
          <table className="pstable">
            <tbody>
              <tr className="pstable-row">
                <td className="pstable-column-title">
                  <span>Personal</span>
                </td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>In person sales representative</li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Personal remote</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>Telesales</li>
                    <li>
                      Live electronic rep interaction (videoconference, web
                      chat)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Peer interaction</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>
                      In person peer programs (speaker programs, lunch & learns,
                      etc.)
                    </li>
                    <li>
                      Remote, interactive peer programs (webinar, satellite
                      webcast, teleconference)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Digital pull</td>
                <td className="pstable-column-description">
                  <ul>
                    <li>Web or mobile microsite</li>
                    <li>Recorded video</li>
                  </ul>
                </td>
                <td className="pstable-column-description">
                  <ul>
                    <li>Self-guided e-details</li>
                    <li>Online advertising</li>
                  </ul>
                </td>
                <td className="pstable-column-description">
                  <ul>
                    <li>
                      Online resource requests (e-samples, e-vouchers, etc.)
                    </li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Digital push</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>Email</li>
                    <li>Mobile or web portal alerts</li>
                  </ul>
                </td>
              </tr>
              <tr className="pstable-row">
                <td className="pstable-column-title">Direct</td>
                <td className="pstable-column-description" colspan="3">
                  <ul>
                    <li>Direct mail</li>
                    <li>Fax</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </Modal>
    );
  };

  return (
    <>
      {isUnauthorised ? (
        <UnauthorisedErrorView message={errorMessage} />
      ) : (
        <>
          <PhysicianViewWrapper>
            <Spin spinning={isLoading}>
              <>
                <PhyHeader prescriberExtInfo={prescriberInfo} />
                <Row>
                  <Col xs={24}>
                    <Row>
                      <Col xs={24} lg={12} className="topLeftCardContainer">
                        <Row>
                          <Col xs={24}>
                            <CardViewWrapper className="topLeftCardWrapper">
                              <Row>
                                <Col xs={24} lg={12}>
                                  <CardTitle>HCP Name</CardTitle>
                                  <CardMessage>
                                    <Skeleton
                                      style={{ margin: 0, padding: 0 }}
                                      size={"small"}
                                      active={prescriberInfo.hcpName == null}
                                      paragraph={{ rows: 0 }}
                                      loading={prescriberInfo.hcpName == null}
                                    >
                                      {prescriberInfo.hcpName}
                                    </Skeleton>
                                  </CardMessage>
                                </Col>
                                <Col xs={24} lg={12}>
                                  <CardTitle>Level of Access</CardTitle>
                                  <CardMessage>
                                    <Skeleton
                                      style={{ margin: 0, padding: 0 }}
                                      size={"small"}
                                      active={isSfDataLoading}
                                      paragraph={{ rows: 0 }}
                                      loading={isSfDataLoading}
                                    >
                                      {prescriberInfo.level_of_access || ""}
                                    </Skeleton>
                                  </CardMessage>
                                </Col>
                                <Col xs={24} lg={12}>
                                  <CardTitle>AZ ID</CardTitle>
                                  <CardMessage>
                                    <Skeleton
                                      style={{ margin: 0, padding: 0 }}
                                      size={"small"}
                                      active={isSfDataLoading}
                                      paragraph={{ rows: 0 }}
                                      loading={isSfDataLoading}
                                    >
                                      {prescriberInfo.azid}
                                    </Skeleton>
                                  </CardMessage>
                                </Col>
                                <Col xs={24} lg={12}>
                                  <CardTitle>Communication Pref</CardTitle>
                                  <CardMessage>
                                    <Skeleton
                                      style={{ margin: 0, padding: 0 }}
                                      size={"small"}
                                      active={isSfDataLoading}
                                      paragraph={{ rows: 0 }}
                                      loading={isSfDataLoading}
                                    >
                                      {prescriberInfo.communication_preference ||
                                        ""}
                                    </Skeleton>
                                  </CardMessage>
                                </Col>
                                <Col xs={24}>
                                  <CardTitle>
                                    Channel affinities{" "}
                                    <InfoCircleOutlined
                                      onClick={() => {
                                        setCaVisible(true);
                                      }}
                                    />
                                  </CardTitle>
                                  <CardMessage>
                                    {prescriberInfo.channelaffinity &&
                                    Array.isArray(
                                      prescriberInfo.channelaffinity
                                    ) &&
                                    prescriberInfo.channelaffinity.length > 0
                                      ? prescriberInfo.channelaffinity.join(
                                          ", "
                                        )
                                      : "-"}
                                  </CardMessage>
                                  <CAModal />
                                </Col>
                                <Col xs={24}>
                                  <div className="message-title">
                                    HCP Priority, Segmentation & Engagement
                                    Stage{" "}
                                    <InfoCircleOutlined
                                      onClick={() => {
                                        setPsVisible(true);
                                      }}
                                    />
                                    <HcpModal
                                      type={
                                        hcpInfo.indication &&
                                        (hcpInfo.indication.includes("MCL") ||
                                          hcpInfo.indication.includes("mcl"))
                                          ? "MCL"
                                          : "CLL"
                                      }
                                    />
                                  </div>
                                  <Row>
                                    <Col xs={24} lg={16}>
                                      <SegmentationChart
                                        providerId={providerId}
                                      />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                      <div
                                        id="enagagementChart"
                                        className="funnel-chart"
                                      ></div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardViewWrapper>
                          </Col>
                          <Col xs={24}>
                            <div className="cardViewWrapper">
                              <div className="timelineContentWrapper">
                                <TimelineHistory
                                  data={timelineData}
                                  providerId={providerId}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} lg={12} className="topRightCardContainer">
                        <CardViewWrapper className="topRightCardWrapper">
                          <Row>
                            <Col xs={24}>
                              <Row>
                                <Col xs={24}>
                                 <div className="title-container-1">
                                  <Switch
                                    checkedChildren="Percentage"
                                    unCheckedChildren="Percentage"
                                    defaultChecked={isHcpChartPercent}
                                    onChange={(checked) => {
                                      dispatch(
                                        setIsHcpLevelChartPercent(checked)
                                      );
                                      setIsHcpChartPercent(checked);
                                    }}
                                  />
                                  </div>
                                </Col>
                                <Col xs={24}>
                                  <CLLPatientsViewWrapper className="cllPatientsViewWrapper">
                                    <Row>
                                      <Col xs={24}>
                                        <StackedHorizontalBarChart
                                          chartId={"hcpLOTChart"}
                                          chartData={lotData}
                                        />
                                      </Col>
                                    </Row>
                                  </CLLPatientsViewWrapper>
                                </Col>
                              </Row>
                            </Col>

                            <Col xs={24}>
                              <StackedBarChart
                                showAccountInfo={true}
                                type="hcp"
                              />
                            </Col>
                            <Col xs={24}>
                              <PerformanceChart />
                            </Col>
                          </Row>
                        </CardViewWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            </Spin>
            <Footer
              style={{
                zIndex: 99999999,
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
              }}
            />
          </PhysicianViewWrapper>
        </>
      )}
    </>
  );
};

PhysicianView.propTypes = {
  // bla: PropTypes.string,
};

PhysicianView.defaultProps = {
  // bla: 'test',
};

export default PhysicianView;
