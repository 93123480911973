import styled from "styled-components";

export const CardTitle = styled.div`
  text-align: left;
  font-size: 14px;
  color: #606060;
  opacity: 0.7;
  width: 100%;
  margin-top: 10px;
`;
export const CardMessage = styled.div`
  text-align: left;
  font-size: 14px;
  color: #000000;
  width: 100%;
`;
export const MessageTitle = styled.div`
  text-align: left;
  font-size: 14px;
  color: #25405f;
  font-weight: 600;
  padding: 5px 0px 0px;
  margin: 0px 0px;
`;

export const AccountAddress = styled.div`
  text-align: left;
  font-size: 12px;
  color: rgb(32, 33, 36);
  font-weight: 400;
  padding: 5px 0px;
  margin: 0px 0px;
`;
export const AccountId = styled.div`
  text-align: left;
  font-size: 12px;
  color: rgb(32, 33, 36);
  font-weight: 400;
  padding: 5px 0px;
  margin: 0px 0px;
`;
