const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const API_BASE_LOGIN_URL = process.env.REACT_APP_BASE_LOGIN_URL;
const API_URL = API_BASE_URL + "/oce/api/v2";
const appDefaults = {
  api: {
    baseURL: API_BASE_URL,
    loginUrl: API_BASE_LOGIN_URL,
    auth: API_BASE_LOGIN_URL + "/identity/ping/login",
    getTriggersCount: API_URL + "/salesrep/triggersCount",
    getTriggersList: API_URL + "/salesrep/triggersListByStatus",
    triggersDetailsByHcpId: API_URL + "/salesrep/triggersDetailsByHcpId",
    hcpDetailsByHcpId: API_URL + "/salesrep/hcpDetailsByHcpId",
    getDrugs: API_URL + "/drugs",
    getCycles: API_URL + "/triggermetric/ta",
    getInsights: API_URL + "/triggermetric/ta",
    dismissedTriggerActions: API_URL + "/salesrep/dismissedTriggerActions",
    completedTriggerActions: API_URL + "/salesrep/completedTriggerActions",
    triggerActionSave: API_URL + "/trigger/save",
    getTriggerDetailsById: API_URL + "/trigger/hcp/uniquetriggerid",
    getTriggerSegmentation: API_URL + "/trigger/segmentation/prescriberId",
    getAdoptionStage: API_URL + "/trigger/adoptionladder/prescriberId",
    getPrescriberDetailsById: API_URL + "/treatments/prescriberid",
    getPrescriberDetailsById1: API_URL + "/prescriber/prescriberId",
    timeLineApi: API_URL + "/timeline/prescriberId/",
    getTerritories: API_URL + "/salesrep/territories",
    getTerritoryInfo: API_URL + "/insights/territory/detail",
    getCallReachData: API_URL + "/insights/national/callreach",
    getNationalPerfData: API_URL + "/insights/national/spsd",
    getPrescriberPerfData: API_URL + "/insights/prescriber/spsd",
    getNationalTherapies: API_URL + "/insights/national/therapies",
    getPrescriberTherapies: API_URL + "/insights/prescriber/therapies",
    getNationalTrx: API_URL + "/insights/national/trx",
    getNationalNps: API_URL + "/insights/national/nps",
    getPrescriberTrx: API_URL + "/insights/prescriber/trx",
    getPrescriberNps: API_URL + "/insights/prescriber/nps",
    getAdoptionLadder: API_URL + "/insights/prescriber/adoptionladder",
    getSegmentation: API_URL + "/insights/prescriber/segmentation",
    getPrescriberInfo: API_URL + "/insights/prescriber/detail",
    getDurationOfTherapy: API_URL + "/insights/dot/duration",
    getCustomerList: API_URL + "/insights/national/customer/list",
    getDropOffs: API_URL + "/insights/dot/dropoffs",
    exportCustomerList: API_URL + "/insights/national/customer/list/export",
    exportCustomerListV2: API_URL + "/insights/national/customer/list/exportv2",
    getParentDetail: API_URL + "/insights/parent/detail",
    getTopParentDetail: API_URL + "/insights/topparent/detail",
    getParentTherapies: API_URL + "/insights/parent/therapies",
    getTopParentTherapies: API_URL + "/insights/topparent/therapies",
    getParentTrx: API_URL + "/insights/parent/trx",
    getParentNps: API_URL + "/insights/parent/nps",
    getParentPerformance: API_URL + "/insights/parent/spsd",
    getTopParentTrx: API_URL + "/insights/topparent/trx",
    getTopParentNps: API_URL + "/insights/topparent/nps",
    getTopParentPerformance: API_URL + "/insights/topparent/spsd",
    getParentCustomerList: API_URL + "/insights/parent/customer/list",
    getTopParentCustomerList: API_URL + "/insights/topparent/customer/list",
    getParentSegmentation: API_URL + "/insights/parent/segmentation",
    getTopParentSegmentation: API_URL + "/insights/topparent/segmentation",
    getTopParentPriority: API_URL + "/insights/topparent/priority/parent",
    getUserTerritories: API_URL + "/users/territory/detail",
    getUserRoles: API_URL + "/users/roles",
    getUserList: API_URL + "/users/list",
    saveUser: API_URL + "/users/save",
    deleteUser: API_URL + "/users/prid_id",
    getUserDetails: API_URL + "/users/prid_id",
    getEmailList: API_URL + "/users/search",
    getPriorities: API_URL + "/insights/customers/type",
    getAccountBySearch: API_URL + "/insights/customers/type",
    getAccountBySearchV2: API_URL + "/insights/customers/search",
    getNumberComparer: API_URL + "/insights/customers/numbercomparer",
    getFilteredCustomerList: API_URL + "/insights/customers/filteredCustomerList"
  },
};
export default appDefaults;

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ITEM_CLASS = {
  animation: ["gif", "swf"],
  audio: ["mp1", "mp2", "mp3", "wav"],
  images: ["bmp", "jpeg", "jpg", "png"],
  mixed: ["docx", "doc", "pdf", "rtf", "ppt", "pptx", "csv", "txt"],
  video: ["3gp", "3g2", "mp4", "mpe", "mpeg"],
};
