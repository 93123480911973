import styled from "styled-components";

export const TimelineTriggerTypeWrapper = styled.div`
  width: 100%;
  .timeLineTriggerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .timeLineTriggerWrapperNpp {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .circle {
    border-radius: 100px;
    position: relative;
  }

  .contentBox {
    padding: 10px 5px 5px;
    font-size: 1em;
    overflow: hidden;
    text-align: right;
    line-height: normal !important;
  }
  /*
.timeLineTriggerWrapperH {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.timeLineTriggerWrapperNppH {
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.circleH {
    border-radius: 100px;
    position: relative;
}

.contentBoxH {
    padding: 0px 10px 8px;
    font-size: 12px;
    writing-mode: vertical-rl;
    text-orientation: upright;
}*/
`;
