import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getIndicationType, getIsHcpLevelChartPercent } from "../../../store/appSlice";
import { numberWithCommas } from "../../../utils/utils";
//import wwinfo from "../../../data/wwdata";
import { HBarLegend, LegendItem } from "./StackedHorizontalBarChart.styles";
var stacked_hbar_chart = null;
const StackedHorizontalBarChart = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [cllPatientsData, setCLLPatientsData] = useState([]);
  const [duration, setDuration] = useState("");
  const [msTitle, setMsTitle] = useState("Total Patients");
  const [patientCount, setPatientCount] = useState(" ");
  const indicationType = useSelector(getIndicationType);
  const isHcpLevelChartPercent = useSelector(getIsHcpLevelChartPercent);
  useEffect(() => {
    return () => {
      stacked_hbar_chart.dispose();
    };
  }, []);

  useEffect(() => {
    var it = indicationType;
    if (it == "ALL") {
      setMsTitle("Total Patients");
    } else if (it == "CLL") {
      setMsTitle("Total CLL Patients");
    } else if (it == "MCL") {
      setMsTitle("Total MCL Patients");
    }
  }, [indicationType]);
  const CHART_ID = props.chartId || "horizontalBarChart";
  useEffect(() => {
    var _data = props.chartData;
    if (_data && _data.data) {
      var duartion = "";
      if (_data.startDt) {
        duartion += _data.startDt;
      }
      duartion += " - ";
      if (_data.endDt) {
        duartion += _data.endDt;
      }
      setDuration(duartion);
      loadSBChart(_data.data);
      if (_data.totalPatients) {
        setPatientCount(numberWithCommas(_data.totalPatients));
      } else {
        setPatientCount(0);
      }
    }
  }, [props.chartData]);

  const loadSBChart = async (data) => {
    am4core.useTheme(am4themes_animated);
    if (stacked_hbar_chart) {
      stacked_hbar_chart.dispose();
    }
    stacked_hbar_chart = am4core.create(CHART_ID, am4charts.XYChart);
    var cdata = {
      year: "2016",
    };
    var d1 = {};
    data.map((d) => {
      d1[d.name] = d.value;
    });
    stacked_hbar_chart.data = [
      {
        year: "2016",
        WW: d1["WW"],
        "1L": d1["1L"],
        "2L": d1["2L"],
      },
    ];

    stacked_hbar_chart.colors.list = [
      am4core.color("#584D8D"),
      am4core.color("#EB5F5E"),
      am4core.color("#4EBCB3"),
    ];
    // Create axes
    var categoryAxis = stacked_hbar_chart.yAxes.push(
      new am4charts.CategoryAxis()
    );
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;

    var valueAxis = stacked_hbar_chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.minGridDistance = 40;
    valueAxis.renderer.labels.template.disabled = true;

    // Create series
    function createSeries(field, name) {
      var series = stacked_hbar_chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.stacked = true;
      series.name = name;
      series.showOnInit = false;
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = isHcpLevelChartPercent ? "{valueX}%" : "{valueX}";
      labelBullet.label.fill = am4core.color("#fff");
      labelBullet.label.adapter.add("textOutput", function (text, target) {
        if (target.dataItem && target.dataItem.valueX == 0) return "";
        return text;
      });
      series.columns.template.events.on("sizechanged", function (ev) {
        if (ev.target.dataItem && ev.target.dataItem.bullets) {
          var height = ev.target.pixelWidth;
          ev.target.dataItem.bullets.each(function (id, bullet) {
            if (height > 20) {
              bullet.show();
            } else {
              bullet.hide();
            }
          });
        }
      });
    }
    createSeries("WW", "WW");
    createSeries("1L", "1L");
    createSeries("2L", "2L");

    setLoading(false);
  };
  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col xs={24} lg={12}>
          <div className="message-title">{msTitle + " - " + patientCount}</div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="message-date">{duration || ""}</div>
        </Col>
        <Col xs={24} lg={16}>
          <div
            id={CHART_ID}
            style={{
              width: "100%",
              height: "80px",
              marginTop: "-20px",
              marginLeft: "-10px",
            }}
          ></div>
        </Col>
        <Col xs={24} lg={8}>
          <HBarLegend>
            <LegendItem>
              <span
                className="legend-bullet"
                style={{ background: "#584D8D" }}
              ></span>
              <span className="legend-label">WW</span>
            </LegendItem>
            <LegendItem>
              <span
                style={{ background: "#EB5F5E" }}
                className="legend-bullet"
              ></span>
              <span className="legend-label">1L</span>
            </LegendItem>
            <LegendItem>
              <span
                style={{ background: "#4EBCB3" }}
                className="legend-bullet"
              ></span>
              <span className="legend-label">2L+</span>
            </LegendItem>
          </HBarLegend>
        </Col>
      </Row>
    </Spin>
  );
};

export default StackedHorizontalBarChart;
