import { FileExcelOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Popover,
  Radio,
  Row,
  Spin,
  Switch,
  Table,
  Tabs
} from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";
import TitleSearch from "../../../components/TitleSearch/TitleSearch";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import { GetFileAPIRequest } from "../../../services/api";
import {
  getCurrCustomerType,
  getIndicationType,
  getSelectedGeoTerriroties,
  setCurrCustomerType
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import {
  CardViewWrapper,
  CustomerListViewWrapper,
  TableButtonWrapper
} from "./CustomerListView.styles";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
var offset = 0;
var totalCount = 0;
var loadedRecords = 0;
var limit = 100;
var isLimitReached = false;
var currTblData = [];
const CustomerListView = (props) => {
  var datesStr = sessionStorage.getItem("customerTblDateRange");
  var datesStrArr = [];
  if (datesStr) {
    datesStrArr = datesStr.split(",");
  }
  if (datesStrArr && datesStrArr.length <= 0) {
    datesStrArr = ["2022-01-01", "2022-06-01"];
  }
  const [dateRange, setDateRange] = useState([
    moment(datesStrArr[0]).isValid() ? moment(datesStrArr[0], dateFormat) : "",
    moment(datesStrArr[1]).isValid() ? moment(datesStrArr[1], dateFormat) : "",
  ]);
  const [dateRangeStr, setDateRangeStr] = useState([
    datesStrArr[0],
    datesStrArr[1],
  ]);
  const [hcpTblInfo, setHcpTblInfo] = useState();
  const [currentTblColumns, setCurrentTblColumns] = useState([]);
  const [currentTblExportColumns, setCurrentTblExportColumns] = useState([]);
  const [currentTblData, setCurrentTblData] = useState([]);
  const dispatch = useDispatch();
  const [customerType, setCustomerType] = useState("HCP");
  const [searchKey, setSearchKey] = useState("");
  const [isListLoading, setIsListLoading] = useState(true);
  const [isCustomerTblPercent, setIsCustomerTblPercent] = useState(true);
  const navigate = useNavigate();
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);

  //const [limit, setLimit] = useState(50);
  //const [offSet, setOffset] = useState(0);
  //const [totalCount, setTotalCount] = useState(0);
  // const [loadedRecords, setLoadedRecords] = useState(0);
  const [sort, setSort] = useState({
    key: "",
    order: "",
  });

  useEffect(() => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    const handleScroll = () => {
      const perc =
        (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;
      if (perc >= 100) {
        if (!isLimitReached) {
          isLimitReached = true;
          offset = offset + limit;
          loadData();
        }
        //setOffset(offSet => offSet + 50);

        console.log(
          "TODO: Scrolling has reached bottom, load more data, or do whatever...",
          offset
        );
      }
    };
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }
    return () => {
      node.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    offset = 0;
    totalCount = 0;
    loadedRecords = 0;
    setSearchKey("");
    setCurrentTblData([]);
    if (customerType == "HCP") {
      setCurrentTblColumns(hcpTblColumns);
      setCurrentTblExportColumns(hcpTblExportColumns);
    } else if (customerType == "Parent") {
      setCurrentTblColumns(accountTblColumns);
      setCurrentTblExportColumns(accountTblExportColumns);
    } else if (customerType == "TopParent") {
      setCurrentTblColumns(parentAccountTblColumns);
      setCurrentTblExportColumns(parentAccountTblExportColumns);
    }
    offset = 0;
    totalCount = 0;
    loadedRecords = 0;
    setSearchKey("");
    setCurrentTblData([]);
    getCustomerList1();
  }, [
    indicationType,
    selectedGeoTerriroties,
    isCustomerTblPercent,
    dateRange,
    customerType,
  ]);

  const loadData = () => {
    if (loadedRecords <= totalCount && searchKey == "") {
      console.log("currTblData", currTblData);
      getCustomerList();
    } else if (loadedRecords <= totalCount) {
      searchCustomerList(searchKey);
    }
  };

  const handleSearch = (searchText) => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    if (node) {
      node.scrollTo(0, 0);
    }
    totalCount = 0;
    offset = 0;
    loadedRecords = 0;
    setCurrentTblData([]);
    searchCustomerList(searchText);
  };
  const getCustomerList1 = () => {
    setIsListLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getCustomerList}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        isPercentage: isCustomerTblPercent,
        accountType: customerType,
        fromDt: dateRangeStr[0],
        toDt: dateRangeStr[1],
        limit: limit,
        offSet: offset,
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            setHcpTblInfo(response.data);
            loadedRecords = loadedRecords + limit;
            //setCurrentTblData(response.data);
            setCurrentTblData(response.data);
            currTblData = [...response.data];
            totalCount = response.totalCount;
            if (customerType == "HCP") {
              setCurrentTblColumns(hcpTblColumns);
              setCurrentTblExportColumns(hcpTblExportColumns);
            } else if (customerType == "Parent") {
              setCurrentTblColumns(accountTblColumns);
              setCurrentTblExportColumns(accountTblExportColumns);
            } else if (customerType == "TopParent") {
              setCurrentTblColumns(parentAccountTblColumns);
              setCurrentTblExportColumns(parentAccountTblExportColumns);
            }
          }
        }
        isLimitReached = false;
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        isLimitReached = false;
        setIsListLoading(false);
      });
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const getCustomerList = () => {
    console.log("getCustomerList");
    var data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isCustomerTblPercent,
      accountType: customerType,
      fromDt: dateRangeStr[0],
      toDt: dateRangeStr[1],
      limit: limit,
      offSet: offset,
    };
    if (sort.key != "") {
      data.sort = sort;
    }

    setIsListLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            loadedRecords = loadedRecords + limit;
            currTblData.push(...response.data);
            console.log("currTblData-", currTblData);
            totalCount = response.totalCount;
            setCurrentTblData([]);
            setCurrentTblData(currTblData);
          }
        }
        isLimitReached = false;
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        isLimitReached = false;
        setIsListLoading(false);
      });
  };
  const searchCustomerList = (searchKey) => {
    setIsListLoading(true);
    var data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isCustomerTblPercent,
      accountType: customerType,
      fromDt: dateRangeStr[0],
      toDt: dateRangeStr[1],
      q: searchKey,
      limit: limit,
      offSet: offset,
    };
    if (sort.key != "") {
      data.sort = sort;
    }
    PostAPIRequest({
      //url: `${CONFIG.api.getCustomerList}?searchText=${searchKey}`,
      url: `${CONFIG.api.getCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            currTblData = [...response.data];
            setCurrentTblData(response.data);
            totalCount = response.totalCount;
            loadedRecords = loadedRecords + limit;
          }
        }
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
    /*PostAPIRequest({
      url: `${CONFIG.api.getPrescriberDetailsById1}/${providerId}/uniqueTriggerId/${triggerid}/internal`,
    }).then((response) => {
      if (response) {
        var r = checkUnauthorised(response);
        if (!r.status) {
          setPrescriberInfo(response);
          setPriority(response.priority);
          setSegmentation(response.segmentation);
          loadFunnel(response);
          if (response.dueDate) {
            setTriggerDate(response.dueDate);
          }
        }
      }
    });
  */
  };
  const exportCustomerList = () => {
    setIsListLoading(true);
    var _cctype = customerType; //sessionStorage.getItem("currCustomerType");
    var selectedTerritories = sessionStorage.getItem("territories") || "";
    GetFileAPIRequest({
      url: `${CONFIG.api.exportCustomerList}?accountType=${_cctype}&fromDt=${dateRangeStr[0]}&toDt=${dateRangeStr[1]}&tumorType=${indicationType}&q=${searchKey}&territories=${selectedTerritories}&isPercentage=${isCustomerTblPercent}`,
    })
      .then((response) => {
        console.log(response.headers);
        setIsListLoading(false);
        if (response.data) {
          var r = checkUnauthorised(response.data);
          if (!r.status) {
            // const [, filename] = response.headers['content-disposition'].split('filename=');

            const temp = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = temp;
            link.setAttribute("download", `${customerType}.csv`); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
  };
  const sortCustomerList = (type, key, isSort) => {
    setIsListLoading(true);
    PostAPIRequest({
      // url: `${CONFIG.api.getCustomerList}?searchText=${searchKey}`,
      url: `${CONFIG.api.getCustomerList}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        isPercentage: isCustomerTblPercent,
        accountType: customerType,
        fromDt: dateRangeStr[0],
        toDt: dateRangeStr[1],
        q: searchKey,
        limit: limit,
        offSet: offset,
        sort: {
          key: key,
          order: type,
        },
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);

            if (isSort) {
              currTblData = [...response.data];
              setCurrentTblData(response.data);
            } else {
              currTblData.push(...response.data);
              console.log("currTblData-", currTblData);
              //setCurrentTblData([...currentTblData, ...response.data]);
              setCurrentTblData([]);
              setCurrentTblData(currTblData);
            }
            totalCount = response.totalCount;
            loadedRecords = loadedRecords + limit;
          }
        }
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
  };
  const goToDetailView = (id) => {
    var _cctype = customerType; //sessionStorage.getItem("currCustomerType");
    if (_cctype == "HCP") {
      navigate(`/physician/${id}`);
    } else if (_cctype == "TopParent") {
      navigate(`/parent-account/${id}`);
    } else if (_cctype == "Parent") {
      navigate(`/account/${id}`);
    }
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    if (node) {
      node.scrollTo(0, 0);
    }
    totalCount = 0;
    offset = 0;
    loadedRecords = 0;
    //setCurrentTblData([]);

    if (sorter.order) {
      let ordertype =
        sorter.order == "ascend"
          ? "asc"
          : sorter.order == "descend"
          ? "desc"
          : "";
      setSort({
        key: sorter.field,
        order: ordertype,
      });
      sortCustomerList(ordertype, sorter.field, true);
    } else {
      getCustomerList();
    }
  };

  const hcpTblColumns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 100,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // sorter: (a, b) => a.id - b.id,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //sorter: (a, b) => a.name.length - b.name.length,
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      key: "cllPriority",
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: "mclPriority",
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: "evusheldPriority",
      title: "Evusheld Priority",
      dataIndex: "evusheldPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.evusheldPriority.length - b.evusheldPriority.length,
    },
    {
      key: "inFocusAccount",
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      key: "spUnits",
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: "spNps",
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      key: "calNps",
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNps",
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      key: "bruNps",
      title: "Zanu\nNPS",
      dataIndex: "bruNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      key: "ibrNps",
      title: "Ibru\nNPS",
      dataIndex: "ibrNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    {
      key: "venNps",
      title: "Ven\nNPS",
      dataIndex: "venNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      key: "citNps",
      title: "CIT\nNPS",
      dataIndex: "citNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      key: "reached",
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.reached - b.reached,
    },
    {
      title: "Click for details",
      key: "operation",
      width: 100,
      fixed: "right",
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];
  const hcpTblExportColumns = [
    {
      title: "HCP ID",
      dataIndex: "hcpId",
      width: 120,
      sorter: true,
      // sorter: (a, b) => a.hcpId - b.hcpId,
    },
    {
      title: "HCP Name",
      dataIndex: "hcpName",
      width: 120,
      sorter: true,
      // sorter: (a, b) => a.hcpName.length - b.hcpName.length,
    },
    {
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 120,
      sorter: true,
      //sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 120,
      sorter: true,
      //sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      title: "Evusheld Priority",
      dataIndex: "evusheldPriority",
      width: 120,
      sorter: true,
      //sorter: (a, b) => a.evusheldPriority.length - b.evusheldPriority.length,
    },
    {
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 120,
      sorter: true,
      // sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      title: "SP Units",
      dataIndex: "spUnits",
      sorter: true,
      // sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      title: "SP NPS",
      dataIndex: "spNps",
      sorter: true,
      //  sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      title: "Cal NPS",
      dataIndex: "calNps",
      sorter: true,
      // sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      title: "Zanu NPS",
      dataIndex: "bruNps",
      sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      title: "Ibru NPS",
      dataIndex: "ibrNps",
      sorter: true,
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    // {
    //   title: "BTKi NPS",
    //   dataIndex: "btkiNps",
    //   sorter: (a, b) => a.btkiNps - b.btkiNps,
    // },
    {
      title: "Ven NPS",
      dataIndex: "venNps",
      sorter: true,
      // sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      title: "CIT NPS",
      dataIndex: "citNps",
      sorter: true,
      //sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      title: "Reached?",
      dataIndex: "reached",
      width: 130,
      sorter: true,
      //sorter: (a, b) => a.reached - b.reached,
    },
  ];

  const accountTblColumns = [
    {
      key: 0,
      title: "ID",
      dataIndex: "id",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //sorter: (a, b) => a.id - b.id,
    },
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //sorter: (a, b) => a.name.length - b.name.length,
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      key: 2,
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: 3,
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      ///   sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: 5,
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      key: 4,
      title: "SD\nUnits",
      dataIndex: "sdUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.sdUnits - b.sdUnits,
    },
    {
      key: 6,
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 7,
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      key: 8,
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNps",
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      key: 9,
      title: "Zanu\nNPS",
      dataIndex: "bruNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      key: 10,
      title: "Ibru\nNPS",
      dataIndex: "ibrNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    {
      key: 12,
      title: "Ven\nNPS",
      dataIndex: "venNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      key: 13,
      title: "CIT\nNPS",
      dataIndex: "citNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      key: 14,
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.reached - b.reached,
    },
    {
      key: 15,
      title: "Click for details",
      fixed: "right",
      key: "operation",
      width: 100,
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];
  const accountTblExportColumns = [
    {
      title: "Account ID",
      dataIndex: "account_id",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
    },
    {
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
    },
    {
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
    },
    {
      title: "SD Units",
      dataIndex: "sdUnits",
    },
    {
      title: "SP Units",
      dataIndex: "spUnits",
    },
    {
      title: "SP NPS",
      dataIndex: "spNps",
    },
    {
      title: "Cal NPS",
      dataIndex: "calNps",
    },
    {
      title: "Zanu NPS",
      dataIndex: "bruNps",
    },
    {
      title: "Ibru NPS",
      dataIndex: "ibrNps",
    },
    {
      title: "Ven NPS",
      dataIndex: "venNps",
    },
    {
      title: "CIT NPS",
      dataIndex: "citNps",
    },
    {
      title: "Reached?",
      dataIndex: "reached",
    },
  ];

  const parentAccountTblColumns = [
    {
      key: 0,
      title: " ID",
      dataIndex: "id",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
      width: 120,
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: 4,
      title: "SD\nUnits",
      dataIndex: "sdUnits",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.sdUnits - b.sdUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 6,
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.spUnits - b.spUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 7,
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      //sorter: (a, b) => a.spNps - b.spNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 8,
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNps",
      sorter: true,
      //sorter: (a, b) => a.calNps - b.calNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 9,
      title: "Zanu\nNPS",
      dataIndex: "bruNps",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.bruNps - b.bruNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 10,
      title: "Ibru\nNPS",
      dataIndex: "ibrNps",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 12,
      title: "Ven\nNPS",
      dataIndex: "venNps",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.venNps - b.venNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 13,
      title: "CIT\nNPS",
      dataIndex: "citNps",
      width: 60,
      sorter: true,
      //sorter: (a, b) => a.citNps - b.citNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 14,
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      //sorter: (a, b) => a.reached.length - b.reached.length,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 15,
      title: "Click for details",
      fixed: "right",
      key: "operation",
      width: 100,
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];
  const parentAccountTblExportColumns = [
    {
      title: "Account ID",
      dataIndex: "account_id",
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
    },
    {
      title: "SD Units",
      dataIndex: "sdUnits",
    },
    {
      title: "SP Units",
      dataIndex: "spUnits",
    },
    {
      title: "SP NPS",
      dataIndex: "spNps",
    },
    {
      title: "Cal NPS",
      dataIndex: "calNps",
    },
    {
      title: "Zanu NPS",
      dataIndex: "bruNps",
    },
    {
      title: "Ibru NPS",
      dataIndex: "ibrNps",
    },
    {
      title: "Ven NPS",
      dataIndex: "venNps",
    },
    {
      title: "CIT NPS",
      dataIndex: "citNps",
    },
    {
      title: "Reached?",
      dataIndex: "reached",
    },
  ];

  return (
    <CustomerListViewWrapper>
      <CardViewWrapper>
        <Spin spinning={isListLoading}>
          <Row>
            <Col xs={12}>
              <div className="timelineHeader">
                <Popover
                  placement="right"
                  content={
                    <div style={{ maxWidth: 500 }}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `<h3>HCPs, Accounts & Parent Accounts</h3><ul><li>HCPs - Claims and SP data at HCP level</li><li>Accounts - SD data at Account Level</li><li>Parent Accounts - Contracting focus / Storytelling</li><li>SP (Specialty Pharmacy) and SD (Specialty Distributor) data from Matrix.</li></ul>`,
                        }}
                      ></p>
                    </div>
                  }
                  title={""}
                  trigger={"click"}
                >
                  Customer List <InfoCircleOutlined />
                </Popover>
              </div>
            </Col>
            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Switch
                  checkedChildren="Percentage"
                  unCheckedChildren="Percentage"
                  defaultChecked={isCustomerTblPercent}
                  onChange={(checked) => {
                    setIsCustomerTblPercent(checked);
                  }}
                />
              </div>
            </Col>
            <Col xs={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: 5,
                  marginTop: 15,
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginLeft: 0, marginBottom: 10 }}>
                  <Radio.Group
                    value={customerType}
                    onChange={(e) => {
                      setCurrentTblData([]);
                      dispatch(setCurrCustomerType(e.target.value));
                      setCustomerType(e.target.value);
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="HCP">HCPs</Radio.Button>
                    <Radio.Button value="Parent">Accounts</Radio.Button>
                    <Radio.Button value="TopParent">
                      Parent Accounts
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  <RangePicker
                    allowEmpty={[false, false]}
                    allowClear={false}
                    inputReadOnly={true}
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    picker="week"
                    value={dateRange}
                    onChange={(dates, datesStr) => {
                      console.log(datesStr);
                      sessionStorage.setItem("customerTblDateRange", datesStr);
                      setDateRange(dates);
                      setDateRangeStr(datesStr);
                    }}
                    format={dateFormat}
                  />
                </div>
                <TitleSearch
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  onSearch={handleSearch}
                />
                <Button
                  style={{
                    marginLeft: 10,
                    marginBottom: 10,
                    backgroundColor: "#294360",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    exportCustomerList();
                    /*const excel = new Excel();
                    excel.setRowHeight(20);
                    excel.setTHeadStyle({
                      background: "FFB4C6E7",
                      color: "FF000000",
                      "text-align": "center",
                    });

                    excel
                      .addSheet("HCPs")
                      .addColumns(currentTblExportColumns)
                      .addDataSource(currentTblData)
                      .saveAs("HCPs.xlsx");*/
                  }}
                >
                  Export
                  <FileExcelOutlined
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </Button>
              </div>
            </Col>
            <Col xs={24}>
              <Table
                className="custhcptbl"
                showSorterTooltip={false}
                columns={currentTblColumns}
                dataSource={currentTblData}
                pagination={false}
                scroll={{
                  y: window.innerHeight - 220,
                  x: window.innerWidth - 40,
                  scrollToFirstRowOnChange: false,
                }}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </CardViewWrapper>
    </CustomerListViewWrapper>
  );
};

export default CustomerListView;
