import styled from "styled-components";

export const PhysicianViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 100px 0px 60px;
  background: #ffffff;
  overflow-y: scroll;
  .title-container-1 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: -5px;
    .ant-switch {
      width: 120px;
    }
  }
  .ant-table-column-title,
  th.ant-table-cell,
  .ant-table-column-sorters:hover .ant-table-column-sorter {
  }
  .ant-segmented {
    background-color: #eeeeee;
    color: #000000;
  }
  .ant-table-thead {
    th {
      border-bottom: 2px solid #dedede;
      overflow-wrap: normal;
      text-align: center;
      padding: 5px 0px;
      color: #ffffff;
    }
  }
  .ant-table {
    font-size: 10pt;
    tr:nth-child(even) {
      background-color: #f9f9f9 !important;
    }
    tr > td {
      padding: 5px 14px;
      overflow-wrap: normal;
      text-align: center;
    }
  }
  .ant-skeleton-content .ant-skeleton-title {
    margin-top: 5px;
  }
  .ant-skeleton-paragraph {
    margin-top: 0px !important;
  }
  .cardViewWrapper {
    background-color: #ffffff;
    min-height: 100px;
    box-shadow: 0px 0px 20px #25405f2a;
    padding: 10px;
    border-radius: 15px;
    margin: 10px;
  }
  .timelineContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .message-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .ant-radio-button-wrapper {
    padding: 5px 35px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .ant-radio-button-wrapper-checked {
    color: #fff;
    background: #294360 !important;
    border-color: #294360 !important;
  }
  .trigger-title-back-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0px;
    color: #666666;
    font-size: 20px;
    cursor: pointer;
  }
  .trigger-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10px;
  }
  .trigger-title {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: #234060;
    padding-bottom: 5px;
  }
  .trigger-date {
    text-align: left;
    font-size: 14px;
    color: #234060;
  }
  .ant-divider-horizontal {
    margin: 5px 0px;
  }
  .message-title {
    text-align: left;
    font-size: 14px;
    color: #25405f;
    font-weight: 600;
    padding: 5px 0px;
    margin: 0px 0px;
  }
  .message-date {
    text-align: right;
    font-size: 14px;
    color: #234060;
    font-weight: 400;
    padding: 5px 0px;
    margin: 0px 0px 10px;
  }
  .topCardWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
  .topLeftCardWrapper {
    flex: 1;
    padding: 15px 10px 20px;
  }
  .topRightCardWrapper {
    align-self: stretch;
  }
  .funnel-chart {
    width: 100%;
    height: 260px;
    margin-top: 15px;
  }

  .dismiss-btn {
    height: 40px;
    background: #254060 !important;
    border-color: #233e5c;
    color: #ffffff !important;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    /*box-shadow: 2px 3px 3px #00000029;*/
  }
  .dismiss-btn:hover {
    background: #254060 !important;
    color: #ffffff !important;
  }
  .completed-btn {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    background: #254060 !important;
    border-color: #233e5c;
    color: #ffffff;
    border-radius: 5px;
  }
  .completed-btn:hover {
    color: #ffffff !important;
    background: #254060;
    border-color: #233e5c;
  }
  .outline-btn {
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    background: transparent !important;
    border-color: #233e5c;
    color: #233e5c;
    border-radius: 5px;
  }

  .outline-btn:hover {
    color: #233e5c !important;
    background: transparent;
    border-color: #233e5c;
  }
  .timelineHeader {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    padding: 10px 0px 0px;
  }
`;
export const CardTitle = styled.div`
  text-align: left;
  font-size: 14px;
  color: #606060;
  opacity: 0.7;
  width: 100%;
  margin-top: 10px;
`;

export const CardMessage = styled.div`
  text-align: left;
  font-size: 14px;
  color: #202124;
  width: 100%;
`;
export const CardTags = styled.div`
  width: 100%;
  margin-left: -5px;
  .ant-tag-blue {
    color: #3a3a3f;
    background: #ebf5fd;
    border-color: #93b1d1;
    border-radius: 15px;
    padding: 5px 10px;
    margin: 5px;
  }
`;
export const CllHeaderWrapper = styled.div`
  background-color: red;
  width: 100%;
  height: 60px;
  text-align: center;
  padding: 15px;
`;
export const CardViewWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100px;
  box-shadow: 0px 0px 20px #25405f2a;
  padding: 10px 10px 0px;
  border-radius: 15px;
  margin: 10px;
`;
export const CardRecommendedMessage = styled.div`
  padding: 10px;
  .message-title {
    text-align: left;
    font-size: 14px;
    color: #234060;
    font-weight: 600;
    padding: 5px 5px;
    margin: 10px 15px;
  }
  .icon-ctn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #e4eff5;
    box-shadow: 3px 3px 5px #00000029;
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
    margin: 10px 15px;
    min-height: 50px;
  }
  .icon-ctn span {
    padding: 0px 5px;
  }
  .tag-topic-view {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: -10px;

    .tag-topic {
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      overflow: hidden;
      margin-right: -25px;
      .tag-topic-left {
        width: 0;
        height: 0;
        margin-right: -75px;
        border-top: 65px solid transparent;
        border-right: 100px solid #47761e;
        border-bottom: 65px solid transparent;
      }
      .tag-topic-right {
        background-color: #30481c;
        width: 5px;
        height: 30px;
      }
      .tag-topic-middle {
        flex: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #47761e;
        color: #ffffff;
        font-size: 14px;
        padding: 0px 10px 0px 10px;
        height: 30px;
        font-weight: 500;
      }
    }
  }
`;

export const CLLPatientsViewWrapper = styled.div`
  margin-top: 10px;
`;
