import styled from "styled-components";

export const CustomerListViewWrapper = styled.div`
  .title-view {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    padding: 0px;
    display: flex;
    width: 100%;
    margin: 0px 0px 5px;
    align-items: center;
    justify-content: space-between;
    .ant-radio-button-wrapper {
      white-space: nowrap;
    }
  }
  .datePicker{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ant-picker-range {
    padding: 4px;
  }
  .ant-picker-range-separator {
    padding: 0px;
  }
  .ant-picker-input > input {
    max-width: 85px;
  }
  .ant-picker-range .ant-picker-active-bar {
    margin-left: 0px;
  }
  .ant-table-column-title,
  th.ant-table-cell,
  .ant-table-column-sorters:hover .ant-table-column-sorter {
  }

  .ant-table-thead {
    th {
      border-bottom: 2px solid #dedede;
      overflow-wrap: normal;
      text-align: center;
      padding: 5px 0px;
    }
  }
  .ant-table {
    height: 605px;
    font-size: 0.9em;
    tr:nth-child(even) {
      background-color: #ffffff !important;
    }
    tr > td {
      padding: 5px 10px;
      overflow-wrap: normal;
      text-align: center;
    }
  }
  .ant-skeleton-content .ant-skeleton-title {
    margin-top: 5px;
  }
  .ant-skeleton-paragraph {
    margin-top: 0px !important;
  }
`;
export const CardViewWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100px;
  box-shadow: 0px 0px 20px #25405f2a;
  border-radius: 15px;
  margin: 10px;
  flex: 1;
  padding: 15px 10px 15px;
`;

export const TableButtonWrapper = styled.button`
  background-color: #204b73;
  /* background: #3BA2D6; */
  width: 100%;
  border-radius: 5px;
  border: none;
  text-align: center;
  color: #ffffff;
  border-color: #2c6c8f;
  cursor: pointer;
  padding: 1px 1px !important;
`;
