import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .header {
    background-color: #ffffff; /*#2E547A;*/
    border-bottom: 1px solid #0000000a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #000000;
    padding: 10px 10px !important;
  }
  .ant-select-selector {
    background-color: #eeeeee;
    border: 0px solid #d9d9d9;
    border-radius: 0px;
    font-weight: bold;
    color: #e44404;
    outline: none;
  }
  .ant-select-open {
    outline: none;
    border: 0px solid #d9d9d9 !important;
  }
  .ant-select-selection-item {
    font-weight: bold;
    color: #e44404;
    border: 0px solid #d9d9d9 !important;
  }
  .ant-select-selector {
    background-color: #eeeeee !important;
    border: 0px solid #d9d9d9 !important;
    border-radius: 0px;
    color: #e44404;
    font-weight: bold;
    outline: none;
    &:focus {
      border: 0px solid #d9d9d9 !important;
      border-radius: 0px;
    }
    &:active {
      border: 0px solid #d9d9d9 !important;
      border-radius: 0px;
    }
  }

  .sub-menu-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    background-color: #eeeeee;
    border-bottom: 1px solid #dedede;
  }
  .subMenu {
    padding: 0px 0px;
    color: #2e547a;
    flex: 1;
    font-weight: bold;
  }
  .subMenuProduct {
    padding: 0px 0px;
    color: #e44404;
  }
  .chooseBtn {
    color: #ffffff;
    font-weight: bold;
    margin: 0px 10px;
    background-color: #2e547a;
    padding: 5px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 2px 3px #00000022;
    cursor: pointer;
  }
  .ant-segmented-item {
    color: #666666;
    border: none;
    font-weight: bold;
    background-color: #eeeeee !important;
    padding: 0px 10px !important;
  }
  .ant-segmented {
    background-color: #eeeeee !important;
    padding: 0px 10px !important;
  }
  .ant-segmented-item-selected {
    color: #e44404;
    background-color: #eeeeee;
    border-bottom: 3px solid #e44404;
    font-weight: bold;
    box-shadow: 0px 0px 0px #00000000;
  }
  .rightMenu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-menu-light {
    flex: 1;
    margin: 0px 20px;
  }
  .ant-menu-light .ant-menu-item:hover {
    background-color: #2e547a !important; /*#2E547A;*/
    color: #ffffff;
  }
  .ant-menu-horizontal {
    border-bottom: 0px solid #f0f0f0;
    line-height: 30px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 0px solid #f0f0f0;
    background-color: #2e547a !important;
    color: #ffffff;
  }
  .ant-menu-item {
    padding: 0px 40px !important;
    margin: 0px 5px;
    border-radius: 30px;
  }
  .ant-menu-item-selected {
    background-color: #2e547a !important;
    color: #ffffff !important;
    border-radius: 30px;
    padding: 0px 20px;
  }
`;
export const CardViewWrapper = styled.div`
  background-color: #ffffff;
  min-height: 100px;
  box-shadow: 0px 0px 20px #25405f2a;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
`;
