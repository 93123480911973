import Brand from "../Brand";
import UserInfo from "../UserInfo";
import { HeaderWrapper } from "./MaintenanceHeader.styles";

const MaintenanceHeader = () => {
  return (
    <HeaderWrapper>
      <div className="header">
        <Brand />
      </div>
    </HeaderWrapper>
  );
};

export default MaintenanceHeader;
