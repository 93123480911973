import styled from "styled-components";

export const UnauthorisedErrorViewWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;

  .errorIcon {
    width: 274px;
    height: 203px;
  }
  .errorMessageContainer {
    color: #000000;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: fit-content;
    height: auto;
    padding: 20px;
    margin: 20px 0px 30px;
    box-shadow: 2px 2px 5px 2px #00000029;
    border: 2px solid #00000029;
    border-radius: 5px;
    .errorTitle {
      font-size: 28px;
      margin-bottom: 20px;
      font-weight: 500;
    }
    .errorDescription {
      font-size: 18px;
    }
  }
  .cal-header-wrapper {
    padding: 10px;
    width: 100%;
    background-color: #253f61 !important;
  }
  .cal-error-wrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 100%;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .cal-top-wrapper {
    flex-direction: row;
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .cal-icon {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin-top: 20px;
  }
  .cal-content {
    flex-direction: column;
    display: flex;
    flex: 1;
    margin-top: 60px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .cal-title {
    font-size: 32px;
    font-weight: 600;
    color: #000000;
  }
  .cal-desc {
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 40px;
    font-weight: 400;
    flex: 1;
  }
  .cal-desc-list {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    color: #000000;
    li {
      margin: 20px 0px;
    }
    ul {
      list-style: none; /* Remove default bullets */
      padding-inline-start: 0px;
    }
  }
  .cal-footer {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 30px 20px;
    flex-direction: row;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /*background-color: #ecf5fd;*/
  }
  .cal-footer-message {
    font-size: 16px;
    flex: 1;
    color: #000000;
    max-width: 80%;
  }
  .cal-footer-logo {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .circle-dot {
    border-radius: 5px;
    background-color: #830051;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 15px;
  }
`;
