import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
var per_chart = null;
const LayeredColumnChart = (props) => {
  const [isLoading, setLoading] = useState(true);
  const CHART_ID = props.chartId || "layeredColumnChart";

  useEffect(() => {
    loadSBChart(props.chartData);
  }, [props.chartData]);
  useEffect(() => {
    return () => {
      per_chart.dispose();
    };
  }, []);

  const loadSBChart = async (_chartData) => {
    am4core.useTheme(am4themes_animated);
    if (per_chart) {
      per_chart.dispose();
    }
    per_chart = am4core.create(CHART_ID, am4charts.XYChart);
    // Add percent sign to all numbers
    per_chart.numberFormatter.numberFormat = "#,###.";
    per_chart.colors.list = [
      am4core.color("#013765"),
      am4core.color("#C4D600"),
    ];
    per_chart.paddingLeft = 0;
    per_chart.paddingRight = 0;
    // Add data
    per_chart.data = _chartData;

    // Create axes
    let categoryAxis = per_chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fontSize = "9pt";

    let valueAxis = per_chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "";
    valueAxis.title.fontWeight = 800;
    valueAxis.title.text = "SP/SD TRx";
    valueAxis.title.fontSize = "8pt";
    valueAxis.title.fontWeight = "bold";
    valueAxis.title.fill = "#013765";
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.fontSize = "10pt";

    let npsAxis = per_chart.yAxes.push(new am4charts.ValueAxis());
    npsAxis.title.text = "";
    npsAxis.title.fontWeight = 800;
    npsAxis.min = 0;
    //npsAxis.max = 1000;
    npsAxis.renderer.opposite = true;
    //npsAxis.syncWithAxis = valueAxis;
    npsAxis.renderer.grid.template.disabled = true;
    npsAxis.title.text = "SP/SD NPS";
    npsAxis.title.fontSize = "8pt";
    npsAxis.title.fontWeight = "bold";
    npsAxis.title.rotation = 270;
    npsAxis.title.fill = "#A0AF02";
    npsAxis.extraMax = 1;
    npsAxis.strictMinMax = true;
    npsAxis.renderer.labels.template.fontSize = "10pt";
    // Create series
    let series = per_chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "trx";
    series.dataFields.categoryX = "name";
    series.clustered = true;
    series.name = "SP/SD TRx";
    //series.showOnInit = false;
    /*let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "nps";
    series2.dataFields.categoryX = "month";
    series2.clustered = false;
    series2.columns.template.width = am4core.percent(50);*/
    var bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = -10;
    bullet.label.text = "{valueY}";
    bullet.label.fill = am4core.color("#000000");
    bullet.label.truncate = false;
    bullet.label.fontWeight = "normal";
    bullet.label.fontSize = "7pt";
    var latitudeSeries = per_chart.series.push(new am4charts.ColumnSeries());
    latitudeSeries.dataFields.valueY = "nps";
    latitudeSeries.dataFields.categoryX = "name";
    latitudeSeries.yAxis = npsAxis;
    latitudeSeries.name = "SP/SD NPS";
    latitudeSeries.strokeWidth = 2;
    latitudeSeries.propertyFields.strokeDasharray = "dashLength";
    latitudeSeries.showOnInit = false;

    /*var latitudeBullet = latitudeSeries.bullets.push(
      new am4charts.CircleBullet()
    );
    latitudeBullet.circle.fill = am4core.color("#C4D600");
    latitudeBullet.circle.strokeWidth = 2;
    latitudeBullet.circle.radius = 3;

    var latitudeState = latitudeBullet.states.create("hover");
    latitudeState.properties.scale = 1.2;*/
    // Custom legend
    var legend = new am4charts.Legend();
    legend.align = "left";
    legend.position = "bottom";
    legend.contentAlign = "center";
    legend.labels.template.fontSize = "12px";
    /*var marker = legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
marker.strokeWidth = 2;
marker.stroke = am4core.color("#ccc");
//per_chart.legend.useDefaultMarker = true;*/

    per_chart.legend = legend;
    
    let markerTemplate = per_chart.legend.markers.template;
    markerTemplate.width = 10;
    markerTemplate.height = 10;
    //per_chart.legend.markers.template.disabled = true;
    var latitudeLabel = latitudeSeries.bullets.push(
      new am4charts.LabelBullet()
    );
    latitudeLabel.dy = -10;
    latitudeLabel.label.text = "{nps}";

    latitudeLabel.label.fontWeight = "bold";
    latitudeLabel.label.truncate = false;
    latitudeLabel.label.fontSize = "7pt";
    latitudeLabel.label.fill = am4core.color("#A0AF02");
    //setTimeout(() => { per_chart.legend.x = -10; per_chart.legend.y = -20; }, 1000);
    setLoading(false);
  };
  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col xs={24}>
          {/*<HBarLegend>
            <LegendItem>
              <span
                className="legend-bullet"
                style={{ background: "#013765" }}
              ></span>
              <span className="legend-label">SP/SD Units</span>
            </LegendItem>
            <LegendItem>
              <span
                style={{ background: "#C4D600" }}
                className="legend-bullet"
              ></span>
              <span className="legend-label">SP/SD NPS</span>
            </LegendItem>
          </HBarLegend>*/}
        </Col>
        <Col xs={24}>
          <div
            id={CHART_ID}
            style={{
              width: "100%",
              height: "275px",
              marginTop: "0px",
            }}
          ></div>
        </Col>
      </Row>
    </Spin>
  );
};

export default LayeredColumnChart;
