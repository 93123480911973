import { Button, Cascader, Col, Row, Select, Spin } from "antd";
import * as d3 from "d3";
import { nest } from "d3-collection";
import { geoPath } from "d3-geo";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as topojson from "topojson-client";
import { GetAPIRequest } from "../../services";
import CONFIG from "../../constants";
import {
  getSelectedGeoFilters,
  setSelectedGeoFilterName,
  setSelectedGeoFilters,
  setSelectedGeoTerriroties,
} from "../../store/appSlice";
import { isUndefined } from "../../utils/utils";
import counties from "./counties_without_pr";
import counties_pr from "./counties";
import salesRegionData from "./salesRegionInfo";
import {
  SalesRegionMapContainer,
  SalesRegionMapWrapper,
} from "./SalesRegionMap.styles";
const { SHOW_CHILD } = Cascader;
const { Option, OptGroup } = Select;

var selectedGeo = [];
var _selectedSalesInput = [];
var regionDistrictTerritory = [];
var geoTerritory = [];
var selTerritories = [];
var _regionDistrictObj = {};
var _geoTerritoryObj = {};
var _regionTerritoryObj = {};
var _districtTerritoryObj = {};
var _districtTerritoryNameObj = {};
var selectedItems = [];
const filter = (inputValue, path) =>
  path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );

const SalesRegionMap = (props) => {
  const { url, containerId, onClick } = props;
  const [selBrand, setSelBrand] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  //const [salesRegionData, setSalesRegionData] = useState([]);
  const [selectedTerritories, setSelectedTerritories] = useState([]);
  const [selectedAreaArray, setSelectedAreaArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regionDistrictObj, setRegionDistrictObj] = useState({});
  const [regionTerritoryObj, setRegionTerritoryObj] = useState({});
  const [districtTerritoryObj, setDistrictTerritoryObj] = useState({});
  const [geoTerritoryObj, setGeoTerritoryObj] = useState({});
  const [selectedSalesInput, setSelectedSalesInput] = useState([]);
  const selectedGeoFilters = useSelector(getSelectedGeoFilters);
  const [searchData, setSearchData] = useState([]);
  const dispatch = useDispatch();

  const LightenColor = (color, percent) => {
    var num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;
    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };
  const randomBetween = (min, max) => {
    if (min < 0) {
      return min + Math.random() * (Math.abs(min) + max);
    } else {
      return min + Math.random() * max;
    }
  };
  const getTerritoryName = (territoryId) => {
    var result = salesRegionData.find((obj) => obj.territoryId == territoryId);
    return isUndefined(result) ? null : result;
  };

  const handleChange = (value) => {
    d3.selectAll(`#salesRegionSvg path`).attr("stroke", "#666666");
    d3.selectAll(`#salesRegionSvg path`).attr("stroke-width", "1");
    value.map((v) => {
      var val = v.split("-")[0];
      d3.select(`#${val}`).attr("stroke", "#ff0000");
      d3.select(`#${val}`).attr("stroke-width", "2");
    });
    setSelectedArea(value);
  };

  useEffect(() => {
    setSelectedSalesInput(_selectedSalesInput);
  }, [_selectedSalesInput]);
  useEffect(() => {
    //loadMapView(salesRegionData);
  }, [salesRegionData]);
  useEffect(() => {
    if (props.visible) {
      //getTerritoryDetails();
      loadMapView(salesRegionData);
    }
    return () => {};
  }, [props.visible]);

  const updateMapFilters = (selectedGeoFilters) => {
    _selectedSalesInput = selectedGeoFilters;
    console.log("selectedGeoFilters-1", selectedGeoFilters);
    console.log("_regionDistrictObj", _regionDistrictObj);
    console.log("_regionTerritoryObj", _regionTerritoryObj);
    console.log("_districtTerritoryObj", _districtTerritoryObj);
    selectedGeo = [];
    selTerritories = [];
    resetPath();
    selectedItems = [];
    selectedGeoFilters.map((_sd) => {
      if (_sd.length == 1) {
        if (_sd[0] == "All Geos") {
          Object.keys(_geoTerritoryObj).forEach((_k) => {
            let _idx = selectedGeo.indexOf(`${_k}-geo`);
            let id = _k.replaceAll(" ", "-") + "-geo";
            if (_idx < 0) {
              selectedGeo.push(`${_k}-geo`);
              d3.select(`#${id}`).attr("stroke", "#ff0000");
              d3.select(`#${id}`).attr("stroke-width", "4");
            } else {
              selectedGeo.splice(_idx, 1);
              d3.select(`#${id}`).attr("stroke", "#666666");
              d3.select(`#${id}`).attr("stroke-width", "1");
            }
          });
        } else {
          d3.select(`#${_sd[0]}`).attr("stroke", "#ff0000");
          d3.select(`#${_sd[0]}`).attr("stroke-width", "4");
          if (selectedGeo.indexOf(`${_sd[0]}-region`) < 0) {
            selectedGeo.push(`${_sd[0]}-region`);
          }
          var districts = _regionDistrictObj[_sd[0]];
          districts.map((_d) => {
            if (selectedGeo.indexOf(`${_d}-district`) < 0) {
              selectedGeo.push(`${_d}-district`);
            }
            var did = _d.replaceAll(" ", "-");
            d3.select(`#${did}`).attr("stroke", "#ff0000");
            d3.select(`#${did}`).attr("stroke-width", "4");
          });
          var territories = _regionTerritoryObj[_sd[0]];
          territories.map((_d) => {
            if (selTerritories.indexOf(_d) < 0) {
              selTerritories.push(_d);
            }
            var result = getTerritoryName(_d);
            var tid = result.territory.replaceAll(" ", "_");
            if (selectedGeo.indexOf(`${result.territory}-territory`) < 0) {
              selectedGeo.push(`${result.territory}-territory`);
            }
            d3.select(`#${tid}`).attr("stroke", "#ff0000");
            d3.select(`#${tid}`).attr("stroke-width", "4");
          });
        }
        selectedItems.push(_sd[0]);
      } else if (_sd.length == 2) {
        if (_sd[0] == "All Geos") {
          Object.keys(_geoTerritoryObj).forEach((_k) => {
            if (_sd[1] == _k) {
              let _idx = selectedGeo.indexOf(`${_k}-geo`);
              let id = _k.replaceAll(" ", "-") + "-geo";
              if (_idx < 0) {
                selectedGeo.push(`${_k}-geo`);
              }
              d3.select(`#${id}`).attr("stroke", "#ff0000");
              d3.select(`#${id}`).attr("stroke-width", "4");
              let territories = _geoTerritoryObj[_sd[1]];
              territories.map((_d) => {
                if (selTerritories.indexOf(_d) < 0) {
                  selTerritories.push(_d);
                }
              });
            }
          });
        } else {
          var did = _sd[1].replaceAll(" ", "-");
          d3.select(`#${did}`).attr("stroke", "#ff0000");
          d3.select(`#${did}`).attr("stroke-width", "4");
          var territories = _districtTerritoryObj[_sd[1]];
          if (selectedGeo.indexOf(`${_sd[1]}-district`) < 0) {
            selectedGeo.push(`${_sd[1]}-district`);
          }
          territories.map((_d) => {
            if (selTerritories.indexOf(_d) < 0) {
              selTerritories.push(_d);
            }
            var result = getTerritoryName(_d);
            var tid = result.territory.replaceAll(" ", "_");
            if (selectedGeo.indexOf(`${result.territory}-territory`) < 0) {
              selectedGeo.push(`${result.territory}-territory`);
            }
            d3.select(`#${tid}`).attr("stroke", "#ff0000");
            d3.select(`#${tid}`).attr("stroke-width", "4");
          });
        }
        if (_sd[0] == "All Geos") {
          selectedItems.push(`${_sd[1]} Geo`);
        } else {
          selectedItems.push(`${_sd[0]} | ${_sd[1]}`);
        }
      } else if (_sd.length == 3) {
        if (selTerritories.indexOf(_sd[2]) < 0) {
          selTerritories.push(_sd[2]);
        }
        console.log("_sd[2]", _sd[2]);
        var result = getTerritoryName(_sd[2]);
        var tid = result.territory.replaceAll(" ", "_");
        if (selectedGeo.indexOf(`${result.territory}-territory`) < 0) {
          selectedGeo.push(`${result.territory}-territory`);
        }
        d3.select(`#${tid}`).attr("stroke", "#ff0000");
        d3.select(`#${tid}`).attr("stroke-width", "4");
        selectedItems.push(`${_sd[0]} | ${_sd[1]} | ${_sd[2]}`);
      }
    });
    updateTerriroties();
    /*dispatch(setSelectedGeoFilters(selectedGeoFilters));
    dispatch(setSelectedGeoFilterName(selectedItems));*/
  };

  const loadMapView = () => {
    setTimeout(() => {
      regionDistrictTerritory = nest()
        .key(function (d) {
          return (
            d.region +
            "-" +
            d.district +
            "-" +
            d.territoryId +
            "-" +
            d.territory
          );
        })
        .rollup(function (d) {
          return d.map(function (g) {
            return g.fip;
          });
        })
        .object(salesRegionData);

      geoTerritory = nest()
        .key(function (d) {
          return d.geo + "-" + d.territoryId;
        })
        .rollup(function (d) {
          return d.map(function (g) {
            return g.fip;
          });
        })
        .object(salesRegionData);

      Object.keys(geoTerritory).forEach((k) => {
        var _k = k.split("-");
        if (_geoTerritoryObj[_k[0]]) {
          if (_geoTerritoryObj[_k[0]].indexOf(_k[1]) < 0) {
            _geoTerritoryObj[_k[0]].push(_k[1]);
          }
        } else {
          _geoTerritoryObj[_k[0]] = [];
          if (_geoTerritoryObj[_k[0]].indexOf(_k[1]) < 0) {
            _geoTerritoryObj[_k[0]].push(_k[1]);
          }
        }
      });
      setGeoTerritoryObj(_geoTerritoryObj);
      Object.keys(regionDistrictTerritory).forEach((k) => {
        var _k = k.split("-");
        if (_regionDistrictObj[_k[0]]) {
          if (_regionDistrictObj[_k[0]].indexOf(_k[1]) < 0) {
            _regionDistrictObj[_k[0]].push(_k[1]);
          }
        } else {
          _regionDistrictObj[_k[0]] = [];
          if (_regionDistrictObj[_k[0]].indexOf(_k[1]) < 0) {
            _regionDistrictObj[_k[0]].push(_k[1]);
          }
        }
        if (_regionTerritoryObj[_k[0]]) {
          if (_regionTerritoryObj[_k[0]].indexOf(_k[2]) < 0) {
            _regionTerritoryObj[_k[0]].push(_k[2]);
          }
        } else {
          _regionTerritoryObj[_k[0]] = [];
          if (_regionTerritoryObj[_k[0]].indexOf(_k[2]) < 0) {
            _regionTerritoryObj[_k[0]].push(_k[2]);
          }
        }
        if (_districtTerritoryObj[_k[1]]) {
          if (_districtTerritoryObj[_k[1]].indexOf(_k[2]) < 0) {
            _districtTerritoryObj[_k[1]].push(_k[2]);
          }
        } else {
          _districtTerritoryObj[_k[1]] = [];
          if (_districtTerritoryObj[_k[1]].indexOf(_k[2]) < 0) {
            _districtTerritoryObj[_k[1]].push(_k[2]);
          }
        }
        if (_districtTerritoryNameObj[_k[1]]) {
          if (_districtTerritoryNameObj[_k[1]].indexOf(_k[3]) < 0) {
            _districtTerritoryNameObj[_k[1]].push(_k[3]);
          }
        } else {
          _districtTerritoryNameObj[_k[1]] = [];
          if (_districtTerritoryNameObj[_k[1]].indexOf(_k[3]) < 0) {
            _districtTerritoryNameObj[_k[1]].push(_k[3]);
          }
        }
      });
      setRegionDistrictObj(_regionDistrictObj);
      setRegionTerritoryObj(_regionTerritoryObj);
      setDistrictTerritoryObj(_districtTerritoryObj);
      console.log("_regionDistrictObj", _regionDistrictObj);
      console.log("_regionTerritoryObj", _regionTerritoryObj);
      console.log("_districtTerritoryObj", _districtTerritoryObj);
      var searchInput = [];
      Object.keys(_regionDistrictObj).forEach((_k) => {
        var _obj = {
          label: _k,
          value: _k,
        };
        var childObj = [];
        var districts = _regionDistrictObj[_k];
        districts.map((_district) => {
          var _cobj = {
            label: _district,
            value: _district,
          };
          var distChildObj = [];
          var territories = _districtTerritoryNameObj[_district];
          var territoryIds = _districtTerritoryObj[_district];
          territories.map((_territory, idx) => {
            var _tobj = {
              label: _territory,
              value: territoryIds[idx],
            };
            distChildObj.push(_tobj);
          });
          _cobj["children"] = distChildObj;
          childObj.push(_cobj);
        });
        _obj.children = childObj;
        searchInput.push(_obj);
      });
      var _gtobj = {
        label: "All Geos",
        value: "All Geos",
      };
      var gtChildObj = [];
      Object.keys(_geoTerritoryObj).forEach((_k) => {
        var _cobj = {
          label: _k,
          value: _k,
        };
        gtChildObj.push(_cobj);
      });
      _gtobj["children"] = gtChildObj;
      searchInput.push(_gtobj);
      setSearchData(searchInput);
      loadAreaData(counties_pr, salesRegionData);
      loadDistrictData(counties_pr, salesRegionData);
      loadTerritoryData(counties_pr, salesRegionData);
      loadGeoData(counties_pr, salesRegionData);
      getTerritoryDetails();
      var filters = sessionStorage.getItem("filters");
      if (filters) {
        filters = filters.split(",");
      }
      var filterArr = [];
      if (filters && filters.length > 0) {
        filters.map((_f) => {
          var arr = _f.split(" | ");
          if (arr.length == 1) {
            if (arr[0].includes("Geo")) {
              filterArr.push(["All Geos", arr[0].replace(" Geo", "")]);
            } else {
              filterArr.push([arr[0]]);
            }
          } else if (arr.length == 2) {
            filterArr.push([...arr]);
          } else if (arr.length == 3) {
            filterArr.push([...arr]);
          }
        });
      }

      setSelectedSalesInput(filterArr);
      updateMapFilters(filterArr);
    }, 100);
  };

  const getTerritoryDetails = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getTerritoryInfo}`,
    }).then((response) => {
      if (response) {
        // setSalesRegionData(response);
        console.log(response);
      }
    });
  };

  const updateTerriroties = () => {
    console.log("selTerritories-2", selTerritories);
    console.log("selectedGeo", selectedGeo);
    //dispatch(setSelectedGeoTerriroties(selTerritories));
    selectedItems = [];
    _selectedSalesInput.map((_sd) => {
      if (_sd.length == 1) {
        selectedItems.push(_sd[0]);
      } else if (_sd.length == 2) {
        if (_sd[0] == "All Geos") {
          selectedItems.push(`${_sd[1]} Geo`);
        } else {
          selectedItems.push(`${_sd[0]} | ${_sd[1]}`);
        }
      } else if (_sd.length == 3) {
        var result = getTerritoryName(_sd[2]);
        selectedItems.push(`${_sd[0]} | ${_sd[1]} | ${_sd[2]}`);
      }
    });
    //dispatch(setSelectedGeoFilterName(selectedItems));
  };

  const drawMap = (us, salesRegion, _d, status) => {
    var epsilon = 0.000001
    function multiplex(streams) {
      const n = streams.length;
      return {
        point(x, y) { for (const s of streams) s.point(x, y); },
        sphere() { for (const s of streams) s.sphere(); },
        lineStart() { for (const s of streams) s.lineStart(); },
        lineEnd() { for (const s of streams) s.lineEnd(); },
        polygonStart() { for (const s of streams) s.polygonStart(); },
        polygonEnd() { for (const s of streams) s.polygonEnd(); }
      };
    }
    function geoAlbersUsaPr() {
      var cache,
          cacheStream,
          lower48 = d3.geoAlbers(), lower48Point,
          alaska = d3.geoConicEqualArea().rotate([154, 0]).center([-2, 58.5]).parallels([55, 65]),
          alaskaPoint,
          hawaii = d3.geoConicEqualArea().rotate([157, 0]).center([-3, 19.9]).parallels([8, 18]),
          hawaiiPoint,
          puertoRico = d3.geoConicEqualArea().rotate([66, 0]).center([0, 18]).parallels([8, 18]),
          puertoRicoPoint,
          point,
          pointStream = {point: function(x, y) { point = [x, y]; }};
    
      function albersUsa(coordinates) {
        var x = coordinates[0], y = coordinates[1];
        return point = null,
            (lower48Point.point(x, y), point)
            || (alaskaPoint.point(x, y), point)
            || (hawaiiPoint.point(x, y), point)
            || (puertoRicoPoint.point(x, y), point);
      }
    
      albersUsa.invert = function(coordinates) {
        var k = lower48.scale(),
            t = lower48.translate(),
            x = (coordinates[0] - t[0]) / k,
            y = (coordinates[1] - t[1]) / k;
        return (y >= 0.120 && y < 0.234 && x >= -0.425 && x < -0.214 ? alaska
            : y >= 0.166 && y < 0.234 && x >= -0.214 && x < -0.115 ? hawaii
            : y >= 0.204 && y < 0.234 && x >= 0.320 && x < 0.380 ? puertoRico
            : lower48).invert(coordinates);
      };
    
      albersUsa.stream = function(stream) {
        return cache && cacheStream === stream ? cache : cache = multiplex([lower48.stream(cacheStream = stream), alaska.stream(stream), hawaii.stream(stream), puertoRico.stream(stream)]);
      };
    
      albersUsa.precision = function(_) {
        if (!arguments.length) return lower48.precision();
        lower48.precision(_); alaska.precision(_); hawaii.precision(_); puertoRico.precision(_);
        return reset();
      };
    
      albersUsa.scale = function(_) {
        if (!arguments.length) return lower48.scale();
        lower48.scale(_); alaska.scale(_ * 0.35); hawaii.scale(_); puertoRico.scale(_);
        return albersUsa.translate(lower48.translate());
      };
    
      albersUsa.translate = function(_) {
        if (!arguments.length) return lower48.translate();
        var k = lower48.scale(), x = +_[0], y = +_[1];
    
        lower48Point = lower48
            .translate(_)
            .clipExtent([[x - 0.455 * k, y - 0.238 * k], [x + 0.455 * k, y + 0.238 * k]])
            .stream(pointStream);
    
        alaskaPoint = alaska
            .translate([x - 0.307 * k, y + 0.201 * k])
            .clipExtent([[x - 0.425 * k + epsilon, y + 0.120 * k + epsilon], [x - 0.214 * k - epsilon, y + 0.234 * k - epsilon]])
            .stream(pointStream);
    
        hawaiiPoint = hawaii
            .translate([x - 0.205 * k, y + 0.212 * k])
            .clipExtent([[x - 0.214 * k + epsilon, y + 0.166 * k + epsilon], [x - 0.115 * k - epsilon, y + 0.234 * k - epsilon]])
            .stream(pointStream);
    
        puertoRicoPoint = puertoRico
            .translate([x + 0.350 * k, y + 0.224 * k])
            .clipExtent([[x + 0.320 * k, y + 0.204 * k], [x + 0.380 * k, y + 0.234 * k]])
            .stream(pointStream).point;
    
        return reset();
      };
    
      function reset() {
        cache = cacheStream = null;
        return albersUsa;
      }
    
      return albersUsa.scale(1070);
    }
    var CONTAINER_ID = _d.id;
    var groupKey = _d.groupKey;
    var onPathClick = _d.path.onClick;
    var pathId = _d.path.id;
    var pathFill = _d.path.fill;
    d3.select(CONTAINER_ID).html("");
    
    var width = window.innerWidth / 2.5;
    var height = width * 0.7;
    var path = geoPath();
    
    
    var svg = d3
      .select(CONTAINER_ID)
      .append("svg")
      .attr("class", "map-area")
      .attr("viewBox", `0 0 900 700`)
      .attr("width", width)
      .attr(`height`, height);
    var groupedData = nest()
      .key(groupKey)
      .rollup(function (d) {
        return d.map(function (g) {
          return parseInt(g.fip);
        });
      })
      .entries(salesRegion);

    var groupedCounties = groupedData.map((fips) => {
      return topojson.merge(
        us,
        us.objects.counties.geometries.filter(function (d) {
          return fips.value.indexOf(+d.id) > -1;
        })
      );
    });
    var countis = {
      type: "FeatureCollection",
    };
    var groupedCountiesArr = [];
    groupedCounties.forEach((cnt, idx) => {
      groupedCountiesArr.push({
        type: "Feature",
        geometry: cnt,
        properties: { key: groupedData[idx].key },
        id: idx,
      });
    });
    countis["features"] = groupedCountiesArr;
    //if(status){
     var projection = geoAlbersUsaPr().scale(1300).translate([487.5, 305]);
      path = geoPath().projection(projection);
    //} 
    var g = svg
      .append("g")
      .attr("class", "center-container center-items us-state");
    g.append("g")
      .selectAll("path")
      .data(countis.features)
      .enter()
      .append("path")
      .attr("class", "county")
      .attr("fill", pathFill)
      .attr("stroke-width", "1")
      .attr("opacity", "1")
      .attr("stroke", (d) => {
        return "#666666";
      })
      .attr("id", pathId)
      .on("click", onPathClick)
      .attr("d", path);
    if (_d.showLabels) {
      g.selectAll("text")
        .data(countis.features)
        .enter()
        .append("svg:text")
        .text(function (d) {
          if (_d.isTerritory) {
            var terr = getTerritoryName(d.properties.key);
            var tarr = terr.territory.split(" ");
            var name = "";
            if (tarr.length == 4) {
              name = tarr[0] + " " + tarr[1];
            } else if (tarr.length == 3) {
              name = tarr[0];
            } else {
              name = tarr.join(" ");
            }
            return name;
          } else {
            return d.properties.key;
          }
        })
        .attr("x", function (d) {
          return path.centroid(d)[0];
        })
        .attr("y", function (d) {
          return path.centroid(d)[1];
        })
        .attr("id", pathId)
        .on("click", onPathClick)
        .attr("text-anchor", "middle")
        .attr("font-size", _d.isTerritory ? "12px" : "16pt")
        .attr("font-weight", "500")
        .attr("opacity", "0.5")
        .attr("fill", "#000000");
    }

    setLoading(false);
    if (_d.isZoomEnabled) {
      var zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .on("zoom", function (event) {
          g.selectAll("path").attr("transform", event.transform);
          g.selectAll("text").attr("transform", event.transform);
        });

      if (_d.isTerritory) {
        svg.call(zoom);
      } else {
        svg
          .call(zoom)
          .on("mousedown.zoom", null)
          .on("touchstart.zoom", null)
          .on("touchmove.zoom", null)
          .on("touchend.zoom", null);
      }
    }
  };

  const loadAreaData = async (us, salesRegion) => {
    const onRegionClick = (event, d) => {
      var region = d.properties.key;
      let _idx = selectedGeo.indexOf(`${region}-region`);
      var selInput = [];
      if (_idx < 0) {
        _selectedSalesInput.map((_sd, idx) => {
          if (_sd[0] == region) {
          } else {
            selInput.push(_sd);
          }
        });
        selInput.push([region]);
        _selectedSalesInput = selInput;
        setSelectedSalesInput([]);
        setSelectedSalesInput(selInput);
        selectedGeo.push(`${region}-region`);
        d3.select(`#${region}`).attr("stroke", "#ff0000");
        d3.select(`#${region}`).attr("stroke-width", "2");
        var districts = _regionDistrictObj[region];
        districts.map((_d) => {
          if (selectedGeo.indexOf(`${_d}-district`) < 0) {
            selectedGeo.push(`${_d}-district`);
          }
          var did = _d.replaceAll(" ", "-");
          d3.select(`#${did}`).attr("stroke", "#ff0000");
          d3.select(`#${did}`).attr("stroke-width", "4");
        });
        var territories = _regionTerritoryObj[region];
        territories.map((_d) => {
          if (selTerritories.indexOf(_d) < 0) {
            selTerritories.push(_d);
          }
          var result = getTerritoryName(_d);
          var tid = result.territory.replaceAll(" ", "_");
          if (selectedGeo.indexOf(`${result.territory}-territory`) < 0) {
            selectedGeo.push(`${result.territory}-territory`);
          }
          d3.select(`#${tid}`).attr("stroke", "#ff0000");
          d3.select(`#${tid}`).attr("stroke-width", "4");
        });
      } else {
        _selectedSalesInput.map((_sd, idx) => {
          if (_sd[0] == region) {
          } else {
            selInput.push(_sd);
          }
        });
        _selectedSalesInput = selInput;
        setSelectedSalesInput([]);
        setSelectedSalesInput(selInput);
        selectedGeo.splice(_idx, 1);
        var districts = _regionDistrictObj[region];
        districts.map((_d) => {
          var did = _d.replaceAll(" ", "-");
          selectedGeo.splice(selectedGeo.indexOf(`${_d}-district`), 1);
          d3.select(`#${did}`).attr("stroke", "#666666");
          d3.select(`#${did}`).attr("stroke-width", "1");
        });
        var territories = _regionTerritoryObj[region];
        territories.map((_d) => {
          if (selTerritories.indexOf(_d) >= 0) {
            selTerritories.splice(selTerritories.indexOf(_d), 1);
          }
          var result = getTerritoryName(_d);
          var tid = result.territory.replaceAll(" ", "_");
          selectedGeo.splice(
            selectedGeo.indexOf(`${result.territory}-territory`),
            1
          );
          d3.select(`#${tid}`).attr("stroke", "#666666");
          d3.select(`#${tid}`).attr("stroke-width", "1");
        });
        d3.select(`#${region}`).attr("stroke", "#666666");
        d3.select(`#${region}`).attr("stroke-width", "1");
      }
      var _selectedRegion = selectedRegion;
      let _index = _selectedRegion.indexOf(region);
      if (_index < 0) {
        _selectedRegion.push(region);
      } else {
        _selectedRegion.splice(_index, 1);
      }
      setSelectedRegion(_selectedRegion);
      _selectedRegion.map((_region) => {});
      updateTerriroties();
    };
    drawMap(us, salesRegion, {
      id: "#salesRegionSvg",
      groupKey: function (d) {
        return d.region;
      },
      path: {
        id: (d) => {
          return d.properties.key;
        },
        fill: (d) => {
          var color = "none";
          var _key = d.properties.key;
          if (_key.includes("East")) {
            color = "#BBCC6E";
          } else {
            color = "#64A1BC";
          }
          return color;
        },
        onClick: onRegionClick,
      },
      isZoomEnabled: false,
      showLabels: true,
    },true);
  };

  const loadDistrictData = async (us, salesRegion) => {
    var districtColors = {
      Carolinas: "#FFFCB2",
      Detroit: "#64A1BC",
      "Mid Atlantic": "#BBCC6E",
      "New England": "#D1A498",
      "New York": "#2356B6",
      Southeast: "#D7B2FF",
      California: "#8CC0BF",
      Central: "#D6A274",
      "Great Lakes": "#FFB2D3",
      "Rocky Mountains": "#F3EA88",
      Texas: "#FF6E6B",
      "Puerto Rico_ONC": "#8CC0EE",
    };
    const onDistrictClick = (event, d) => {
      var district = d.properties.key;
      let _idx = selectedGeo.indexOf(`${district}-district`);
      let id = district.replaceAll(" ", "-");
      var _data = salesRegion.find((obj) => obj.district == district);
      var selInput = [];
      if (_idx < 0) {
        _selectedSalesInput.map((_sd, idx) => {
          if (
            (_sd.length == 1 && _sd[0] == _data.region) ||
            _sd[1] == district
          ) {
          } else {
            selInput.push(_sd);
          }
        });
        selInput.push([_data.region, district]);
        _selectedSalesInput = selInput;
        setSelectedSalesInput([]);
        setSelectedSalesInput(selInput);
        selectedGeo.push(`${district}-district`);
        d3.select(`#${id}`).attr("stroke", "#ff0000");
        d3.select(`#${id}`).attr("stroke-width", "4");
        var territories = _districtTerritoryObj[district];
        territories.map((_d) => {
          if (selTerritories.indexOf(_d) < 0) {
            selTerritories.push(_d);
          }
          var result = getTerritoryName(_d);
          var id = result.territory.replaceAll(" ", "_");
          if (selectedGeo.indexOf(`${result.territory}-territory`) < 0) {
            selectedGeo.push(`${result.territory}-territory`);
          }
          d3.select(`#${id}`).attr("stroke", "#ff0000");
          d3.select(`#${id}`).attr("stroke-width", "4");
        });
      } else {
        selectedGeo.splice(_idx, 1);
        _selectedSalesInput.map((_sd, idx) => {
          if (_sd.length == 2 && _sd[1] == district) {
          } else if (_sd.length == 1 && _sd[0] == _data.region) {
          } else {
            selInput.push(_sd);
          }
        });
        var districtsArr = _regionDistrictObj[_data.region];
        var _selInput = [...selInput];
        districtsArr.map((_dt, _i) => {
          if (_dt != district) {
            if (selInput.length > 0) {
            } else {
              _selInput.push([_data.region, _dt]);
            }
          }
        });
        _selectedSalesInput = _selInput;
        setSelectedSalesInput([]);
        setSelectedSalesInput(selInput);
        d3.select(`#${id}`).attr("stroke", "#666666");
        d3.select(`#${id}`).attr("stroke-width", "1");
        var territories = _districtTerritoryObj[district];

        territories.map((_d) => {
          var result = getTerritoryName(_d);
          var tid = result.territory.replaceAll(" ", "_");
          if (selTerritories.indexOf(_d) >= 0) {
            selTerritories.splice(selTerritories.indexOf(_d), 1);
          }
          selectedGeo.splice(
            selectedGeo.indexOf(`${result.territory}-territory`),
            1
          );
          d3.select(`#${tid}`).attr("stroke", "#666666");
          d3.select(`#${tid}`).attr("stroke-width", "1");
        });
      }

      if (_data) {
        var region = _data.region;
        let _idx = selectedGeo.indexOf(`${region}-region`);
        selectedGeo.splice(_idx, 1);
        d3.select(`#${region}`).attr("stroke", "#666666");
        d3.select(`#${region}`).attr("stroke-width", "1");
      }
      updateTerriroties();
    };
    drawMap(us, salesRegion, {
      id: "#salesDistrictSvg",
      groupKey: function (d) {
        return d.district;
      },
      path: {
        id: (d) => {
          return d.properties.key.replaceAll(" ", "-");
        },
        fill: (d) => {
          var district = d.properties.key;
          var color = "none";
          if (district == null) {
            color = "#ffffff";
          } else if (district.includes("Unassigned District")) {
            color = "#ffffff";
          } else {
            color = districtColors[district];
          }
          return color;
        },
        onClick: onDistrictClick,
      },
      isZoomEnabled: false,
      showLabels: true,
    });
  };

  const loadTerritoryData = async (us, salesRegion) => {
    var colorArr = [];
    var territoryArr = [];
    var districtColors = {
      Carolinas: "#FFFCB2",
      Detroit: "#64A1BC",
      "Mid Atlantic": "#BBCC6E",
      "New England": "#D1A498",
      "New York": "#2356B6",
      Southeast: "#D7B2FF",
      California: "#8CC0BF",
      Central: "#D6A274",
      "Great Lakes": "#FFB2D3",
      "Rocky Mountains": "#F3EA88",
      Texas: "#FF6E6B",
      "Puerto Rico_ONC": "#8CC0EE",
    };
    const getColor = (district, territoryId) => {
      var color = "";
      if (district == null) {
        color = "#ffffff";
      } else if (district.indexOf("Unassigned District") > -1) {
        color = "#ffffff";
      } else {
        if (territoryArr.indexOf(territoryId) > -1) {
          var idx = territoryArr.indexOf(territoryId);
          color = colorArr[idx];
        } else {
          if(districtColors[district]){
            var _c = LightenColor(
              districtColors[district],
              randomBetween(-20, -10)
            );
            territoryArr.push(territoryId);
            colorArr.push(_c);
            color = _c;
          }
          
        }
      }
      return color;
    };
    const onTerritoryClick = (event, d) => {
      var result = getTerritoryName(d.properties.key);
      if (result.district && result.territory) {
        var territory = result.territory;
        let id = territory.replaceAll(" ", "_");
        var selInput = [];
        if (selectedGeo.indexOf(`${territory}-territory`) < 0) {
          _selectedSalesInput.map((_sd, idx) => {
            if (
              (_sd.length == 1 && _sd[0] == result.region) ||
              (_sd.length == 2 && _sd[1] == result.district) ||
              (_sd.length == 3 && _sd[2] == d.properties.key)
            ) {
            } else {
              selInput.push(_sd);
            }
          });
          selInput.push([result.region, result.district, result.territoryId]);
          _selectedSalesInput = selInput;
          setSelectedSalesInput([]);
          setSelectedSalesInput(selInput);
          selectedGeo.push(`${territory}-territory`);
          if (selTerritories.indexOf(result.territoryId) < 0) {
            selTerritories.push(result.territoryId);
          }
          d3.select(`#${id}`).attr("stroke", "#ff0000");
          d3.select(`#${id}`).attr("stroke-width", "4");
        } else {
          selectedGeo.splice(selectedGeo.indexOf(`${territory}-territory`), 1);
          _selectedSalesInput.map((_sd, idx) => {
            if (
              (_sd.length == 1 && _sd[0] == result.region) ||
              (_sd.length == 2 && _sd[1] == result.district) ||
              (_sd.length == 3 && _sd[2] == d.properties.key)
            ) {
            } else {
              selInput.push(_sd);
            }
          });
          var districtTerritoryArr = _districtTerritoryObj[result.district];
          var _selInput = [...selInput];
          districtTerritoryArr.map((_dt, _i) => {
            if (_dt != d.properties.key) {
              if (selInput.length > 0) {
              } else {
                _selInput.push([result.region, result.district, _dt]);
              }
            }
          });
          _selectedSalesInput = _selInput;
          setSelectedSalesInput([]);
          setSelectedSalesInput(selInput);
          if (selTerritories.indexOf(result.territoryId) >= 0) {
            selTerritories.splice(
              selTerritories.indexOf(result.territoryId),
              1
            );
          }
          d3.select(`#${id}`).attr("stroke", "#666666");
          d3.select(`#${id}`).attr("stroke-width", "1");
        }

        var region = result.region;
        if (selectedGeo.indexOf(`${region}-region`) >= 0) {
          selectedGeo.splice(selectedGeo.indexOf(`${region}-region`), 1);
          d3.select(`#${region}`).attr("stroke", "#666666");
          d3.select(`#${region}`).attr("stroke-width", "1");
        }
        if (selectedGeo.indexOf(`${result.district}-district`) >= 0) {
          var district = result.district;
          let did = district.replaceAll(" ", "-");
          selectedGeo.splice(
            selectedGeo.indexOf(`${result.district}-district`),
            1
          );
          d3.select(`#${did}`).attr("stroke", "#666666");
          d3.select(`#${did}`).attr("stroke-width", "1");
        }
        console.log("selTerritories-1", selTerritories);
        updateTerriroties();
      }
    };
    drawMap(us, salesRegion, {
      id: "#salesTerritorySvg",
      groupKey: function (d) {
        return d.territoryId;
      },
      path: {
        id: (d) => {
          var result = getTerritoryName(d.properties.key);
          return result.territory.replaceAll(" ", "_");
        },
        fill: (d) => {
          var result = getTerritoryName(d.properties.key);
          var color = "none";
          if (result && result.district && result.territory) {
            color = getColor(result.district, result.territory);
          }

          return color;
        },
        onClick: onTerritoryClick,
      },
      isZoomEnabled: true,
      showLabels: true,
      isTerritory: true,
    });
  };

  const loadGeoData = async (us, salesRegion) => {
    var geoColors = {
      Atlanta: "#9988BD",
      California: "#ADC48E",
      Charlotte: "#FFCBB2",
      Chicago: "#D6A274",
      Florida: "#F3EA88",
      Indianapolis: "#D1CC98",
      "Mid South": "#8CC0BF",
      "Mid West": "#FF6E6B",
      Minneapolis: "#FFFCB2",
      Nashville: "#FFB2D3",
      "New England": "#BB64BA",
      "New York": "#D57474",
      Northwest: "#FFB2D3",
      "Ohio Valley": "#83B96D",
      Philadelphia: "#C4FFB2",
      Phoenix: "#8CC0BF",
      Texas: "#D1B998",
      "Washington DC": "#8BA6C1",
      "Puerto Rico":"#ADC4EE",
      "Honolulu HI LC": "#ADC4EE", 
    };
    const onGeoClick = (event, d) => {
      var geo = d.properties.key;
      let _selectedArea = selectedArea;
      let idx = _selectedArea.indexOf(`${geo}-geo`);
      let _idx = selectedGeo.indexOf(`${geo}-geo`);
      let id = geo.replaceAll(" ", "-") + "-geo";
      var selInput = [];
      if (_idx < 0) {
        _selectedSalesInput.map((_sd, idx) => {
          if ((_sd.length == 1 && _sd[0] == "All Geos") || _sd[1] == geo) {
          } else {
            selInput.push(_sd);
          }
        });
        var territories = _geoTerritoryObj[geo];
        selInput.push(["All Geos", geo]);
        _selectedSalesInput = selInput;
        setSelectedSalesInput([]);
        setSelectedSalesInput(selInput);
        selectedGeo.push(`${geo}-geo`);
        d3.select(`#${id}`).attr("stroke", "#ff0000");
        d3.select(`#${id}`).attr("stroke-width", "4");
        territories.map((_d) => {
          if (selTerritories.indexOf(_d) < 0) {
            selTerritories.push(_d);
          }
        });
      } else {
        selectedGeo.splice(_idx, 1);
        _selectedSalesInput.map((_sd, idx) => {
          if (
            (_sd.length == 1 && _sd[0] == "All Geos") ||
            (_sd.length == 2 && _sd[1] == geo)
          ) {
          } else {
            selInput.push(_sd);
          }
        });
        console.log("selInput", selInput);
        _selectedSalesInput = selInput;
        setSelectedSalesInput([]);
        setSelectedSalesInput(selInput);
        d3.select(`#${id}`).attr("stroke", "#666666");
        d3.select(`#${id}`).attr("stroke-width", "1");
        var territories = _geoTerritoryObj[geo];
        territories.map((_d) => {
          if (selTerritories.indexOf(_d) >= 0) {
            selTerritories.splice(selTerritories.indexOf(_d), 1);
          }
        });
      }
      updateTerriroties();
    };
    drawMap(us, salesRegion, {
      id: "#salesGeoSvg",
      groupKey: function (d) {
        return d.geo;
      },
      path: {
        id: (d) => {
          return d.properties.key.replaceAll(" ", "-") + "-geo";
        },
        fill: (d) => {
          var geo = d.properties.key;
          var color = "none";
          if (geo == null) {
            color = "#ffffff";
          } else if (geo.includes("Unassigned District")) {
            color = "#ffffff";
          } else {
            color = geoColors[geo];
          }
          return color;
        },
        onClick: onGeoClick,
      },
      isZoomEnabled: false,
      showLabels: true,
    });
  };

  const onFilterChange = (v) => {
    setSelectedSalesInput(v);
    updateMapFilters(v);

    //onSalesmapChange(v, false);
  };

  const resetPath = () => {
    d3.selectAll(`#salesRegionSvg path`).attr("stroke", "#666666");
    d3.selectAll(`#salesRegionSvg path`).attr("stroke-width", "1");
    d3.selectAll(`#salesDistrictSvg path`).attr("stroke", "#666666");
    d3.selectAll(`#salesDistrictSvg path`).attr("stroke-width", "1");
    d3.selectAll(`#salesTerritorySvg path`).attr("stroke", "#666666");
    d3.selectAll(`#salesTerritorySvg path`).attr("stroke-width", "1");
    d3.selectAll(`#salesGeoSvg path`).attr("stroke", "#666666");
    d3.selectAll(`#salesGeoSvg path`).attr("stroke-width", "1");
  };

  return (
    <Spin spinning={loading}>
      <SalesRegionMapWrapper>
        <div className="map-title-view">
          <h4>Select Geo / Area / District / Territory</h4>
          <div className="map-title-button-view">
            <Button
              key="back"
              onClick={() => {
                setSelectedSalesInput([]);
                onFilterChange([]);
                dispatch(setSelectedGeoFilters([]));
                dispatch(setSelectedGeoFilterName([]));
                dispatch(setSelectedGeoTerriroties([]));
                props.onCancel();
              }}
            >
              Clear All
            </Button>
            <Button
              key="cancel"
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              style={{ background: "#2E547A" }}
              type="primary"
              onClick={() => {
                //updateTerriroties();
                dispatch(setSelectedGeoTerriroties(selTerritories));
                dispatch(setSelectedGeoFilters(selectedGeoFilters));
                dispatch(setSelectedGeoFilterName(selectedItems));
                props.onCancel();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
        <Row>
          <Col xs={24}>
            {/*<Select
              showSearch
              placeholder="Search Geo / Area / District / Territory"
              mode="multiple"
              style={{
                width: "100%",
              }}
              allowClear
              value={selectedArea}
              onChange={handleChange}
              className="sticky-select"
            >
              <OptGroup label="Areas">
                {regs.map((r, idx) => (
                  <Option key={idx} value={`${r}-region`}>
                    {r}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label="Districts">
                {dists.map((d, idx) => (
                  <Option key={idx + 100} value={`${d}-district`}>
                    {d}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label="Geos">
                {geo.map((g, idx) => (
                  <Option key={idx + 1000} value={`${g}-geo`}>
                    {g}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label="Territories">
                {terrs.map((t, idx) => (
                  <Option key={idx + 10000} value={`${t}-geo`}>
                    {t}
                  </Option>
                ))}
              </OptGroup>
            </Select>*/}
            <Cascader
              style={{
                width: "100%",
              }}
              placeholder="Select sales region/district/territory"
              options={searchData}
              changeOnSelect={true}
              value={selectedSalesInput}
              onChange={onFilterChange}
              showSearch={{
                filter,
              }}
              onSearch={(value) => console.log(value)}
              multiple
              maxTagCount="responsive"
            />
          </Col>
        </Row>
        <div className="salesmapContainer">
          <div className="salesmap">
            {salesRegionData && salesRegionData.length > 0 && <h3>Geo</h3>}
            <SalesRegionMapContainer id="salesGeoSvg"></SalesRegionMapContainer>
          </div>
          <div className="salesmap">
            {salesRegionData && salesRegionData.length > 0 && <h3>Area</h3>}
            <SalesRegionMapContainer id="salesRegionSvg"></SalesRegionMapContainer>
          </div>
          <div className="salesmap">
            {salesRegionData && salesRegionData.length > 0 && <h3>District</h3>}
            <SalesRegionMapContainer id="salesDistrictSvg"></SalesRegionMapContainer>
          </div>
          <div className="salesmap">
            {salesRegionData && salesRegionData.length > 0 && (
              <h3>Territory</h3>
            )}
            <SalesRegionMapContainer id="salesTerritorySvg"></SalesRegionMapContainer>
          </div>
        </div>
        <div id="sales-tooltip" style={{ position: "absolute" }}></div>
      </SalesRegionMapWrapper>
    </Spin>
  );
};

SalesRegionMap.propTypes = {
  // bla: PropTypes.string,
};

SalesRegionMap.defaultProps = {
  // bla: 'test',
};

export default SalesRegionMap;
