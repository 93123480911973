import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .header {
    background-color: #ffffff; /*#2E547A;*/
    border-bottom: 1px solid #0000000a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #000000;
    padding: 10px 10px !important;
  }
`;
