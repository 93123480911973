import styled from "styled-components";

export const TimelineHistoryWrapper = styled.div`
  width: 100%;
  .timelineContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }

  .timelineWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    padding: 10px;
  }

  .timelineHeader {
    font-weight: bold;
    padding: 10px 10px 0px;
  }

  .timelineContentWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .activityContentWrapper1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 600px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .activityContent {
    font-size: 13px;
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 50%;
  }

  .activityContent .title {
    font-family: "BigNoodleTitle";
    font-size: 26px;
    text-align: left;
  }

  .bulletDiv {
    display: flex;
    align-items: center;
    padding: 0px 10px;
  }

  .squareDiv {
    height: 12px;
    width: 12px;
  }

  .squareText {
    padding-left: 5px;
  }

  .timelineDiv {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px 50px 20px;
  }
  .timelineArrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .activityContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0px 5px;
  }
`;
