import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import Modal from "antd/lib/modal/Modal";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Tag,
  Switch,
  AutoComplete,
} from "antd";
import { GetAPIRequest, PostAPIRequest } from "../../../../services";
import CONFIG from "../../../../constants";
import { CloseCircleTwoTone, SearchOutlined } from "@ant-design/icons";
import { setIsCustomerTablePercent } from "../../../../store/appSlice";
import { useDispatch } from "react-redux";

const { CheckableTag } = Tag;

const dataTags = ["Yes", "No"];

const { Search } = Input;
const { Option } = Select;

const CustomerFilter = forwardRef((props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [priorityMCLData, setMCLPriorityData] = useState([]);
  const [priorityCLLData, setCLLPriorityData] = useState([]);
  const [priorityEvusheldData, setEvusheldPriorityData] = useState([]);
  const [selectedMCLTags, setSelectedMCLTags] = useState([]);
  const [selectedCLLTags, setSelectedCLLTags] = useState([]);
  const [selectedEvusheldTags, setSelectedEvusheldTags] = useState([]);

  // const [infocusAccount, setInfocusAccount] = useState(false);
  // const [callReach, setCallReach] = useState(false);

  const [isCustomerTblPercent, setIsCustomerTblPercent] = useState(
    props.isCustomerTblPercent
  );
  const [infocusTags, setInfocusAccount] = useState([]);
  const [reachedTags, setCallReach] = useState([]);
  const dispatch = useDispatch();
  const [spUnits, setSPUnits] = useState();
  const [spUnitsValue, setSPUnitsValue] = useState();
  const [spNps, setSPNps] = useState();
  const [spNpsValue, setSPNpsValue] = useState();
  const [sdUnits, setSDUnits] = useState();
  const [sdUnitsValue, setsdUnitsValue] = useState();

  const [calNPS, setCalNps] = useState();
  const [calNPSValue, setCalNpsValue] = useState();
  const [zanuNPS, setZanuNPS] = useState();
  const [zanuNPSValue, setZanuNPSValue] = useState();
  const [ibruNPS, setIbruNPS] = useState();
  const [ibruNPSValue, setIbruNPSValue] = useState();
  const [venNPS, setVenNPS] = useState();
  const [venNPSValue, setVenNPSValue] = useState();
  const [citNPS, setCitNPS] = useState();
  const [citNPSValue, setCitNPSValue] = useState();
  const [height, setHeight] = useState(window.innerHeight);

  useImperativeHandle(ref, () => ({
    updateFilters(type, value) {
      if (type == "ACCOUNT") {
        let list = selectedAccountList.filter((x) => x.name != value);
        setSelectedAccountList(list);
      } else if (type == "MCL") {
        let list = selectedMCLTags.filter((x) => x != value);
        setSelectedMCLTags(list);
      } else if (type == "CLL") {
        let list = selectedCLLTags.filter((x) => x != value);
        setSelectedCLLTags(list);
      } else if (type == "EVUSHELD") {
        let list = selectedEvusheldTags.filter((x) => x != value);
        setSelectedEvusheldTags(list);
      } else if (type == "CITNPS") {
        setCitNPS();
        setCitNPSValue();
      } else if (type == "VENNPS") {
        setVenNPS();
        setVenNPSValue();
      } else if (type == "IBRUNPS") {
        setIbruNPS();
        setIbruNPSValue();
      } else if (type == "ZANUNPS") {
        setZanuNPS();
        setZanuNPSValue();
      } else if (type == "CALNPS") {
        setCalNps();
        setCalNpsValue();
      } else if (type == "SDUNITS") {
        setSDUnits();
        setsdUnitsValue();
      } else if (type == "SPNPS") {
        setSPNps();
        setSPNpsValue();
      } else if (type == "SPUNITS") {
        setSPUnits();
        setSPUnitsValue();
      } else if (type == "CALLREACH") {
        setCallReach([]);
      } else if (type == "FOCUSACCOUNT") {
        setInfocusAccount([]);
      }
    },
    clearAll() {
      clearAll();
    },
  }));

  const updateDimensions = () => {
    // setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    console.log("tumorType", props.customerType);
    console.log("indicationType", props.indicationType);
    getAccountsBySearch();
    getNumberComparer();
  }, [props.customerType, props.indicationType, props.isCustomerTblPercent]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let names = selectedAccountList.map((item, index) => {
      return `${item.name} (${item.value})`;
    });
    let data = {
      //  "tumorType": props.indicationType,
      territories: [],
      // "isPercentage": isCustomerTblPercent,
      //  "accountType": props.customerType,
      fromDt: "",
      toDt: "",
      // "limit": 100,
      // "offSet": 0,
      names: names,
      cll_priorities: selectedCLLTags,
      mcl_priorities: selectedMCLTags,
      evushield_priorities: selectedEvusheldTags,
      //"focused_Account": "Yes",
      //"focused_Account": infocusTags,
      sp_trx_value: spUnitsValue,
      sp_nps_value: spNpsValue,

      //"sd_nps_value": 0,
      sp_trx: spUnits,
      sp_nps: spNps,

      //"sd_nps": "Less than",
      acalabrutinib: calNPS,
      zanubrutinib: zanuNPS,
      ibrutinib: ibruNPS,
      venetoclax: venNPS,
      cit: citNPS,
      //"call_reach": "Yes",
      //"call_reach": reachedTags,
      acalabrutinib_value: calNPSValue,
      zanubrutinib_value: zanuNPSValue,
      ibrutinib_value: ibruNPSValue,
      venetoclax_value: venNPSValue,
      cit_value: citNPSValue,
    };

    if (props.customerType != "HCP") {
      data.sd_trx = sdUnits;
      data.sd_trx_value = sdUnitsValue;
    }

    if (infocusTags.length == 1) {
      data.focused_Account = infocusTags[0];
    }
    if (reachedTags.length == 1) {
      data.call_reach = reachedTags[0];
    }

    //  props.getCustomerListByFilter(data);
    dispatch(setIsCustomerTablePercent(isCustomerTblPercent));
    props.setFilterDataOnChange(data);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [accountList, setAccountList] = useState([]);
  const [selectedAccountList, setSelectedAccountList] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [numberComparer, setNumberComparer] = useState([]);
  const getNumberComparer = () => {
    GetAPIRequest({
      url: `${CONFIG.api.getNumberComparer}`,
    })
      .then((res) => {
        setNumberComparer(res);
      })
      .catch((err) => {});
  };

  const onAccountSearch = (value) => {
    setAccountName(value);
    PostAPIRequest(
      {
        url: `${CONFIG.api.getAccountBySearchV2}`,
        data: {
          tumorType: props.indicationType,
          territories: [],
          q: value,
          accountType: props.customerType,
          fromDt: props.dateRange[0],
          toDt: props.dateRange[1],
        },
      })
        .then((res) => {
          setAccountList(res);
        })
        .catch((err) => {})
   /* GetAPIRequest({
      url: `${CONFIG.api.getAccountBySearch}/${props.customerType}/tumerType/${props.indicationType}/search?q=${value}`,
    })
      .then((res) => {
        setAccountList(res);
      })
      .catch((err) => {});*/
  };
  const addAccount = (value) => {
    let selectedAccountListObj = selectedAccountList.filter(
      (x) => x.name == value
    );
    if (selectedAccountListObj.length == 0) {
      let selectedObj = accountList.filter((x) => x.name == value);
      setSelectedAccountList([...selectedAccountList, ...selectedObj]);
      setAccountName("");
    }
  };

  const removeAccount = (value) => {
    let list = selectedAccountList.filter((x) => x.value != value);
    setSelectedAccountList(list);
  };

  //Tags Code

  const handleInfocusTags = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...infocusTags, tag]
      : infocusTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setInfocusAccount(nextSelectedTags);
  };

  const handlereachedTags = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...reachedTags, tag]
      : reachedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setCallReach(nextSelectedTags);
  };

  const handleMCLChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedMCLTags, tag]
      : selectedMCLTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedMCLTags(nextSelectedTags);
  };

  const handleCLLChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedCLLTags, tag]
      : selectedCLLTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedCLLTags(nextSelectedTags);
  };

  const handleEvusheldChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedEvusheldTags, tag]
      : selectedEvusheldTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedEvusheldTags(nextSelectedTags);
  };

  const [drugs, setDrugs] = useState(["cll", "mcl", "evusheld"]);
  const getAccountsBySearch = () => {
    drugs.map((item) => {
      GetAPIRequest({
        url: `${CONFIG.api.getPriorities}/${props.customerType}/drug/${item}/priorities`,
      })
        .then((res) => {
          if (item == "mcl") setMCLPriorityData(res);
          else if (item == "cll") setCLLPriorityData(res);
          else setEvusheldPriorityData(res);
        })
        .catch((err) => {});
    });
  };

  const clearAll = () => {
    setSelectedMCLTags([]);
    setSelectedCLLTags([]);
    setSelectedEvusheldTags([]);
    setSelectedAccountList([]);
    setInfocusAccount([]);
    setCallReach([]);
    setSPUnits();
    setSPUnitsValue();
    setSPNps();
    setSPNpsValue();
    setSDUnits();
    setsdUnitsValue();
    setCalNps();
    setCalNpsValue();
    setZanuNPS();
    setZanuNPSValue();
    setIbruNPS();
    setIbruNPSValue();
    setVenNPS();
    setVenNPSValue();
    setCitNPS();
    setCitNPSValue();
    setAccountName("");
    props.clearAll({});
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ backgroundColor: "#25405F", marginRight: "10px" }}
      >
        Filter
      </Button>
      <Modal
        title="Filters"
        className="filter-modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"85%"}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Apply
          </Button>,
        ]}
      >
        <div
          className="filter-container"
          style={{
            overflowX: "auto",
            maxHeight: height - 150,
          }}
        >
          {" "}
          <Row>
            <Col span={24}>
              <div className="search-ctn">
                <label>search</label>
                <AutoComplete
                  //options={accountList}
                  onSearch={onAccountSearch}
                  onSelect={addAccount}
                  style={{
                    width: "100%",
                    marginTop: "5px",
                  }}
                  value={accountName}
                  allowClear
                >
                  {accountList.map((item, index) => (
                    <AutoComplete.Option key={item.value} value={item.name}>
                      {item.name}{" "}<b>({item.value})</b>
                    </AutoComplete.Option>
                  ))}
                </AutoComplete>
                <SearchOutlined />
              </div>
            </Col>
          </Row>
          <div className="filte-main-ctn">
            <Row
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "5px",
              }}
            >
              <Col span={20}>
                {selectedAccountList.map((item, index) => {
                  return (
                    <div className="fliter-opt">
                      <span>
                        {item.name}{" "}({item.value})
                        <CloseCircleTwoTone
                          onClick={() => removeAccount(item.value)}
                        />
                      </span>
                    </div>
                  );
                })}
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <Button className="clear-btn-mdl" onClick={() => clearAll()}>
                  {" "}
                  Clear All
                </Button>
              </Col>
            </Row>
            <Row className="gray-ctn">
              {props.customerType != "TopParent" && (
                <Col span={14}>
                  <div className="radio-ctn">
                    <label
                      style={{
                        marginRight: 8,
                      }}
                    >
                      MCL Priority
                    </label>
                    {priorityMCLData.map((tag, index) => {
                      return (
                        <CheckableTag
                          className="radio-btn"
                          key={tag}
                          checked={selectedMCLTags.indexOf(tag) > -1}
                          onChange={(checked) => handleMCLChange(tag, checked)}
                        >
                          {tag}
                        </CheckableTag>
                      );
                    })}
                  </div>
                  <div className="radio-ctn">
                    <label
                      style={{
                        marginRight: 8,
                      }}
                    >
                      CLL Priority
                    </label>
                    {priorityCLLData.map((tag, index) => {
                      return (
                        <CheckableTag
                          className="radio-btn"
                          key={tag}
                          checked={selectedCLLTags.indexOf(tag) > -1}
                          onChange={(checked) => handleCLLChange(tag, checked)}
                        >
                          {tag}
                        </CheckableTag>
                      );
                    })}
                  </div>
                  {props.customerType != "Parent" && (
                    <div className="radio-ctn">
                      <label
                        style={{
                          marginRight: 8,
                        }}
                      >
                        Evusheld Priority
                      </label>
                      {priorityEvusheldData.map((tag, index) => {
                        return (
                          <CheckableTag
                            className="radio-btn"
                            key={tag}
                            checked={selectedEvusheldTags.indexOf(tag) > -1}
                            onChange={(checked) =>
                              handleEvusheldChange(tag, checked)
                            }
                          >
                            {tag}
                          </CheckableTag>
                        );
                      })}
                    </div>
                  )}
                </Col>
              )}
              <Col span={10}>
                <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                  <Col span={8}>
                    <div className="select-ctn">
                      <label>SP Units</label>
                      <Select
                        className="select-mult"
                        value={spUnits}
                        onChange={(e) => {
                          setSPUnits(e);
                        }}
                      >
                        {numberComparer.map((item, index) => {
                          return <Option value={item}>{item}</Option>;
                        })}
                      </Select>

                      <Input
                        className="select-input"
                        value={spUnitsValue}
                        onChange={(e) => {
                          setSPUnitsValue(e.target.value);
                          console.log("spNps", spUnitsValue);
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="select-ctn">
                      <label>SP NPS</label>
                      <Select
                        className="select-mult"
                        value={spNps}
                        onChange={(e) => {
                          setSPNps(e);
                        }}
                      >
                        {numberComparer.map((item, index) => {
                          return <Option value={item}>{item}</Option>;
                        })}
                      </Select>
                      <Input
                        className="select-input"
                        value={spNpsValue}
                        onChange={(e) => {
                          setSPNpsValue(e.target.value);
                          console.log("spNps", spNps);
                        }}
                      />
                    </div>
                  </Col>
                  {props.customerType != "HCP" && (
                    <Col span={8}>
                      <div className="select-ctn">
                        <label>SD Units</label>
                        <Select
                          className="select-mult"
                          value={sdUnits}
                          onChange={(e) => {
                            setSDUnits(e);
                          }}
                        >
                          {numberComparer.map((item, index) => {
                            return <Option value={item}>{item}</Option>;
                          })}
                        </Select>
                        <Input
                          className="select-input"
                          value={sdUnitsValue}
                          onChange={(e) => {
                            setsdUnitsValue(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                  )}
                </Row>

                <Row
                  className="switch-ctn"
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginTop: "15px",
                  }}
                >
                  <Col span={8}>
                    {" "}
                    <label style={{ display: "block" }}>In focus Account</label>
                    {/* <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={infocusAccount}
                                            onChange={(checked) => {
                                                setInfocusAccount(checked);
                                            }}
                                        /> */}
                    <div className="radio-ctn">
                      {dataTags.map((tag) => (
                        <CheckableTag
                          className="radio-btn"
                          key={tag}
                          checked={infocusTags.indexOf(tag) > -1}
                          onChange={(checked) =>
                            handleInfocusTags(tag, checked)
                          }
                        >
                          {tag}
                        </CheckableTag>
                      ))}
                    </div>
                  </Col>
                  <Col span={8}>
                    {" "}
                    <label style={{ display: "block" }}>Reached</label>
                    {/* <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={callReach}
                                            onChange={(checked) => {
                                                setCallReach(checked);
                                            }}
                                        /> */}
                    <div className="radio-ctn">
                      {dataTags.map((tag) => (
                        <CheckableTag
                          className="radio-btn"
                          key={tag}
                          checked={reachedTags.indexOf(tag) > -1}
                          onChange={(checked) =>
                            handlereachedTags(tag, checked)
                          }
                        >
                          {tag}
                        </CheckableTag>
                      ))}
                    </div>
                  </Col>
                </Row>
                <div></div>
              </Col>
            </Row>
            <Row
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingBottom: "20px",
              }}
            >
              <Col span={4}>
                <div className="select-ctn">
                  <label
                    style={{
                      borderBottom: "2px solid #F67765",
                      paddingBottom: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    Cal NPS
                  </label>
                  <Select
                    className="select-mult"
                    value={calNPS}
                    onChange={(e) => {
                      setCalNps(e);
                    }}
                  >
                    {numberComparer.map((item, index) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                  <Input
                    className="select-input"
                    value={calNPSValue}
                    onChange={(e) => {
                      setCalNpsValue(e.target.value);
                      console.log("calNPS", calNPS);
                    }}
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="select-ctn">
                  <label
                    style={{
                      borderBottom: "2px solid #8542A6",
                      paddingBottom: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    Zanu NPS
                  </label>
                  <Select
                    className="select-mult"
                    value={zanuNPS}
                    onChange={(e) => {
                      setZanuNPS(e);
                    }}
                  >
                    {numberComparer.map((item, index) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                  <Input
                    className="select-input"
                    value={zanuNPSValue}
                    onChange={(e) => {
                      setZanuNPSValue(e.target.value);
                      console.log("zanuNPS", zanuNPS);
                    }}
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="select-ctn">
                  <label
                    style={{
                      borderBottom: "2px solid #25B2EC",
                      paddingBottom: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    Ibru NPS
                  </label>
                  <Select
                    className="select-mult"
                    value={ibruNPS}
                    onChange={(e) => {
                      setIbruNPS(e);
                    }}
                  >
                    {numberComparer.map((item, index) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                  <Input
                    className="select-input"
                    value={ibruNPSValue}
                    onChange={(e) => {
                      setIbruNPSValue(e.target.value);
                    }}
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="select-ctn">
                  <label
                    style={{
                      borderBottom: "2px solid #FDCA59",
                      paddingBottom: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    Ven NPS
                  </label>
                  <Select
                    className="select-mult"
                    value={venNPS}
                    onChange={(e) => {
                      setVenNPS(e);
                    }}
                  >
                    {numberComparer.map((item, index) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                  <Input
                    className="select-input"
                    value={venNPSValue}
                    onChange={(e) => {
                      setVenNPSValue(e.target.value);
                      console.log("venNPS", venNPS);
                    }}
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="select-ctn">
                  <label
                    style={{
                      borderBottom: "2px solid #2BBA67",
                      paddingBottom: "6px",
                      marginBottom: "10px",
                    }}
                  >
                    CIT NPS
                  </label>
                  <Select
                    className="select-mult"
                    value={citNPS}
                    onChange={(e) => {
                      setCitNPS(e);
                    }}
                  >
                    {numberComparer.map((item, index) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                  <Input
                    className="select-input"
                    value={citNPSValue}
                    onChange={(e) => {
                      setCitNPSValue(e.target.value);
                      console.log("citNPS", citNPS);
                    }}
                  />
                </div>
              </Col>
              <Col span={4}>
                <div className="select-ctn" style={{ marginTop: "20px" }}>
                  <Switch
                    checkedChildren="Percentage"
                    unCheckedChildren="Percentage"
                    defaultChecked={props.isCustomerTblPercent}
                    onChange={(checked) => {
                      //props.updatePercentage(checked);
                      setIsCustomerTblPercent(checked);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default CustomerFilter;
