export default [
  {
    fip: "00000",
    geoId: "20078394",
    geo: "Indianapolis",
    territoryId: "20111120",
    territory: "Brooklyn NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "00000",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "00688",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "01001",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01003",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01005",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01007",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01009",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01011",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01013",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01015",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01017",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01019",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01021",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01023",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01025",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01025",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01027",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01029",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01031",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01033",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01035",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01037",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01039",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01041",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01043",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01045",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01047",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01049",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01051",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01053",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01055",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01057",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01059",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01061",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01063",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01065",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01067",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01069",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01071",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01073",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01075",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01077",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01079",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01081",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01083",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01085",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01087",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01089",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01091",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01093",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01095",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01097",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01099",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01101",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01103",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01105",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01107",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01109",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01111",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01113",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01115",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01117",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01119",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01121",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01123",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01123",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01125",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01127",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01129",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01131",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01131",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "01133",
    geoId: "20078420",
    geo: "Atlanta",
    territoryId: "20111146",
    territory: "Birmingham AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "02013",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02016",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02020",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02050",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02060",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02068",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02070",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02090",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02100",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02110",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02122",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02130",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02150",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02164",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02170",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02180",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02185",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02188",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02201",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02220",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02232",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02240",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02261",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02270",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02280",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02282",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "02290",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04001",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04003",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04005",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04007",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04007",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04009",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04011",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04012",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04013",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04013",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04015",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04015",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04017",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04019",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04021",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04023",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04025",
    geoId: "20078454",
    geo: "Phoenix",
    territoryId: "20111180",
    territory: "Phoenix N AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "04027",
    geoId: "20078380",
    geo: "Phoenix",
    territoryId: "20111106",
    territory: "Phoenix S AZ 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05001",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05003",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05005",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05007",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05009",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05009",
    geoId: "20078442",
    geo: "Mid South",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05011",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05013",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05015",
    geoId: "20078421",
    geo: "Mid West",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05015",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05017",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05019",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05021",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05023",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05025",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05027",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05029",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05031",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05033",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05035",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05037",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05039",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05041",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05043",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05045",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05047",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05049",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05051",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05053",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05055",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05057",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05059",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05061",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05063",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05065",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05067",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05069",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05071",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05073",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05075",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05077",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05079",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05081",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05083",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05085",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05087",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05089",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05091",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05093",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05095",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05097",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05099",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05101",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05101",
    geoId: "20078442",
    geo: "Mid South",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05103",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05105",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05107",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05109",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05111",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05113",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05115",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05117",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05119",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05121",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05123",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05125",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05127",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05129",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05131",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05133",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05135",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05137",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05139",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05141",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05143",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05145",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05147",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "05149",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06001",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06003",
    geoId: "20078385",
    geo: "Northwest",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06003",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06005",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06005",
    geoId: "20078455",
    geo: "California",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06005",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06007",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06009",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06009",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06011",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06011",
    geoId: "20078462",
    geo: "Northwest",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06013",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06015",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06017",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06019",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06021",
    geoId: "20078455",
    geo: "California",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06021",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06023",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06025",
    geoId: "20078460",
    geo: "Phoenix",
    territoryId: "20111186",
    territory: "San Diego CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06027",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06027",
    geoId: "20078457",
    geo: "California",
    territoryId: "20111183",
    territory: "Los Angeles N CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06029",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06029",
    geoId: "20078457",
    geo: "California",
    territoryId: "20111183",
    territory: "Los Angeles N CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06031",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06033",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06035",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06037",
    geoId: "20078456",
    geo: "California",
    territoryId: "20111182",
    territory: "Los Angeles S CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06037",
    geoId: "20078457",
    geo: "California",
    territoryId: "20111183",
    territory: "Los Angeles N CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06037",
    geoId: "20078458",
    geo: "California",
    territoryId: "20111184",
    territory: "Thousand Oaks CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06037",
    geoId: "20078459",
    geo: "California",
    territoryId: "20111185",
    territory: "Newport Beach CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06039",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06041",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06043",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06045",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06047",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06049",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06051",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06053",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06055",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06057",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06059",
    geoId: "20078456",
    geo: "California",
    territoryId: "20111182",
    territory: "Los Angeles S CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06059",
    geoId: "20078459",
    geo: "California",
    territoryId: "20111185",
    territory: "Newport Beach CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06061",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06063",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06065",
    geoId: "20078457",
    geo: "Phoenix",
    territoryId: "20111183",
    territory: "Los Angeles N CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06065",
    geoId: "20078459",
    geo: "Phoenix",
    territoryId: "20111185",
    territory: "Newport Beach CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06065",
    geoId: "20078460",
    geo: "Phoenix",
    territoryId: "20111186",
    territory: "San Diego CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06067",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06067",
    geoId: "20078462",
    geo: "Northwest",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06069",
    geoId: "20078461",
    geo: "California",
    territoryId: "20111187",
    territory: "San Francisco CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06071",
    geoId: "20078457",
    geo: "California",
    territoryId: "20111183",
    territory: "Los Angeles N CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06071",
    geoId: "20078459",
    geo: "California",
    territoryId: "20111185",
    territory: "Newport Beach CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06073",
    geoId: "20078460",
    geo: "Phoenix",
    territoryId: "20111186",
    territory: "San Diego CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06075",
    geoId: "20078461",
    geo: "California",
    territoryId: "20111187",
    territory: "San Francisco CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06075",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06077",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06079",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06081",
    geoId: "20078461",
    geo: "California",
    territoryId: "20111187",
    territory: "San Francisco CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06083",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06085",
    geoId: "20078461",
    geo: "California",
    territoryId: "20111187",
    territory: "San Francisco CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06087",
    geoId: "20078461",
    geo: "California",
    territoryId: "20111187",
    territory: "San Francisco CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06089",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06091",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06093",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06095",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06097",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06099",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06099",
    geoId: "20078462",
    geo: "California",
    territoryId: "20111188",
    territory: "Berkeley CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06101",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06103",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06105",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06107",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06109",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06111",
    geoId: "20078385",
    geo: "California",
    territoryId: "20111111",
    territory: "Fresno CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06111",
    geoId: "20078458",
    geo: "California",
    territoryId: "20111184",
    territory: "Thousand Oaks CA 1",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06113",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "06115",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08001",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08003",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08005",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08007",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08007",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08009",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08011",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08013",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08014",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08015",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08017",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08019",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08021",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08023",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08025",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08027",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08029",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08031",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08033",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08035",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08037",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08039",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08041",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08043",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08045",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08047",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08049",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08051",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08053",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08055",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08057",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08059",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08061",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08063",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08065",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08067",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08069",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08071",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08073",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08075",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08077",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08079",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08081",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08083",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08085",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08087",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08089",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08091",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08093",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08095",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08097",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08099",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08101",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08103",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08105",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08107",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08109",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08111",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08113",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08115",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08117",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08119",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08121",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08123",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "08125",
    geoId: "20078452",
    geo: "Mid West",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "09001",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09003",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09005",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09007",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09009",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09011",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09013",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "09015",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "10001",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "10003",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "10005",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "11001",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12001",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12003",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12005",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12007",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12009",
    geoId: "20078381",
    geo: "Florida",
    territoryId: "20111107",
    territory: "Orlando FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12011",
    geoId: "20078417",
    geo: "Florida",
    territoryId: "20111143",
    territory: "Miami FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12011",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12013",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12015",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12017",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12019",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12021",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12023",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12027",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12029",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12031",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12033",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12035",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12037",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12039",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12041",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12043",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12045",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12047",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12049",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12051",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12053",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12055",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12057",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12057",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12059",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12061",
    geoId: "20078381",
    geo: "Florida",
    territoryId: "20111107",
    territory: "Orlando FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12063",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12065",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12067",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12069",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12071",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12073",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12075",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12075",
    geoId: "20078416",
    geo: "Atlanta",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12077",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12077",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12079",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12081",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12083",
    geoId: "20078415",
    geo: "Florida",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12083",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12085",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12086",
    geoId: "20078417",
    geo: "Florida",
    territoryId: "20111143",
    territory: "Miami FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12086",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12087",
    geoId: "20078417",
    geo: "Florida",
    territoryId: "20111143",
    territory: "Miami FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12089",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12091",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12093",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12095",
    geoId: "20078381",
    geo: "Florida",
    territoryId: "20111107",
    territory: "Orlando FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12095",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12097",
    geoId: "20078381",
    geo: "Florida",
    territoryId: "20111107",
    territory: "Orlando FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12099",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12101",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12101",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12103",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12105",
    geoId: "20078381",
    geo: "Florida",
    territoryId: "20111107",
    territory: "Orlando FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12105",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12107",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12109",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12111",
    geoId: "20078418",
    geo: "Florida",
    territoryId: "20111144",
    territory: "Fort Lauderdale FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12113",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12115",
    geoId: "20078419",
    geo: "Florida",
    territoryId: "20111145",
    territory: "Tampa FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12117",
    geoId: "20078381",
    geo: "Florida",
    territoryId: "20111107",
    territory: "Orlando FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12117",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12119",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12121",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12123",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12125",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12127",
    geoId: "20078416",
    geo: "Florida",
    territoryId: "20111142",
    territory: "St. Petersburg FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12129",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12131",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "12133",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13001",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13003",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13005",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13007",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13009",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13011",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13011",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13013",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13015",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13017",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13019",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13021",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13023",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13025",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13027",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13029",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13031",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13033",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13035",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13037",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13039",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13043",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13045",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13047",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13049",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13051",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13053",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13055",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13057",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13059",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13061",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13061",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13063",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13063",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13065",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13065",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13067",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13069",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13071",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13073",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13075",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13077",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13079",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13081",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13083",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13085",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13087",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13089",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13089",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13091",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13093",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13095",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13097",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13097",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13099",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13099",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13101",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13103",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13105",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13107",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13109",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13111",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13113",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13115",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13117",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13119",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13121",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13121",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13123",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13125",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13127",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13129",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13131",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13133",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13135",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13135",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13137",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13139",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13139",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13141",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13143",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13145",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13145",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13147",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13149",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13151",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13153",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13155",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13157",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13159",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13161",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13163",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13165",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13167",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13169",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13171",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13173",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13173",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13175",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13177",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13179",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13181",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13183",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13185",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13185",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13187",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13189",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13191",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13193",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13195",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13197",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13199",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13201",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13205",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13207",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13209",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13211",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13213",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13215",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13217",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13219",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13221",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13223",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13225",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13227",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13229",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13229",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13231",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13233",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13235",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13237",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13239",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13241",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13243",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13245",
    geoId: "20078410",
    geo: "Atlanta",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13245",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13247",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13249",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13251",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13253",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13253",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13255",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13257",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13257",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13259",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13261",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13263",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13265",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13267",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13269",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13271",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13273",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13275",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13277",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13279",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13281",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13283",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13285",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13287",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13289",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13291",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13293",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13295",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13297",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13299",
    geoId: "20078415",
    geo: "Atlanta",
    territoryId: "20111141",
    territory: "Jacksonville FL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13301",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13303",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13305",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13307",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13309",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13311",
    geoId: "20078412",
    geo: "Atlanta",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13313",
    geoId: "20078412",
    geo: "Nashville",
    territoryId: "20111138",
    territory: "Atlanta N GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13313",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13315",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13317",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13319",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "13321",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "16001",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16003",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16005",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16007",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16009",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16011",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16013",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16015",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16017",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16019",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16021",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16023",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16025",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16027",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16029",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16031",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16033",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16035",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16035",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16037",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16039",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16041",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16043",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16045",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16047",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16049",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16051",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16053",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16055",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16057",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16059",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16061",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16061",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16063",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16065",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16067",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16069",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16071",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16073",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16075",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16077",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16079",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16081",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16083",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16085",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "16087",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17001",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17003",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17005",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17007",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17009",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17011",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17011",
    geoId: "20078438",
    geo: "Minneapolis",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17011",
    geoId: "20078441",
    geo: "Minneapolis",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17013",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17015",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17017",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17019",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17021",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17023",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17025",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17027",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17029",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17031",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17031",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17031",
    geoId: "20078439",
    geo: "Chicago",
    territoryId: "20111165",
    territory: "Evanston IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17033",
    geoId: "20078426",
    geo: "Chicago",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17033",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17035",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17037",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17039",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17041",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17043",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17043",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17045",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17047",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17049",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17051",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17051",
    geoId: "20078441",
    geo: "Mid South",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17053",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17055",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17057",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17059",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17061",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17063",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17063",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17065",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17067",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17069",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "17071",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17073",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17075",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17077",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17079",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17081",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17083",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17085",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17087",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17089",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17091",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17093",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17095",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17097",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17097",
    geoId: "20078439",
    geo: "Chicago",
    territoryId: "20111165",
    territory: "Evanston IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17099",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17099",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17101",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17103",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17105",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17107",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17109",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17111",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17113",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17115",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17117",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17119",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17121",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17123",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17125",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17127",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17129",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17131",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17133",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17135",
    geoId: "20078426",
    geo: "Chicago",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17135",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17137",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17139",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17141",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17143",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17145",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17147",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17149",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17151",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17153",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17155",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17157",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17159",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17159",
    geoId: "20078441",
    geo: "Mid South",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17161",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17163",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17165",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17167",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17169",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17171",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17173",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17175",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17177",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17179",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17181",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17183",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17185",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17187",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17189",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17191",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17193",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17195",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17197",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17197",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17197",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17199",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17201",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "17203",
    geoId: "20078441",
    geo: "Chicago",
    territoryId: "20111167",
    territory: "Springfield IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18001",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18003",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18005",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18007",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18009",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18011",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18013",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18015",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18017",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18019",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18021",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18023",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18025",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18027",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18029",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18031",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18033",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18035",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18037",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18039",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18041",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18043",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18045",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18047",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18049",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18049",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18051",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18053",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18055",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18055",
    geoId: "20078429",
    geo: "Nashville",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18057",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18059",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18061",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18063",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18065",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18067",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18069",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18071",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18073",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18075",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18077",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18079",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18081",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18083",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18085",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18087",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18089",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18091",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18093",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18095",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18097",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18099",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18101",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18103",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18105",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18107",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18109",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18111",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18113",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18115",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18117",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18119",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18121",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18123",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18125",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18127",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18129",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18131",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18133",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18135",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18137",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18139",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18141",
    geoId: "20078430",
    geo: "Chicago",
    territoryId: "20111156",
    territory: "Chicago IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "18141",
    geoId: "20078431",
    geo: "Chicago",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18143",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18145",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18147",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18149",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18151",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18153",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18155",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18155",
    geoId: "20078425",
    geo: "Nashville",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18157",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18159",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18161",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18163",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18165",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18167",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18169",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18171",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18173",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18175",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18177",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18179",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18181",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "18183",
    geoId: "20078429",
    geo: "Indianapolis",
    territoryId: "20111155",
    territory: "Indianapolis IN 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "19001",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19003",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19005",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19007",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19009",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19011",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19013",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19015",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19017",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19019",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19021",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19023",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19025",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19027",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19029",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19031",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19033",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19035",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19037",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19039",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19041",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19043",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19045",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19047",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19047",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19049",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19051",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19053",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19055",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19057",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19059",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19061",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19063",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19063",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19065",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19067",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19069",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19071",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19073",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19075",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19077",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19079",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19081",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19083",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19085",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19087",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19089",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19091",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19093",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19093",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19095",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19097",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19099",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19101",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19103",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19105",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19107",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19109",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19111",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19113",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19115",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19117",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19119",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19121",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19123",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19125",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19127",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19129",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19131",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19133",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19135",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19137",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19137",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19139",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19141",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19143",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19145",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19145",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19147",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19147",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19149",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19151",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19153",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19155",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19157",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19159",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19161",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19161",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19163",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19165",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19165",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19167",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19169",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19171",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19173",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19175",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19177",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19179",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19181",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19183",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19185",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19187",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19189",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19191",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19193",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19195",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "19197",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20001",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20001",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20003",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20005",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20007",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20007",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20009",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20009",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20011",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20011",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20013",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20015",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20015",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20017",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20017",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20019",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20019",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20021",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20023",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20023",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20025",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20025",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20027",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20027",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20027",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20029",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20029",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20029",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20031",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20031",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20031",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20033",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20033",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20035",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20035",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20037",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20037",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20039",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20039",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20041",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20041",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20041",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20043",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20045",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20047",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20047",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20049",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20049",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20051",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20051",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20053",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20053",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20055",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20055",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20057",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20057",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20059",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20061",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20063",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20063",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20065",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20065",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20067",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20067",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20069",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20069",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20071",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20071",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20073",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20073",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20075",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20075",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20077",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20077",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20079",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20079",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20081",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20081",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20083",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20083",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20085",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20087",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20089",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20089",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20091",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20093",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20093",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20095",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20095",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20097",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20097",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20099",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20099",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20101",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20101",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20103",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20105",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20105",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20107",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20109",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20109",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20111",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20113",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20113",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20115",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20115",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20117",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20119",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20119",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20121",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20123",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20123",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20125",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20125",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20127",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20127",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20129",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20129",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20131",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20133",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20133",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20135",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20135",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20137",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20137",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20139",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20141",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20141",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20143",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20143",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20145",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20145",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20147",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20147",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20149",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20151",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20151",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20153",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20153",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20155",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20155",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20157",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20157",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20157",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20159",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20159",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20161",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20163",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20163",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20165",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20165",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20167",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20167",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20169",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20169",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20171",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20171",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20173",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20173",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20175",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20175",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20177",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20179",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20179",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20181",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20181",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20183",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20183",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20185",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20185",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20187",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20187",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20189",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20189",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20191",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20191",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20193",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20193",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20195",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20195",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20197",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20199",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20199",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20201",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20203",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20203",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20205",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20205",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20207",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20207",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "20209",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21001",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21003",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21005",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21007",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21009",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21011",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21013",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21015",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21017",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21019",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21021",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21023",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21025",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21027",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21029",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21031",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21033",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21035",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21037",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21039",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21041",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21041",
    geoId: "20078425",
    geo: "Nashville",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21043",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21045",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21047",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21049",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21051",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21053",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21055",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21057",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21059",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21061",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21063",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21065",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21067",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21069",
    geoId: "20078422",
    geo: "Ohio Valley",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21069",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21071",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21073",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21075",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21077",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21079",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21081",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21083",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21085",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21087",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21089",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21091",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21093",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21095",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21097",
    geoId: "20078422",
    geo: "Ohio Valley",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21097",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21099",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21101",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21103",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21105",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21107",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21109",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21111",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21113",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21115",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21117",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21119",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21121",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21123",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21125",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21127",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21129",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21131",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21133",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21135",
    geoId: "20078408",
    geo: "Nashville",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21135",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21135",
    geoId: "20078425",
    geo: "Nashville",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21137",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21139",
    geoId: "20078424",
    geo: "Mid South",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21139",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21141",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21143",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21145",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21147",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21147",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21149",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21151",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21153",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21155",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21157",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "21159",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21161",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21163",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21165",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21167",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21169",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21171",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21173",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21175",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21177",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21179",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21181",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21183",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21185",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21187",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21187",
    geoId: "20078425",
    geo: "Nashville",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21189",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21191",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21193",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21195",
    geoId: "20078408",
    geo: "Nashville",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21195",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21197",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21199",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21199",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21201",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21203",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21205",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21207",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21209",
    geoId: "20078422",
    geo: "Ohio Valley",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21209",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21211",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21213",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21215",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21217",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21219",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21219",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21221",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21223",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21225",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21227",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21229",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21231",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21233",
    geoId: "20078424",
    geo: "Nashville",
    territoryId: "20111150",
    territory: "Louisville KY 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21235",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21237",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "21239",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "22001",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22003",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22005",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22005",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22007",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22009",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22011",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22013",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22015",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22017",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22019",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22021",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22023",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22025",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22027",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22029",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22031",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22033",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22035",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22037",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22039",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22041",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22043",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22045",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22047",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22049",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22051",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22053",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22055",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22057",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22059",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22061",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22063",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22063",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22065",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22067",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22069",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22071",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22073",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22075",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22077",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22079",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22081",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22083",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22085",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22087",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22089",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22091",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22093",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22093",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22095",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22097",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22099",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22101",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22103",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22105",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22107",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22109",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22109",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22111",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22113",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22115",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22117",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22119",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22121",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22123",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22125",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "22127",
    geoId: "20078445",
    geo: "Mid South",
    territoryId: "20111171",
    territory: "Baton Rouge LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "23001",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23003",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23005",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23007",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23009",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23011",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23013",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23015",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23017",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23019",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23021",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23023",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23025",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23027",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23029",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "23031",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24001",
    geoId: "20078399",
    geo: "Washington DC",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24001",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24001",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24003",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24003",
    geoId: "20078404",
    geo: "Washington DC",
    territoryId: "20111130",
    territory: "Baltimore E MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24005",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24005",
    geoId: "20078404",
    geo: "Washington DC",
    territoryId: "20111130",
    territory: "Baltimore E MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24009",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24011",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24013",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24015",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24017",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24019",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24021",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24021",
    geoId: "20078402",
    geo: "Washington DC",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24023",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24025",
    geoId: "20078404",
    geo: "Washington DC",
    territoryId: "20111130",
    territory: "Baltimore E MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24027",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24029",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24031",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24031",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24033",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24033",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24033",
    geoId: "20078404",
    geo: "Washington DC",
    territoryId: "20111130",
    territory: "Baltimore E MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24035",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24037",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24039",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24041",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24043",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24045",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24047",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24510",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "24510",
    geoId: "20078404",
    geo: "Washington DC",
    territoryId: "20111130",
    territory: "Baltimore E MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25001",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25003",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25003",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25005",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25007",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25009",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25011",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25011",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25013",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25013",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25015",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25015",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25017",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25017",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25019",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25021",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25023",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25025",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25027",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25027",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "25027",
    geoId: "20078389",
    geo: "New England",
    territoryId: "20111115",
    territory: "Hartford CT 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26001",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26003",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26005",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26007",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26009",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26011",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26013",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26015",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26017",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26019",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26021",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26023",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26025",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26027",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26029",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26031",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26033",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26035",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26035",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26037",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26039",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26041",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26043",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26045",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26047",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26049",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26051",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26051",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26053",
    geoId: "20078384",
    geo: "Indianapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "26053",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26055",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26057",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26059",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26059",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26061",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26063",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26065",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26067",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26069",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26069",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26071",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "26071",
    geoId: "20078431",
    geo: "Minneapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26073",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26075",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26077",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26079",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26081",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26083",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26085",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26087",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26089",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26091",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26093",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26093",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26095",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26097",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26099",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26099",
    geoId: "20078433",
    geo: "Indianapolis",
    territoryId: "20111159",
    territory: "Detroit MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26101",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26103",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26105",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26107",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26109",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "26111",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26113",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26115",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26117",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26119",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26121",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26123",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26125",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26125",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26125",
    geoId: "20078433",
    geo: "Indianapolis",
    territoryId: "20111159",
    territory: "Detroit MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26127",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26129",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26129",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26131",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26133",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26135",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26137",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26139",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26141",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26143",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26145",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26147",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26149",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26151",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26153",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26155",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26157",
    geoId: "20078432",
    geo: "Indianapolis",
    territoryId: "20111158",
    territory: "Sterling Heights MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26159",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26161",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26163",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26163",
    geoId: "20078433",
    geo: "Indianapolis",
    territoryId: "20111159",
    territory: "Detroit MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "26165",
    geoId: "20078431",
    geo: "Indianapolis",
    territoryId: "20111157",
    territory: "Grand Rapids MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "27001",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27003",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27005",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27007",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27009",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27011",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27013",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27015",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27017",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27019",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27019",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27021",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27023",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27023",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27025",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27027",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27029",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27031",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27033",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27033",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27035",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27037",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27039",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27041",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27043",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27045",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27047",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27049",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27051",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27053",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27053",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27055",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27055",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27057",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27059",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27061",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27063",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27065",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27067",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27069",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27071",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27073",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27075",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27077",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27079",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27081",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27083",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27085",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27085",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27087",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27089",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27091",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27091",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27093",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27095",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27097",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27099",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27101",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27103",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27105",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27107",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27109",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27111",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27113",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27115",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27117",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27119",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27121",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27123",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27123",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27125",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27127",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27127",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27129",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27129",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27131",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27133",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27135",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27137",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27139",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27141",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27143",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27145",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27147",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27149",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27149",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27151",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27151",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27153",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27155",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27155",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27157",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27159",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27161",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27163",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27163",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27165",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27167",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27169",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27171",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "27173",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28001",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28003",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28005",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28007",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28009",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28011",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28013",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28015",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28017",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28017",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28019",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28021",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28023",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28025",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28027",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28027",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28029",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28031",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28033",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28035",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28037",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28039",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "28041",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "28041",
    geoId: "20078423",
    geo: "Atlanta",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28043",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28045",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "28047",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "28049",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28051",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28053",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28055",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28057",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28059",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "28061",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28063",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28065",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28067",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28069",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28071",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28073",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28075",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28077",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28079",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28081",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28083",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28085",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28087",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28089",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28091",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28093",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28095",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28095",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28097",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28099",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28101",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28103",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28105",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28107",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28109",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28111",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28113",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28115",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28117",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28119",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28121",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28123",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28125",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28127",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28129",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28131",
    geoId: "20078414",
    geo: "Atlanta",
    territoryId: "20111140",
    territory: "Mobile AL 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "28133",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28135",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28135",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28137",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28139",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28141",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28143",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28143",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28145",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28147",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28149",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28151",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28153",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28155",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28157",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28159",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28161",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28161",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "28163",
    geoId: "20078423",
    geo: "Mid South",
    territoryId: "20111149",
    territory: "New Orleans LA 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29001",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29003",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29005",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29007",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29009",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29011",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29013",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29015",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29017",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29019",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29021",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29023",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29025",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29027",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29029",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29031",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29033",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29033",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29035",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29037",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29039",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29041",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29043",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29045",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29047",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29049",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29051",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29053",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29055",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29057",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29059",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29061",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29063",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29065",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29067",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29069",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29071",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29073",
    geoId: "20078426",
    geo: "Mid West",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29073",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29075",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29077",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29079",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29081",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29083",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29085",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29087",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29089",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29091",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29093",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29095",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29097",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29099",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29101",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29103",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29105",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29107",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29107",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29109",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29111",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29113",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29115",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29117",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29117",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29119",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29121",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29123",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29125",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29127",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29129",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29131",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29133",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29135",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29137",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29139",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29141",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29143",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29145",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29147",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29149",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29151",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29153",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29155",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29157",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29159",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29161",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29163",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29165",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29167",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29169",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29171",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29173",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29175",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29177",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29179",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29181",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29183",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29185",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29185",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29186",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29187",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29189",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29195",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29197",
    geoId: "20078435",
    geo: "Minneapolis",
    territoryId: "20111161",
    territory: "Des Moines IA 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29197",
    geoId: "20078442",
    geo: "Minneapolis",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29199",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29201",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29203",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29205",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29207",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29209",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29211",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29213",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29215",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29217",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29217",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29219",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29221",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29223",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29225",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29227",
    geoId: "20078443",
    geo: "Mid West",
    territoryId: "20111169",
    territory: "Kansas City MO-KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29229",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "29510",
    geoId: "20078426",
    geo: "Mid South",
    territoryId: "20111152",
    territory: "St Louis MO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30001",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30003",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30005",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30007",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30009",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30011",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30013",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30015",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30017",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30019",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30021",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30023",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30025",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30027",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30029",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30031",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30033",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30035",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30037",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30039",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30041",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30043",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30045",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30047",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30049",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30051",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30053",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30055",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30057",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30059",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30061",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30063",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30065",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30067",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30069",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30071",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30073",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30075",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30077",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30079",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30081",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30083",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30085",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30087",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30089",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30091",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30093",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30095",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30097",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30099",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30101",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30103",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30105",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30107",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30109",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "30111",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31001",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31003",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31005",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31007",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31009",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31011",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31013",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31015",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31017",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31019",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31021",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31023",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31025",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31027",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31029",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31031",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31033",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31035",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31037",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31039",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31041",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31043",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31045",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31047",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31049",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31051",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31053",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31055",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31057",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31059",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31061",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31063",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31065",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31067",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31069",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31071",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31073",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31075",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31077",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31079",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31081",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31083",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31085",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31087",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31089",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31091",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31093",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31095",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31097",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31099",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31101",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31103",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31105",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31107",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31109",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31111",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31113",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31115",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31117",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31119",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31121",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31123",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31125",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31127",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31129",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31131",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31133",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31135",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31137",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31139",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31141",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31143",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31145",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31147",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31149",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31151",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31153",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31155",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31157",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31159",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31161",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31163",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31165",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31167",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31169",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31171",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31173",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31175",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31177",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31179",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31181",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31183",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "31185",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32001",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32003",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32005",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32007",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32009",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32011",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32013",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32015",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32017",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32019",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32021",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32023",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32027",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32029",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32031",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32033",
    geoId: "20078383",
    geo: "Northwest",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32033",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "32510",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "33001",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33003",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33005",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33007",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33009",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33011",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33013",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33015",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33017",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "33019",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34001",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34003",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34005",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34007",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34009",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34011",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34013",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34015",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34017",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34019",
    geoId: "20078390",
    geo: "Philadelphia",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34019",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34021",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34021",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34023",
    geoId: "20078390",
    geo: "New York",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34023",
    geoId: "20078392",
    geo: "New York",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34025",
    geoId: "20078390",
    geo: "New York",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34025",
    geoId: "20078393",
    geo: "New York",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34027",
    geoId: "20078390",
    geo: "New York",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34027",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34029",
    geoId: "20078390",
    geo: "Philadelphia",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34029",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34031",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34033",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34035",
    geoId: "20078390",
    geo: "Philadelphia",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34035",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34037",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34039",
    geoId: "20078390",
    geo: "New York",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34039",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34041",
    geoId: "20078390",
    geo: "New York",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "34041",
    geoId: "20078392",
    geo: "New York",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "35001",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35003",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35005",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35006",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35007",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35009",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35011",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35013",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35015",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35017",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35019",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35021",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35023",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35025",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35027",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35028",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35029",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35031",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35033",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35035",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35037",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35039",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35041",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35043",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35045",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35047",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35049",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35051",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35053",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35055",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35057",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35059",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "35061",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "36001",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36003",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36005",
    geoId: "20078395",
    geo: "New York",
    territoryId: "20111121",
    territory: "New York NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36005",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36007",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36007",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36009",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36011",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36013",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36015",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36017",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36019",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36021",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36023",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36025",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36027",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36027",
    geoId: "20078397",
    geo: "New York",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36029",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36031",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36033",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36035",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36037",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36039",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36041",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36043",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36045",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36047",
    geoId: "20078394",
    geo: "New York",
    territoryId: "20111120",
    territory: "Brooklyn NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36049",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36051",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36053",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36055",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36057",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36059",
    geoId: "20078386",
    geo: "New York",
    territoryId: "20111112",
    territory: "Suffolk NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36059",
    geoId: "20078394",
    geo: "New York",
    territoryId: "20111120",
    territory: "Brooklyn NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36061",
    geoId: "20078394",
    geo: "New York",
    territoryId: "20111120",
    territory: "Brooklyn NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36061",
    geoId: "20078395",
    geo: "New York",
    territoryId: "20111121",
    territory: "New York NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36061",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36063",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36065",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36067",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36067",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36069",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36071",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36073",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36075",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36075",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36077",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36079",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36081",
    geoId: "20078394",
    geo: "New York",
    territoryId: "20111120",
    territory: "Brooklyn NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36083",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36085",
    geoId: "20078390",
    geo: "New York",
    territoryId: "20111116",
    territory: "Edison NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36087",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36089",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36091",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36093",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36095",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36097",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36099",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36101",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36103",
    geoId: "20078386",
    geo: "New York",
    territoryId: "20111112",
    territory: "Suffolk NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36105",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36105",
    geoId: "20078397",
    geo: "New York",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36107",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36109",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36111",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36111",
    geoId: "20078397",
    geo: "New York",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36113",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36115",
    geoId: "20078397",
    geo: "New England",
    territoryId: "20111123",
    territory: "Albany NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36117",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36119",
    geoId: "20078396",
    geo: "New York",
    territoryId: "20111122",
    territory: "White Plains NY 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36121",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "36123",
    geoId: "20078398",
    geo: "New England",
    territoryId: "20111124",
    territory: "Buffalo NY 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37001",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37003",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37005",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37007",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37009",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37011",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37013",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37015",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37017",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37019",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37021",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37023",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37025",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37027",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37029",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37031",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37033",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37033",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37035",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37035",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37037",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37037",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37039",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37041",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37043",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37045",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37045",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37047",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37049",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37051",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37051",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37053",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37055",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37057",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37059",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37059",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37061",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37063",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37065",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37067",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37069",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37071",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37073",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37075",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37077",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37079",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37081",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37083",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37085",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37085",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37087",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37089",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37091",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37093",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37095",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37097",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37097",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37099",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37101",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37103",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37105",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37105",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37107",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37107",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37109",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37109",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37111",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37113",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37115",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37117",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37119",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37121",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37123",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37123",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37125",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37127",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37129",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37131",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37133",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37135",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37137",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37139",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37141",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37143",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37145",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37147",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37149",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37151",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37153",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37155",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37157",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37159",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37159",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37161",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37161",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37163",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37165",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37167",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37169",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37171",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37173",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37175",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37177",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37179",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37181",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37183",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37185",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37187",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37189",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37191",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37191",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37193",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37195",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37197",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "37199",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "38001",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38003",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38005",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38007",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38009",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38011",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38013",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38015",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38017",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38019",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38021",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38023",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38025",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38027",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38029",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38031",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38033",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38035",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38037",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38039",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38041",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38043",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38045",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38047",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38049",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38051",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38053",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38055",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38057",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38059",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38061",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38063",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38065",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38067",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38069",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38071",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38073",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38075",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38077",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38079",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38081",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38083",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38085",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38087",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38089",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38091",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38093",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38095",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38097",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38099",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38101",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38103",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "38105",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "39001",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39001",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39003",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39005",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39007",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39009",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39011",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39013",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39015",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39017",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39019",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39019",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39021",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39021",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39023",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39025",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39027",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39029",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39031",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39031",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39033",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39035",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39037",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39039",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39041",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39043",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39043",
    geoId: "20078428",
    geo: "Indianapolis",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39045",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39047",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39049",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39051",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39053",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39055",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39057",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39059",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39061",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39063",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39065",
    geoId: "20078408",
    geo: "Indianapolis",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39065",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39067",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39067",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39069",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39071",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39073",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39075",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39075",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39077",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39077",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39079",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39081",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39081",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39083",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39085",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39087",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39089",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39091",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39093",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39095",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39097",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39099",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39101",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39103",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39105",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39107",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39109",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39111",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39111",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39113",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39115",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39117",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39119",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39121",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39123",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39123",
    geoId: "20078428",
    geo: "Indianapolis",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39125",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39127",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39129",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39131",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39131",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39133",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39133",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39135",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39137",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39139",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39141",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39141",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39143",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39143",
    geoId: "20078428",
    geo: "Indianapolis",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39145",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39147",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39147",
    geoId: "20078427",
    geo: "Ohio Valley",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39147",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39149",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39151",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39153",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39155",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39155",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39157",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39157",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39159",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39161",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39163",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39165",
    geoId: "20078425",
    geo: "Ohio Valley",
    territoryId: "20111151",
    territory: "Cincinnati OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39167",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39169",
    geoId: "20078428",
    geo: "Ohio Valley",
    territoryId: "20111154",
    territory: "Cleveland OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39171",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39173",
    geoId: "20078427",
    geo: "Indianapolis",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39175",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "39175",
    geoId: "20078427",
    geo: "Ohio Valley",
    territoryId: "20111153",
    territory: "Ann Arbor MI 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "40001",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40001",
    geoId: "20078442",
    geo: "Mid South",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40001",
    geoId: "20078444",
    geo: "Mid South",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40003",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40005",
    geoId: "20078444",
    geo: "Texas",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40005",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40007",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40009",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40011",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40013",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40015",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40017",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40019",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40021",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40021",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40023",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40025",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40027",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40029",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40031",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40033",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40035",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40035",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40037",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40037",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40039",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40041",
    geoId: "20078421",
    geo: "Mid West",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40041",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40041",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40043",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40045",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40047",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40049",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40051",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40053",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40055",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40057",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40059",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40061",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40061",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40063",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40063",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40065",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40067",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40069",
    geoId: "20078444",
    geo: "Texas",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40069",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40071",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40071",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40073",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40075",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40077",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40077",
    geoId: "20078444",
    geo: "Mid South",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40079",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40081",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40083",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40085",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40087",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40089",
    geoId: "20078421",
    geo: "Mid West",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40089",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40091",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40091",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40093",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40095",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40097",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40097",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40099",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40101",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40101",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40103",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40103",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40105",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40105",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40107",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40107",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40109",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40111",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40111",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40113",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40113",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40115",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40115",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40117",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40117",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40119",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40119",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40121",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40123",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40125",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40127",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40129",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40131",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40131",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40133",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40133",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40135",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40137",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40139",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40141",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40143",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40143",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40145",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40145",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40147",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40147",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40149",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40151",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "40153",
    geoId: "20078444",
    geo: "Mid West",
    territoryId: "20111170",
    territory: "Oklahoma City OK 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41001",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41001",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41003",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41005",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41007",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41009",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41011",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41013",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41015",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41017",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41019",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41021",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41023",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41025",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41025",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41025",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41027",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41029",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41031",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41033",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41035",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41037",
    geoId: "20078455",
    geo: "Northwest",
    territoryId: "20111181",
    territory: "Sacramento CA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41037",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41039",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41041",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41043",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41045",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41047",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41049",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41049",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41051",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41053",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41055",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41057",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41059",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41059",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41061",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41061",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41063",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41063",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41065",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41067",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41069",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "41071",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "42001",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42003",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42005",
    geoId: "20078399",
    geo: "Philadelphia",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42005",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42007",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42009",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42009",
    geoId: "20078400",
    geo: "Ohio Valley",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42011",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42011",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42011",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42013",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42015",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42017",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42017",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42019",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42021",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42021",
    geoId: "20078400",
    geo: "Ohio Valley",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42023",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42025",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42027",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42029",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42031",
    geoId: "20078399",
    geo: "Philadelphia",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42031",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42033",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42035",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42037",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42037",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42039",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42041",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42043",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42045",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42045",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42047",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42049",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42051",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42053",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42053",
    geoId: "20078400",
    geo: "Ohio Valley",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42055",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42057",
    geoId: "20078399",
    geo: "Washington DC",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42057",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42059",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42061",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42063",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42065",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42067",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42069",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42071",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42073",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42075",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42077",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42079",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42081",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42083",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42085",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42087",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42089",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42091",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42091",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42091",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42093",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42095",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42097",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42097",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42099",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42101",
    geoId: "20078393",
    geo: "Philadelphia",
    territoryId: "20111119",
    territory: "Philadelphia E PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42101",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42103",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42105",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42107",
    geoId: "20078392",
    geo: "Philadelphia",
    territoryId: "20111118",
    territory: "Allentown PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42107",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42109",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42111",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42113",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42115",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42117",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42119",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42121",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42123",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42125",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42127",
    geoId: "20078391",
    geo: "New York",
    territoryId: "20111117",
    territory: "Paterson NJ 1",
    districtId: "20111194",
    district: "New York",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42127",
    geoId: "20078400",
    geo: "New York",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42129",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42131",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "42133",
    geoId: "20078400",
    geo: "Philadelphia",
    territoryId: "20111126",
    territory: "Scranton PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "44001",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "44003",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "44005",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "44007",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "44009",
    geoId: "20078388",
    geo: "New England",
    territoryId: "20111114",
    territory: "Boston MA 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45001",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45003",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45005",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45007",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45009",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45011",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45013",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45015",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45017",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45019",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45021",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45023",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45025",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45027",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45029",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45031",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45033",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45035",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45037",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45039",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45041",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45043",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45045",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45047",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45049",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45049",
    geoId: "20078411",
    geo: "Charlotte",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45051",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45053",
    geoId: "20078411",
    geo: "Atlanta",
    territoryId: "20111137",
    territory: "Atlanta S GA 1",
    districtId: "20111191",
    district: "Southeast",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45055",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45057",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45057",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45059",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45061",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45063",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45065",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45067",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45069",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45071",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45073",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45075",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45077",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45079",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45081",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45083",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45085",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45087",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45089",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45089",
    geoId: "20078410",
    geo: "Charlotte",
    territoryId: "20111136",
    territory: "Charleston SC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "45091",
    geoId: "20078409",
    geo: "Charlotte",
    territoryId: "20111135",
    territory: "Charlotte NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "46003",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46005",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46007",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46009",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46011",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46013",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46015",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46017",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46019",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46021",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46023",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46025",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46027",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46029",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46031",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46033",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46035",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46037",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46039",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46041",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46043",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46045",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46047",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46049",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46051",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46053",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46055",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46057",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46059",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46061",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46063",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46063",
    geoId: "20078436",
    geo: "Northwest",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46065",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46067",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46069",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46071",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46073",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46075",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46077",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46079",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46081",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46083",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46085",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46087",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46089",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46091",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46093",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46095",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46097",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46099",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46101",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46103",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46105",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46107",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46109",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46111",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46113",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46115",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46117",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46119",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46121",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46123",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46125",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46127",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46129",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46135",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46137",
    geoId: "20078436",
    geo: "Minneapolis",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47001",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47003",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47005",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47007",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47009",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47011",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47013",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47015",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47017",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47019",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47021",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47023",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47025",
    geoId: "20078407",
    geo: "Nashville",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47025",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47027",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47029",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47031",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47033",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47035",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47037",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47039",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47039",
    geoId: "20078421",
    geo: "Nashville",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47041",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47043",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47045",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47047",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47049",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47051",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47053",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47055",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47057",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47059",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47061",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47063",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47065",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47067",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47069",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47069",
    geoId: "20078421",
    geo: "Nashville",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47071",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47073",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47075",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47077",
    geoId: "20078413",
    geo: "Mid South",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47077",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47079",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47081",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47083",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47085",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47085",
    geoId: "20078421",
    geo: "Nashville",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47087",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47089",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47091",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47093",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47095",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47097",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47099",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47101",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47103",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47105",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47107",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47109",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47109",
    geoId: "20078421",
    geo: "Nashville",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47111",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47113",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47115",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47117",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47119",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47121",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47121",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47123",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47125",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47127",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47129",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47131",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47133",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47135",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47137",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47139",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47141",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47143",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47143",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47145",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47147",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47149",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47151",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47153",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47155",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47157",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47159",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47161",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47163",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47165",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47167",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47169",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47171",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47173",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47175",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47177",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47179",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47181",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47183",
    geoId: "20078421",
    geo: "Mid South",
    territoryId: "20111147",
    territory: "Memphis TN 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "47185",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47185",
    geoId: "20078422",
    geo: "Nashville",
    territoryId: "20111148",
    territory: "Knoxville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47187",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "47189",
    geoId: "20078413",
    geo: "Nashville",
    territoryId: "20111139",
    territory: "Nashville TN 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "48001",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48003",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48005",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48007",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48009",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48011",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48013",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48015",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48017",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48019",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48021",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48023",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48025",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48027",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48029",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48031",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48033",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48035",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48037",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48039",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48039",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48041",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48043",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48045",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48047",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48049",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48051",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48053",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48055",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48057",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48059",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48061",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48063",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48065",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48067",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48069",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48071",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48073",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48075",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48077",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48079",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48081",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48083",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48085",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48085",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48087",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48089",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48091",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48093",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48095",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48097",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48099",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48099",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48101",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48103",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48105",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48107",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48109",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48111",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48113",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48113",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48115",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48117",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48119",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48121",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48123",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48123",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48125",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48127",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48127",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48129",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48131",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48133",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48135",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48137",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48139",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48139",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48141",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48143",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48145",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48147",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48149",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48149",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48151",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48153",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48155",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48157",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48157",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48159",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48161",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48161",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48163",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48165",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48167",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48167",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48169",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48171",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48173",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48175",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48177",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48179",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48181",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48181",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48183",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48185",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48185",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48187",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48189",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48191",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48193",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48195",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48197",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48199",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48201",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48201",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48203",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48205",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48207",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48209",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48211",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48213",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48215",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48217",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48217",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48219",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48221",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48223",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48225",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48227",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48229",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48231",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48233",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48235",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48237",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48239",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48241",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48241",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48243",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48245",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48247",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48249",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48251",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48253",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48255",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48255",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48257",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48259",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48261",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48263",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48265",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48267",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48269",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48269",
    geoId: "20078449",
    geo: "Texas",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48271",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48273",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48275",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48277",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48279",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48281",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48283",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48285",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48287",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48289",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48289",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48289",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48291",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48291",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48293",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48295",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48297",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48299",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48301",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48303",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48305",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48307",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48307",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48309",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48311",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48313",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48313",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48315",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48317",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48319",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48321",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48323",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48325",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48327",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48329",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48331",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48333",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48335",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48337",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48339",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48341",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48343",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48345",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48347",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48349",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48349",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48349",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48351",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48353",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48355",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48357",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48359",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48361",
    geoId: "20078451",
    geo: "Texas",
    territoryId: "20111177",
    territory: "Houston S TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48363",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48365",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48367",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48369",
    geoId: "20078447",
    geo: "Mid West",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48369",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48371",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48373",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48375",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48377",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48379",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48381",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48383",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48385",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48387",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48389",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48391",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48393",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48395",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48397",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48399",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48401",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48403",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48405",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48407",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48409",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48411",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48411",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48413",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48415",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48417",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48419",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48421",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48423",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48425",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48427",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48429",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48431",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48433",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48435",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48437",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48439",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48441",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48443",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48445",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48447",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48449",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48451",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48453",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48455",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48457",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48459",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48461",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48463",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48465",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48465",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48467",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48469",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48471",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48473",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48475",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48477",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48479",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48481",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48483",
    geoId: "20078449",
    geo: "Mid West",
    territoryId: "20111175",
    territory: "Albuquerque NM 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48485",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48487",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48489",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48491",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48493",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48495",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48497",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48499",
    geoId: "20078448",
    geo: "Texas",
    territoryId: "20111174",
    territory: "Dallas TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48501",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48503",
    geoId: "20078447",
    geo: "Texas",
    territoryId: "20111173",
    territory: "Fort Worth TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48505",
    geoId: "20078450",
    geo: "Texas",
    territoryId: "20111176",
    territory: "Houston TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "48507",
    geoId: "20078446",
    geo: "Texas",
    territoryId: "20111172",
    territory: "Austin TX 1",
    districtId: "20111200",
    district: "Texas",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49001",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49003",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49005",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49007",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49009",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49011",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49013",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49015",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49017",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49019",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49021",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49023",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49025",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49027",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49029",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49031",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49033",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49035",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49037",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49039",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49041",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49043",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49045",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49047",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49049",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49051",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49053",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49055",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "49057",
    geoId: "20078383",
    geo: "Phoenix",
    territoryId: "20111109",
    territory: "Las Vegas NV 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "50001",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50003",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50005",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50007",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50009",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50011",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50013",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50015",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50017",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50019",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50021",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50023",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50025",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "50027",
    geoId: "20078387",
    geo: "New England",
    territoryId: "20111113",
    territory: "Concord NH 1",
    districtId: "20111195",
    district: "New England",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51001",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51003",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51005",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51007",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51009",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51011",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51013",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51015",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51017",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51019",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51019",
    geoId: "20078407",
    geo: "Washington DC",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51021",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51023",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51025",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51025",
    geoId: "20078406",
    geo: "Washington DC",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51027",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51029",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51031",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51031",
    geoId: "20078407",
    geo: "Washington DC",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51033",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51035",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51036",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51037",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51041",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51043",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51043",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51045",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51047",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51049",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51051",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51053",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51057",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51059",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51061",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51063",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51065",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51067",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51069",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51071",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51073",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51075",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51077",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51079",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51081",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51083",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51083",
    geoId: "20078406",
    geo: "Washington DC",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51085",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51087",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51089",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51091",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51093",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51095",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51097",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51099",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51101",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51101",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51101",
    geoId: "20078406",
    geo: "Washington DC",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51103",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51105",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51107",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51107",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51109",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51111",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51113",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51113",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51115",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51117",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51119",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51121",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51125",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51127",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51127",
    geoId: "20078406",
    geo: "Washington DC",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51131",
    geoId: "20078402",
    geo: "Philadelphia",
    territoryId: "20111128",
    territory: "Philadelphia W PA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51133",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51135",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51137",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51137",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51139",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51139",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51141",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51143",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51143",
    geoId: "20078407",
    geo: "Washington DC",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51145",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51147",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51149",
    geoId: "20078405",
    geo: "Charlotte",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51149",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51153",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51155",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51157",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51159",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51161",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51163",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51165",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51167",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51169",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51171",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51171",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51173",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51175",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51177",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51179",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51181",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51183",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51185",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51187",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51187",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51191",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51193",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51195",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51197",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51199",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51510",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51520",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51530",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51540",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51550",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51570",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51580",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51590",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51600",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51610",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51620",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51630",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51640",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51650",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51660",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51670",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51678",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51680",
    geoId: "20078405",
    geo: "Charlotte",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51680",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51683",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51685",
    geoId: "20078403",
    geo: "Washington DC",
    territoryId: "20111129",
    territory: "Washington DC 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51690",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51700",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51710",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51720",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51730",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51735",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51740",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51750",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51760",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51770",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51775",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51790",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51800",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51810",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51820",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51830",
    geoId: "20078406",
    geo: "Charlotte",
    territoryId: "20111132",
    territory: "Virginia Beach VA 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "51840",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "53001",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53003",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53005",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53007",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53009",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53011",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53013",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53015",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53017",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53019",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53021",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53023",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53025",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53027",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53029",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53031",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53033",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53033",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53035",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53035",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53037",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53039",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53039",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53041",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53043",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53045",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53047",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53049",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53051",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53053",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53055",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53057",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53059",
    geoId: "20078463",
    geo: "Northwest",
    territoryId: "20111189",
    territory: "Portland OR 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53061",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53063",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53065",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53067",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53069",
    geoId: "20078379",
    geo: "Northwest",
    territoryId: "20113577",
    territory: "Tacoma WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53071",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53073",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53075",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "53077",
    geoId: "20078453",
    geo: "Northwest",
    territoryId: "20111179",
    territory: "Seattle WA 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "54001",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54003",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54005",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54007",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54009",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54011",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54013",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54015",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54017",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54019",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54021",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54023",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54025",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54025",
    geoId: "20078408",
    geo: "Washington DC",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54027",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54029",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54031",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54033",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54035",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54037",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54039",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54041",
    geoId: "20078405",
    geo: "Ohio Valley",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54041",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54043",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54045",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54047",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54049",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54051",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54053",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54055",
    geoId: "20078407",
    geo: "Charlotte",
    territoryId: "20111133",
    territory: "Winston - Salem NC 1",
    districtId: "20111198",
    district: "Carolinas",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54055",
    geoId: "20078408",
    geo: "Charlotte",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54057",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54057",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54059",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54061",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54063",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54065",
    geoId: "20078401",
    geo: "Washington DC",
    territoryId: "20111127",
    territory: "Baltimore W MD 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54067",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54069",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54071",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54073",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54075",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54077",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54079",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54081",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54083",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54085",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54087",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54089",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54091",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54093",
    geoId: "20078405",
    geo: "Washington DC",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54095",
    geoId: "20078405",
    geo: "Ohio Valley",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54095",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54097",
    geoId: "20078405",
    geo: "Ohio Valley",
    territoryId: "20111131",
    territory: "Richmond VA 1",
    districtId: "20111196",
    district: "Mid Atlantic",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54097",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54099",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54101",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54103",
    geoId: "20078399",
    geo: "Ohio Valley",
    territoryId: "20111125",
    territory: "Pittsburgh PA 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54103",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54105",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54107",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "54109",
    geoId: "20078408",
    geo: "Ohio Valley",
    territoryId: "20111134",
    territory: "Columbus OH 1",
    districtId: "20111197",
    district: "Detroit",
    regionId: "20111202",
    region: "East",
  },
  {
    fip: "55001",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55003",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55005",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55005",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55007",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55009",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55011",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55013",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55015",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55017",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55019",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55021",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55021",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55023",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55023",
    geoId: "20078440",
    geo: "Chicago",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55025",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55027",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55027",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55029",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55031",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55033",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55035",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55037",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55039",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55041",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55041",
    geoId: "20078434",
    geo: "Minneapolis",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55043",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55045",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55047",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55049",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55051",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55053",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55055",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55057",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55059",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55059",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55059",
    geoId: "20078439",
    geo: "Chicago",
    territoryId: "20111165",
    territory: "Evanston IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55061",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55063",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55065",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55067",
    geoId: "20078384",
    geo: "Chicago",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55067",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55069",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55069",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55071",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55073",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55075",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55077",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55077",
    geoId: "20078440",
    geo: "Chicago",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55078",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55079",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55079",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55081",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55083",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55085",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55087",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55089",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55091",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55093",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55095",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55095",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55097",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55099",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55101",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55103",
    geoId: "20078437",
    geo: "Minneapolis",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55103",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55105",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55107",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55107",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55109",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55111",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55113",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55115",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55115",
    geoId: "20078440",
    geo: "Chicago",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55117",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55119",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55121",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55123",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55125",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55127",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55127",
    geoId: "20078438",
    geo: "Chicago",
    territoryId: "20111164",
    territory: "Naperville IL 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55129",
    geoId: "20078384",
    geo: "Minneapolis",
    territoryId: "20111110",
    territory: "Minneapolis N MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55131",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55133",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55133",
    geoId: "20078437",
    geo: "Chicago",
    territoryId: "20111163",
    territory: "Milwaukee S WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55135",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55137",
    geoId: "20078434",
    geo: "Minneapolis",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55137",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55139",
    geoId: "20078434",
    geo: "Chicago",
    territoryId: "20111160",
    territory: "Milwaukee N WI 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "55141",
    geoId: "20078440",
    geo: "Minneapolis",
    territoryId: "20111166",
    territory: "Minneapolis S MN 1",
    districtId: "20111192",
    district: "Great Lakes",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56001",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56001",
    geoId: "20078452",
    geo: "Northwest",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56003",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56005",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56007",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56009",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56011",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56013",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56015",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56017",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56019",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56021",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56021",
    geoId: "20078452",
    geo: "Northwest",
    territoryId: "20111178",
    territory: "Denver CO 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56023",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56025",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56027",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56029",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56031",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56033",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56035",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56037",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56039",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56041",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56043",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "56045",
    geoId: "20078382",
    geo: "Northwest",
    territoryId: "20111108",
    territory: "Billings MT 1",
    districtId: "20111190",
    district: "Rocky Mountains",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "72003",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72005",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72009",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72011",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72013",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72017",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72021",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72023",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72025",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72027",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72031",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72033",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72035",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72041",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72043",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72047",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72049",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72053",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72055",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72057",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72059",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72061",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72065",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72069",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72071",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72073",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72077",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72079",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72081",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72083",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72087",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72091",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72095",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72097",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72099",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72103",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72107",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72109",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72113",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72115",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72117",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72119",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72121",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72125",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72127",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72129",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72131",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72133",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72135",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72137",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72139",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72141",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72143",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72145",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72147",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72149",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72151",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72153",
    geoId: "20057941",
    geo: "Puerto Rico",
    territoryId: "20094852",
    territory: "ONC_HEM_PR",
    districtId: "20068067",
    district: "Puerto Rico_ONC",
    regionId: "20012954",
    region: "Puerto Rico",
  },
  {
    fip: "72643",
    geoId: "20078442",
    geo: "Mid West",
    territoryId: "20111168",
    territory: "Wichita KS 1",
    districtId: "20111199",
    district: "Central",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "46102",
    geoId: "20081104",
    geo: "Minneapolis",
    districtId: "20111192",
    district: "Great Lakes",
    territoryId: "20111162",
    territory: "Omaha NE 1",
    region: "West",
    regionId: "20111201",
    count: 0,
  },
  {
    fip: "02158",
    geoId: "20081105",
    geo: "Northwest",
    territory: "Seattle WA 1",
    territoryId: "20086266",
    districtId: "20111190",
    district: "Rocky Mountains",
    region: "West",
    regionId: "20111201",
    count: 0,
  },
  {
    fip: "15001",
    geoId: "1111",
    geo: "Honolulu HI LC",
    territoryId: "9999",
    territory: "Honolulu HI",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "15003",
    geoId: "1111",
    geo: "Honolulu HI LC",
    territoryId: "9999",
    territory: "Honolulu HI",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "15007",
    geoId: "1111",
    geo: "Honolulu HI LC",
    territoryId: "9999",
    territory: "Honolulu HI",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  },
  {
    fip: "15009",
    geoId: "1111",
    geo: "Honolulu HI LC",
    territoryId: "9999",
    territory: "Honolulu HI",
    districtId: "20111193",
    district: "California",
    regionId: "20111201",
    region: "West",
  }
];
