import styled from "styled-components";

export const TimelineTriggerTypeWrapper = styled.div`
  width: 100%;
  .timeLineTriggerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 35px;
  }

  .timeLineTriggerWrapperNpp {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
  }

  .circle {
    border-radius: 100px;
    position: relative;
  }

  .contentBox {
    padding: 0px 20px 0px;
    font-size: 16px;
    overflow: hidden;
    text-align: right;
  }
  /*
.timeLineTriggerWrapperH {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.timeLineTriggerWrapperNppH {
    display: flex;
    flex-direction: column;
    justify-content: flex-end
}

.circleH {
    border-radius: 100px;
    position: relative;
}

.contentBoxH {
    padding: 0px 10px 8px;
    font-size: 12px;
    writing-mode: vertical-rl;
    text-orientation: upright;
}*/
`;
