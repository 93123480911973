import { Image } from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AZLOGO from "../../assets/img/azbrain_logo_1.png";
import { BrandWrapper } from "./Brand.styles";

const Brand = () => {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate("/", { replace: true }), [
    navigate,
  ]);
  return (
    <BrandWrapper onClick={handleOnClick}>
      <Image className="brandLogo" preview={false} width={120} src={AZLOGO} />
    </BrandWrapper>
  );
};

export default Brand;
