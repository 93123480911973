import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Segmented, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";
import LayeredColumnChart from "../../../components/RepApp/LayeredColumnChart";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import {
  getIndicationType,
  getSelectedGeoTerriroties
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import { numberWithCommas } from "../../../utils/utils";
import { PerformanceChartWrapper } from "./PerformanceChart.styles";

const PerformanceChart = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [perChartType, setPerChartType] = useState("QUARTERLY");
  const [currTblColumns, setCurrTblColumns] = useState([]);
  const [currTblData, setCurrTblData] = useState([]);
  const [currChartData, setCurrChartData] = useState([]);
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  let { providerId } = useParams();
  useEffect(() => {
    getPerformanceInfo();
  }, [perChartType, indicationType, selectedGeoTerriroties]);

  const getPerformanceInfo = () => {
    setIsLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getPrescriberPerfData}`,
      data: {
        timeInterval: perChartType,
        tumorType: indicationType,
        prescriberId: providerId,
        territories: [],
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            var titleArray = [];
            var tblData = [];
            var tblColumns = [];
            var title = "Quarter";
            if (perChartType == "MONTHLY") {
              title = "Month";
            } else if (perChartType == "WEEKLY") {
              title = "Week";
            }

            var firstColumnArray = [
              "SP TRx",
              "SD TRx",
              "% SD TRx",
              "% SP TRx",
              "SP NPS",
              "SD NPS",
              "% SD NPS",
              "% SP NPS",
            ];
            var chartData = [];
            response.map((res, idx) => {
              var chartObj = {
                key: idx,
                name: res.name,
              };
              titleArray.push(res.name);
              var trxCount = 0;
              var npsCount = 0;
              var trxPercent = 0;
              var npsPercent = 0;
              var trxspPercent = 0;
              var npsspPercent = 0;
              res.data.map((_d) => {
                var key = _d.name;
                if (key == "SP TRx" || key == "SD TRx") {
                  trxCount = trxCount + _d.value;
                } else if (key == "SP NPS" || key == "SD NPS") {
                  npsCount = npsCount + _d.value;
                } else if (key == "% SD TRx") {
                  trxPercent = parseInt(_d.value);
                } else if (key == "% SD NPS") {
                  npsPercent = parseInt(_d.value);
                } else if (key == "% SP TRx") {
                  trxspPercent = parseInt(_d.value);
                } else if (key == "% SP NPS") {
                  npsspPercent = parseInt(_d.value);
                }
              });
              tblColumns.push({
                title: res.name,
                dataIndex: res.name,
              });
              chartObj.trx = trxCount;
              chartObj.nps = npsCount;
              chartObj.trxPercent = trxPercent;
              chartObj.npsPercent = npsPercent;
              chartObj.trxspPercent = trxspPercent;
              chartObj.npsspPercent = npsspPercent;
              chartData.push(chartObj);
            });
            tblColumns.push({
              title: title,
              dataIndex: "name",
              width: 100,
            });

            firstColumnArray.map((column, idx) => {
              var tblObj = {
                key: idx,
                name: column,
              };
              response.map((res, idx) => {
                res.data.map((_d) => {
                  var key = _d.name;
                  if (key == column) {
                    if (!key.includes("%")) {
                      tblObj[res.name] = numberWithCommas(_d.value);
                    } else {
                      tblObj[res.name] = _d.value;
                    }
                  }
                });
              });
              tblData.push(tblObj);
            });

            setCurrTblColumns(tblColumns.reverse());
            setCurrTblData(tblData);
            setCurrChartData(chartData.reverse());
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <PerformanceChartWrapper>
      <Spin spinning={isLoading}>
        <Row>
          <Col xs={12}>
            <div className="titleView">
              <Popover
                placement="right"
                content={
                  <div style={{ maxWidth: 400 }}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          "SP (Specialty Pharmacy) and SD (Specialty Distributor) data from Matrix.",
                      }}
                    ></p>
                  </div>
                }
                title={""}
                trigger={"click"}
              >
                Performance TRx & NPS <InfoCircleOutlined />
              </Popover>
            </div>
          </Col>
          <Col xs={12}>
            <div className="chart-type-buttons-wrapper">
              <Segmented
                style={{ fontSize: 12 }}
                onChange={(e) => {
                  setPerChartType(e);
                  //updateChart(e);
                }}
                defaultValue={perChartType}
                options={["WEEKLY", "MONTHLY", "QUARTERLY"]}
              />
            </div>
          </Col>
          <Col xs={24}>
            <LayeredColumnChart
              chartId="hcp_performance_chart"
              chartData={currChartData}
              type={perChartType}
            />
          </Col>
          <Col xs={24}>
            <Table
              style={{ height: 280, marginBottom: 15 }}
              columns={currTblColumns}
              dataSource={currTblData}
              pagination={false}
            />
            <i>
              <div className="source-link">
                Source:{" "}
                <a
                  href="https://az-uscom1-prd.cloud.microstrategy.com/MicroStrategy/servlet/mstrWeb"
                  target="_blank"
                >
                  Matrix
                </a>
              </div>
            </i>
          </Col>
        </Row>
      </Spin>
    </PerformanceChartWrapper>
  );
};

export default PerformanceChart;
