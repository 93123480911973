import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { TimelineTriggerTypeWrapper } from "./TimelineTriggerType.styles";

const TimelineTriggerType = (props) => {
  const [activeColor, setActiveColor] = useState("");
  const [circleSize, setCircleSize] = useState("");
  const [halfCircleSize, setHalfCircleSize] = useState("");
  const [circlePosition, setCirclePosition] = useState("");
  const [circleMargin, setCircleMargin] = useState(-40);
  const [ppVisible, setPPVisible] = useState(false);
  const [nppVisible, setNPPVisible] = useState(false);
  useEffect(() => {
    if (props.isActive === true) {
      setActiveColor("#E8582C");
      setCircleSize(props.count > 2 ? "20px" : "15px");
      setHalfCircleSize(props.count > 2 ? "10px" : "7.5px");
    } else {
      setActiveColor("#FFF");
      setCircleSize(props.count > 2 ? "19px" : "14px");
      setHalfCircleSize(props.count > 2 ? "9.5px" : "7px");
    }
    setCirclePosition(props.count > 2 ? "9px" : "0px");
    setCircleMargin(props.count > 2 ? -9 : -8);
  }, [props.isActive]);
  const hide = () => {
    setPPVisible(false);
    setNPPVisible(false);
  };

  const handlePPVisibleChange = (visible) => {
    setPPVisible(visible);
  };

  const handleNPPVisibleChange = (visible) => {
    setNPPVisible(visible);
  };
  const handleNavigation = (e) => {
    hide();
  };
  useEffect(() => {
    document
      .getElementById("activityContentWrapper1")
      .addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      if (document.getElementById("activityContentWrapper1")) {
        document
          .getElementById("activityContentWrapper1")
          .removeEventListener("scroll", (e) => handleNavigation(e));
      }
    };
  }, []);
  function capitalizeFirstLetter(string) {
    //return string.charAt(0).toUpperCase() + string.slice(1);
    return string.replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    );
  }
  const popupInfo = (info) => {
    var html = "";
    if (Array.isArray(info) && info.length > 0) {
      if (info[0].name) {
        info.map((d, idx) => {
          if (d.data) {
            html += "";
            d.data.map((data) => {
              html +=
                "<b>" +
                capitalizeFirstLetter(data.name.replace("_", " ")) +
                ":</b> " +
                data.value +
                "<br/>";
            });
          }
          if (info.length > 1 && idx != info.length - 1) {
            html += "<hr />";
          }
        });
      } else {
        html += "<ul>";
        info.map((d, idx) => {
          html += `<li key=${idx}>${d}</li>`;
        });
        html += "</ul>";
      }
    }
    return html;
    /*var info = [
      {
        name: "Email - Field",
        data: [
          {
            name: "prid_id",
            value: "kscf051",
          },
          {
            name: "email",
            value: "jillaine.geary@astrazeneca.com",
          },
          {
            name: "full_name",
            value: "Jillaine Geary",
          },
        ],
      },
    ];*/
  };
  return (
    <TimelineTriggerTypeWrapper>
      {props.triggerType === "pp" ? (
        <div
          className="timeLineTriggerWrapper"
          style={{ paddingTop: props.index === 1 ? "10px" : "0px" }}
        >
          {props.info && props.info != "" ? (
            <Popover
              placement="bottom"
              content={
                <div
                  style={{
                    maxWidth: 400,
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "scroll",
                  }}
                  dangerouslySetInnerHTML={{ __html: popupInfo(props.info) }}
                ></div>
              }
              title={""}
              trigger={"click"}
              visible={ppVisible}
              onVisibleChange={handlePPVisibleChange}
            >
              <div
                className="circle"
                style={{
                  background: activeColor,
                  width: circleSize,
                  height: circleSize,
                  border: `4px solid ${props.color}`,
                  bottom: `-${halfCircleSize}`,
                }}
              ></div>
            </Popover>
          ) : (
            <div
              className="circle"
              style={{
                background: activeColor,
                width: circleSize,
                height: circleSize,
                border: `4px solid ${props.color}`,
                bottom: `-${halfCircleSize}`,
              }}
            ></div>
          )}
          <div
            className="contentBox"
            style={{ borderBottom: `2px solid ${props.color}` }}
          >
            {props.text}
          </div>
        </div>
      ) : props.triggerType === "npp" ? (
        <div
          className="timeLineTriggerWrapperNpp"
          style={{ paddingTop: props.index === 1 ? "10px" : "0px" }}
        >
          <div
            className="contentBox"
            style={{ borderBottom: `2px solid ${props.color}` }}
          >
            {props.text}
          </div>
          {props.info && props.info != "" ? (
            <Popover
              placement="bottom"
              content={
                <div
                  style={{
                    maxWidth: 400,
                    maxHeight: 400,
                    overflow: "hidden",
                    overflowY: "scroll",
                  }}
                  dangerouslySetInnerHTML={{ __html: popupInfo(props.info) }}
                ></div>
              }
              title={""}
              trigger={"click"}
              visible={nppVisible}
              onVisibleChange={handleNPPVisibleChange}
            >
              <div
                className="circle"
                style={{
                  background: activeColor,
                  width: circleSize,
                  height: circleSize,
                  border: `4px solid ${props.color}`,
                  bottom: `-${halfCircleSize}`,
                }}
              ></div>
            </Popover>
          ) : (
            <div
              className="circle"
              style={{
                background: activeColor,
                width: circleSize,
                height: circleSize,
                border: `4px solid ${props.color}`,
                bottom: `-${halfCircleSize}`,
              }}
            ></div>
          )}
        </div>
      ) : (
        ""
      )}
    </TimelineTriggerTypeWrapper>
  );
};

export default TimelineTriggerType;
