import styled from "styled-components";

export const FooterWrapper = styled.div`
  .footer {
    padding: 10px 10px;
    font-size: 0.625rem;
    background: #2e547a;
    color: #ffffff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.3;
    letter-spacing: 0.8px;
    width: 100%;
    font-size: 12px;
    .emaillink {
      white-space: nowrap;
      font-weight: bold;
      a {
        color: #ffffff;
        padding: 8px;

        text-decoration: underline;
      }
    }
    .brandLogo {
      margin-top: 0px;
      height: 20px;
      width: auto;
    }
    .footer-links {
      a {
        color: #ffffff;
        padding: 8px;
        text-decoration: none;
        white-space: nowrap;
        font-size: 12px;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
