import jwtDecode from "jwt-decode";
import CONFIG from "../constants";

export function checkAuth() {
  let c_url = new URL(window.location.href);
  let searchParams = new URLSearchParams(c_url.search);
  let token = getCookie("access-token");
  if (token === null || token === "") {
    if (searchParams.get("code") || searchParams.get("access_token")) {
      var access_token = searchParams.get("access_token"); /// jwt token";
      var decoded = jwtDecode(access_token);
      if (decoded.exp) {
        var exp = decoded.exp;
        var username = decoded.first_name + " " + decoded.last_name;
        setCookie("userName", username, exp);
        setCookie("firstName", decoded.first_name, exp);
        setCookie("lastName", decoded.last_name, exp);
        setCookie("sub", decoded.id, exp);
        setCookie("jobTitle", decoded.designation, exp);
        setCookie("email", decoded.email, exp);
        setCookie("access-token", searchParams.get("access_token"), exp);
        setCookie("refresh-token", searchParams.get("refresh_token"), exp);
        setTimeout(() => {
          const _url = window.location.href.split("?")[0];
          window.location.href = _url;
        }, 1000);
      }
    } else {
      redirectToPing();
    }
  }
}

export function isAuthenticated() {
  let c_url = new URL(window.location.href);
  let searchParams = new URLSearchParams(c_url.search);
  let token = getCookie("access-token");
  if (token === null || token === "") {
    if (searchParams.get("code") || searchParams.get("access_token")) {
      var access_token = searchParams.get("access_token");
      var decoded = jwtDecode(access_token);
      if (decoded.exp) {
        var exp = decoded.exp;
        var username = decoded.first_name + " " + decoded.last_name;
        setCookie("jobTitle", decoded.designation, exp);
        setCookie("userName", username, exp);
        setCookie("firstName", decoded.first_name, exp);
        setCookie("lastName", decoded.last_name, exp);
        setCookie("sub", decoded.id, exp);
        setCookie("email", decoded.email, exp);
        setCookie("access-token", searchParams.get("access_token"), exp);
        setCookie("refresh-token", searchParams.get("refresh_token"), exp);
      }
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function getRefreshToken() {
  var isRefreshToken = false;
  let accessToken = getCookie("access_token");
  let refreshToken = getCookie("refresh-token");
  /*var promiseObj = await $httpClient.getApiCall(
    "/identity/ping/refreshtoken?refreshToken=" +
      refreshToken +
      "&grantType=refresh_token"
  );

  $.when(promiseObj).done((response) => {
    isRefreshToken = true;
    var decoded = jwt_decode(response.access_token);
    if (decoded.exp) {
      setCookie("access-token", response.access_token, decoded.exp);
      setCookie("refresh-token", response.refresh_token, decoded.exp);
    }
  });*/
  return isRefreshToken;
}

export function redirectToPing() {
  let client_uri = "";
  if (
    localStorage.getItem("isPrevState") != "" &&
    localStorage.getItem("isPrevState") != undefined
  ) {
    var CURRENTURL = localStorage.getItem("CURRENTURL");
    var CURRENTURLHOST = localStorage.getItem("CURRENTURLHOST");
    var CURRENTURLPATHNAME = localStorage.getItem("CURRENTURLPATHNAME");
    client_uri = CURRENTURL + CURRENTURLHOST + CURRENTURLPATHNAME;
  } else client_uri = window.location.protocol + "//" + window.location.host;
  let base64Str = window.btoa(client_uri);

  let uri =
    CONFIG.api.loginUrl + "/identity/ping/login?client_uri=" + base64Str;
  console.log(`uri: ${uri}`);
  window.location.href = uri;
}

export function setCookie(cname, cvalue, exdays) {
  if (cname == "refresh-token") {
    var d = new Date();
    var minutes = 90;
    d.setTime(d.getTime() + minutes * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    const d = new Date(exdays * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function checkCookie() {
  let user = getCookie("username");
  if (user != "") {
  } else {
    user = prompt("Please enter your name:", "");
    if (user != "" && user != null) {
      setCookie("username", user, 365);
    }
  }
}

export function checkUnauthorised(res) {
  if (res && (res.errorCode == 401 || res.errorCode == "401")) {
    return {
      status: true,
      message: `${res.errorMessage} <a href="mailto:${res.supportEmail}">${res.supportEmail}</a>`,
    };
  }
  return {
    status: false,
  };
}
