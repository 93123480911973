import styled from "styled-components";

export const StackedBarChartWrapper = styled.div`
  .title-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px 0px;
  }
  .message-button-group {
    margin: 0px 0px;
  }
  .message-title {
    margin: 0px 0px;
  }
  .account-id-col{
    display: flex;
    width: 100px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .account-id-row{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const CardTitle = styled.div`
  text-align: left;
  font-size: 14px;
  color: #606060;
  opacity: 0.7;
  width: 100%;
  margin-top: 10px;
`;
export const CardMessage = styled.div`
  text-align: left;
  font-size: 14px;
  color: #000000;
  width: 100%;
`;
export const MessageTitle = styled.div`
  text-align: left;
  font-size: 14px;
  color: #25405f;
  font-weight: 600;
  padding: 5px 0px 0px;
  margin: 0px 0px;
`;

export const AccountAddress = styled.div`
  text-align: left;
  font-size: 12px;
  color: rgb(32, 33, 36);
  font-weight: 400;
  padding: 5px 0px;
  margin: 0px 0px;
`;
export const AccountId = styled.div`
  text-align: left;
  font-size: 12px;
  color: rgb(32, 33, 36);
  font-weight: 400;
  padding: 5px 0px;
  margin: 0px 0px;
`;
