import { Image } from "antd";
import React from "react";
import MaintenanceIcon from "../../assets/img/maintenance.png";
import MaintenanceHeader from "../../components/MaintenanceHeader";
import { MaintenanceViewWrapper } from "./MaintenanceView.styles";

const MaintenanceView = (props) => (
  <MaintenanceViewWrapper>
    <MaintenanceHeader />
    <div className="maintenanceView">
      <Image className="maintenanceViewIcon" src={MaintenanceIcon} alt="image" preview={false} />
      <h1>We are Under Maintenance.</h1>
      <h3>Will be back soon!</h3>
    </div>
  </MaintenanceViewWrapper>
);

export default MaintenanceView;
