import {
  ContainerOutlined,
  FundProjectionScreenOutlined,
  LeftOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Button, Image, Segmented, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import callIcn from "../../assets/img/callIcn.png";
import emailIcn from "../../assets/img/emailIcn.png";
import { getIndicationType, setIndicationType } from "../../store/appSlice";
import Brand from "../Brand";
import { HeaderWrapper } from "./PhyHeader.styles";
const { Option } = Select;

const items = [
  {
    label: "Insights",
    key: "insights",
    icon: <PieChartOutlined />,
  },
  {
    label: "Triggers",
    key: "triggers",
    icon: <ContainerOutlined />,
  },
];

const AZHeader = (props) => {
  const match = useMatch("/triggers");
  const indicationType = useSelector(getIndicationType);
  const [isShrunk, setShrunk] = useState(false);
  const [current, setCurrent] = useState(match ? "triggers" : "insights");
  const [indType, setIndType] = useState(indicationType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOnClick = useCallback(() => navigate(-1, { replace: true }), [
    navigate,
  ]);
  const [prescriberExtInfo, setPrescriberExtInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleChange = (value) => {};
  const onTypeChange = (value) => {
    dispatch(setIndicationType(value));
    setIndType(value);
  };

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  useEffect(() => {
    //setCurrent(match ? "insights" : "trigger");
    setPrescriberExtInfo(props.prescriberExtInfo);
  }, [props.prescriberExtInfo]);
  const handleEmailClick = () => {
    if (prescriberExtInfo && prescriberExtInfo.sfAccountId) {
      window.open(
        `oce://deeplink/tab/OCE__Accounts/sobject-name/Account/record-id/${prescriberExtInfo.sfAccountId}/quick-action/OCE__EMAIL`
      );
    }
  };
  const handleCallClick = () => {
    if (prescriberExtInfo && prescriberExtInfo.sfAccountId) {
      window.open(
        `oce://deeplink/modal/sobject-name/OCE__Call__c/action/new?uid=${prescriberExtInfo.sfAccountId}`
      );
    }
  };
  const handleProfileClick = () => {
    if (prescriberExtInfo && prescriberExtInfo.sfAccountId) {
      window.open(
        `oce://deeplink/tab/OCE__Accounts/sobject-name/Account/record-id/${prescriberExtInfo.sfAccountId}`
      );
    }
  };

  return (
    <HeaderWrapper>
      <div className="header">
        <div className="trigger-title-back-wrapper">
          <LeftOutlined height={30} onClick={handleOnClick} />
          <Brand />
        </div>

        <div className="rightMenu">
          <Button
            key={0}
            className="outline-btn"
            style={{ marginRight: 10 }}
            onClick={handleCallClick}
          >
            <Image preview={false} src={callIcn} width={15} />{" "}
            <span style={{ marginLeft: 10 }}>Schedule a call</span>
          </Button>

          <Button
            key={1}
            className="outline-btn"
            style={{ marginRight: 10 }}
            onClick={handleEmailClick}
          >
            <Image preview={false} src={emailIcn} width={15} />{" "}
            <span style={{ marginLeft: 10 }}>Send an email</span>
          </Button>
          <Button
            key={2}
            className="outline-btn"
            style={{ marginRight: 10 }}
            onClick={handleProfileClick}
          >
            <FundProjectionScreenOutlined style={{ fontSize: 14 }} />
            <span style={{ marginLeft: 10 }}>See profile in OCE</span>
          </Button>
          {/*<UserInfo />*/}
        </div>
      </div>
      {
        /*current == "insights" && */ <div className="sub-menu-container">
          <div className="subMenu">
            {/*<span className="subMenuProduct">CALQUENCE</span>*/}{" "}
            <Select
              style={{ background: "#eeeeee" }}
              className="subMenuProduct"
              onChange={handleChange}
              defaultValue={"CALQUENCE"}
              showArrow={false}
            >
              <Option value="CALQUENCE">CALQUENCE</Option>
            </Select>
            <Segmented
              onChange={onTypeChange}
              value={indType}
              options={[
                {
                  label: "TOTAL",
                  value: "ALL",
                },
                {
                  label: "CLL",
                  value: "CLL",
                },
                {
                  label: "MCL",
                  value: "MCL",
                },
              ]}
            />
          </div>
        </div>
      }
    </HeaderWrapper>
  );
};

export default AZHeader;
