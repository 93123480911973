export default [
  {
    key: "2",
    parent: "72ND STREET MEDICAL ASSOCIATES",
    account: "72ND STREET MEDICAL ASSOCIATES",
    hcp_id: 55354712,
    hcp_name: "TEPLER JEFFREY",
    total_patients: "",
    sp_units: 12,
    sd_units: "",
    sp_nps: "",
    ven_nps: "",
    cit_nps: "",
    cal_nps_adj: "",
    zanu_nps_adj: 3,
    ibru_nps_adj: "",
    btki_adj: 3,
    nps_adjusted_claims: 3,
    cll_priority: "High",
    mcl_priority: "High",
    evusheld_priority: "High",
    top_25_account: "Yes",
    reached: "Yes",
  },
  {
    key: "3",
    parent: "72ND STREET MEDICAL ASSOCIATES",
    account: "72ND STREET MEDICAL ASSOCIATES",
    hcp_id: 56642781,
    hcp_name: "GELFFAND ROBERT",
    total_patients: "",
    sp_units: 11,
    sd_units: "",
    sp_nps: "",
    ven_nps: "",
    cit_nps: "",
    cal_nps_adj: "",
    zanu_nps_adj: "",
    ibru_nps_adj: "",
    btki_adj: "",
    nps_adjusted_claims: "",
    cll_priority: "High",
    mcl_priority: "High",
    evusheld_priority: "High",
    top_25_account: "No",
    reached: "Yes",
  },
  {
    key: "4",
    parent: "72ND STREET MEDICAL ASSOCIATES",
    account: "CANCER AND HEMATOLOGY CENTERS OF WESTERN MICHIGAN PC",
    hcp_id: 55354712,
    hcp_name: "TEPLER JEFFREY",
    total_patients: "",
    sp_units: 12,
    sp_nps: "",
    sd_units: "",
    ven_nps: "",
    cit_nps: "",
    cal_nps_adj: "",
    zanu_nps_adj: "",
    ibru_nps_adj: "",
    btki_adj: "",
    nps_adjusted_claims: "",
    cll_priority: "High",
    mcl_priority: "High",
    evusheld_priority: "High",
    top_25_account: "Yes",
    reached: "Yes",
  },
  {
    key: "5",
    parent: "72ND STREET MEDICAL ASSOCIATES",
    account: "EAST SIDE ONCOLOGY ASSOCIATES PLLC",
    hcp_id: 56642781,
    hcp_name: "GELFFAND ROBERT",
    total_patients: "",
    sp_units: 11,
    sp_nps: "",
    sd_units: "",
    ven_nps: "",
    cit_nps: "",
    cal_nps_adj: "",
    zanu_nps_adj: "",
    ibru_nps_adj: "",
    btki_adj: "",
    nps_adjusted_claims: "",
    cll_priority: "High",
    mcl_priority: "High",
    evusheld_priority: "High",
    top_25_account: "No",
    reached: "No",
  },
];
export const hcptablData = [
  {
      "hcpId": "55354712",
      "hcpName": "TEPLER JEFFREY",
      "cllPriority": "High",
      "mclPriority": "High",
      "evusheldPriority": "High",
      "inFocusAccount": "Yes",
      "spUnits": 12,
      "spNps": 5,
      "calNps": 1,
      "bruNps": 2,
      "ibrNps": 3,
      "btkiNps": 4,
      "venNps": 5,
      "citNps": 1,
      "reached": "Yes"
  },
  {
      "hcpId": "56642781",
      "hcpName": "GELFFAND ROBERT",
      "cllPriority": "High",
      "mclPriority": "High",
      "evusheldPriority": "High",
      "inFocusAccount": "Yes",
      "spUnits": 10,
      "spNps": 3,
      "calNps": 4,
      "bruNps": 3,
      "ibrNps": 7,
      "btkiNps": 1,
      "venNps": 0,
      "citNps": 1,
      "reached": "Yes"
  }
]

export const demandNpsData = [
  {
    name: "Dec-21",
    "SD TRX": 6004,
    "SP TRX": 2884,
    "SD (%)TRX": "68%",
    "SD NPS": 541,
    "SP NPS": 234,
    "SD (%)NPS": "70%",
  },
  {
    name: "Jan-22",
    "SD TRX": 5798,
    "SP TRX": 2876,
    "SD (%)TRX": "67%",
    "SD NPS": 443,
    "SP NPS": 248,
    "SD (%)NPS": "64%",
  },
  {
    name: "Feb-22",
    "SD TRX": 5408,
    "SP TRX": 2735,
    "SD (%)TRX": "66%",
    "SD NPS": 413,
    "SP NPS": 238,
    "SD (%)NPS": "63%",
  },
  {
    name: "Mar-22",
    "SD TRX": 6341,
    "SP TRX": 3239,
    "SD (%)TRX": "66%",
    "SD NPS": 485,
    "SP NPS": 237,
    "SD (%)NPS": "63%",
  },
  {
    name: "Apr-22",
    "SD TRX": 6116,
    "SP TRX": 3054,
    "SD (%)TRX": "67%",
    "SD NPS": 468,
    "SP NPS": 256,
    "SD (%)NPS": "65%",
  },
  {
    name: "Apr-22",
    "SD TRX": 6807,
    "SP TRX": 3258,
    "SD (%)TRX": "68%",
    "SD NPS": 520,
    "SP NPS": 212,
    "SD (%)NPS": "71%",
  },
];

export const performanceNpsData = [
  {
    key: 1,
    name: "SP TRx",
    "Dec-21": "2,884",
    "Jan-22": "2,876",
    "Feb-22": "2,735",
    "Mar-22": "3,239",
    "Apr-22": "3,054",
    "May-22": "3,258",
  },
  {
    key: 0,
    name: "SD TRx",
    "Dec-21": "6,004",
    "Jan-22": "5,798",
    "Feb-22": "5,408",
    "Mar-22": "6,341",
    "Apr-22": "6,116",
    "May-22": "6,807",
  },

  {
    key: 2,
    name: "% SD TRx",
    "Dec-21": "68%",
    "Jan-22": "67%",
    "Feb-22": "66%",
    "Mar-22": "66%",
    "Apr-22": "67%",
    "May-22": "68%",
  },
  {
    key: 4,
    name: "SP NPS",
    "Dec-21": "234",
    "Jan-22": "248",
    "Feb-22": "238",
    "Mar-22": "237",
    "Apr-22": "256",
    "May-22": "212",
  },
  {
    key: 3,
    name: "SD NPS",
    "Dec-21": "541",
    "Jan-22": "443",
    "Feb-22": "413",
    "Mar-22": "485",
    "Apr-22": "468",
    "May-22": "520",
  },

  {
    key: 5,
    name: "% SD NPS",
    "Dec-21": "70%",
    "Jan-22": "64%",
    "Feb-22": "63%",
    "Mar-22": "63%",
    "Apr-22": "65%",
    "May-22": "71%",
  },
];

export const performanceQtrNpsData = [
  {
    key: 1,
    name: "SP TRx",
    "Q4 2020": "12,884",
    "Q1 2021": "12,876",
    "Q2 2021": "12,735",
    "Q3 2021": "13,239",
    "Q4 2021": "13,054",
    "Q1 2022": "13,258",
  },
  {
    key: 0,
    name: "SD TRx",
    "Q4 2020": "16,004",
    "Q1 2021": "15,798",
    "Q2 2021": "15,408",
    "Q3 2021": "16,341",
    "Q4 2021": "16,116",
    "Q1 2022": "16,807",
  },

  {
    key: 2,
    name: "% SD TRx",
    "Q4 2020": "68%",
    "Q1 2021": "67%",
    "Q2 2021": "66%",
    "Q3 2021": "66%",
    "Q4 2021": "67%",
    "Q1 2022": "68%",
  },
  {
    key: 4,
    name: "SP NPS",
    "Q4 2020": "1234",
    "Q1 2021": "1248",
    "Q2 2021": "1238",
    "Q3 2021": "1237",
    "Q4 2021": "1256",
    "Q1 2022": "1212",
  },
  {
    key: 3,
    name: "SD NPS",
    "Q4 2020": "1541",
    "Q1 2021": "1443",
    "Q2 2021": "1413",
    "Q3 2021": "1485",
    "Q4 2021": "1468",
    "Q1 2022": "1520",
  },

  {
    key: 5,
    name: "% SD NPS",
    "Q4 2020": "70%",
    "Q1 2021": "64%",
    "Q2 2021": "63%",
    "Q3 2021": "63%",
    "Q4 2021": "65%",
    "Q1 2022": "71%",
  },
];

export const performanceWeeklyNpsData = [
  {
    key: 0,
    name: "SD TRx",
    "02 May": "16,004",
    "09 May": "15,798",
    "16 May": "15,408",
    "23 May": "16,341",
    "30 May": "16,116",
    "06 Jun": "16,807",
  },
  {
    key: 1,
    name: "SP TRx",
    "02 May": "12,884",
    "09 May": "12,876",
    "16 May": "12,735",
    "23 May": "13,239",
    "30 May": "13,054",
    "06 Jun": "13,258",
  },
  {
    key: 2,
    name: "% SD TRx",
    "02 May": "68%",
    "09 May": "67%",
    "16 May": "66%",
    "23 May": "66%",
    "30 May": "67%",
    "06 Jun": "68%",
  },
  {
    key: 3,
    name: "SD NPS",
    "02 May": "1541",
    "09 May": "1443",
    "16 May": "1413",
    "23 May": "1485",
    "30 May": "1468",
    "06 Jun": "1520",
  },
  {
    key: 4,
    name: "SP NPS",
    "02 May": "1234",
    "09 May": "1248",
    "16 May": "1238",
    "23 May": "1237",
    "30 May": "1256",
    "06 Jun": "1212",
  },
  {
    key: 5,
    name: "% SD NPS",
    "02 May": "70%",
    "09 May": "64%",
    "16 May": "63%",
    "23 May": "63%",
    "30 May": "65%",
    "06 Jun": "71%",
  },
];

export const hcpPerformanceNpsData = [
  {
    key: 1,
    name: "SP TRx",
    "Dec-21": "2",
    "Jan-22": "2",
    "Feb-22": "2",
    "Mar-22": "4",
    "Apr-22": "3",
    "May-22": "5",
  },
  {
    key: 0,
    name: "SD TRx",
    "Dec-21": "3",
    "Jan-22": "3",
    "Feb-22": "1",
    "Mar-22": "5",
    "Apr-22": "4",
    "May-22": "5",
  },

  {
    key: 2,
    name: "% SD TRx",
    "Dec-21": "68%",
    "Jan-22": "67%",
    "Feb-22": "66%",
    "Mar-22": "66%",
    "Apr-22": "67%",
    "May-22": "68%",
  },
  {
    key: 4,
    name: "SP NPS",
    "Dec-21": "1",
    "Jan-22": "1",
    "Feb-22": "1",
    "Mar-22": "3",
    "Apr-22": "1",
    "May-22": "1",
  },
  {
    key: 3,
    name: "SD NPS",
    "Dec-21": "1",
    "Jan-22": "2",
    "Feb-22": "0",
    "Mar-22": "1",
    "Apr-22": "1",
    "May-22": "1",
  },

  {
    key: 5,
    name: "% SD NPS",
    "Dec-21": "70%",
    "Jan-22": "64%",
    "Feb-22": "63%",
    "Mar-22": "63%",
    "Apr-22": "65%",
    "May-22": "71%",
  },
];

export const hcpPerformanceQtrNpsData = [
  {
    key: 1,
    name: "SP TRx",
    "Q4 2020": "15",
    "Q1 2021": "6",
    "Q2 2021": "23",
    "Q3 2021": "10",
    "Q4 2021": "7",
    "Q1 2022": "10",
  },
  {
    key: 0,
    name: "SD TRx",
    "Q4 2020": "16",
    "Q1 2021": "10",
    "Q2 2021": "20",
    "Q3 2021": "9",
    "Q4 2021": "12",
    "Q1 2022": "10",
  },

  {
    key: 2,
    name: "% SD TRx",
    "Q4 2020": "68%",
    "Q1 2021": "67%",
    "Q2 2021": "66%",
    "Q3 2021": "66%",
    "Q4 2021": "67%",
    "Q1 2022": "68%",
  },
  {
    key: 4,
    name: "SP NPS",
    "Q4 2020": "2",
    "Q1 2021": "2",
    "Q2 2021": "4",
    "Q3 2021": "3",
    "Q4 2021": "2",
    "Q1 2022": "4",
  },
  {
    key: 3,
    name: "SD NPS",
    "Q4 2020": "3",
    "Q1 2021": "1",
    "Q2 2021": "2",
    "Q3 2021": "1",
    "Q4 2021": "4",
    "Q1 2022": "2",
  },

  {
    key: 5,
    name: "% SD NPS",
    "Q4 2020": "70%",
    "Q1 2021": "64%",
    "Q2 2021": "63%",
    "Q3 2021": "63%",
    "Q4 2021": "65%",
    "Q1 2022": "71%",
  },
];

export const hcpPerformanceWeeklyNpsData = [
  {
    key: 1,
    name: "SP TRx",
    "02 May": "2",
    "09 May": "2",
    "16 May": "2",
    "23 May": "4",
    "30 May": "3",
    "06 Jun": "5",
  },
  {
    key: 0,
    name: "SD TRx",
    "02 May": "3",
    "09 May": "3",
    "16 May": "1",
    "23 May": "5",
    "30 May": "4",
    "06 Jun": "5",
  },

  {
    key: 2,
    name: "% SD TRx",
    "02 May": "68%",
    "09 May": "67%",
    "16 May": "66%",
    "23 May": "66%",
    "30 May": "67%",
    "06 Jun": "68%",
  },
  {
    key: 4,
    name: "SP NPS",
    "02 May": "1",
    "09 May": "1",
    "16 May": "1",
    "23 May": "3",
    "30 May": "1",
    "06 Jun": "1",
  },
  {
    key: 3,
    name: "SD NPS",
    "02 May": "1",
    "09 May": "2",
    "16 May": "0",
    "23 May": "1",
    "30 May": "1",
    "06 Jun": "1",
  },

  {
    key: 5,
    name: "% SD NPS",
    "02 May": "70%",
    "09 May": "64%",
    "16 May": "63%",
    "23 May": "63%",
    "30 May": "65%",
    "06 Jun": "71%",
  },
];
