import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CONFIG from "../../constants";
import { GetAPIRequest } from "../../services";
import { checkUnauthorised, getCookie } from "../../utils/cookie-helper";
import {
  User,
  UserInfoDesignation,
  UserInfoName,
  UserInfoWrapper,
} from "./UserInfo.styles";

const UserInfo = (props) => {
  const [username, setUserName] = useState("");
  const [territory, setTerritory] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const user = getCookie("userName");
    //const userTerritory = getCookie("territory");
    if (user) {
      setUserName(user);
    }
    getUserDetails();
    GetAPIRequest({
      url: `${CONFIG.api.getTerritories}`,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            var terr = [];
            if (response && Array.isArray(response)) {
              response.map((r) => {
                terr.push(r.territory_name);
              });
              setTerritory(terr.join(", "));
            }
          }
        }
      })
      .catch((error) => {
        console.log("getTerritories--Error", error);
      });
  }, []);

  const redirectToAdmin = () => {
    navigate("/admin");
  };

  const getUserDetails = () => {
    const prid_id = getCookie("sub");
    GetAPIRequest({
      url: `${CONFIG.api.getUserDetails}/${prid_id}`,
    })
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((err) => {});
  };

  return (
    <UserInfoWrapper>
      <User>
        <UserInfoName>{username}</UserInfoName>
        {territory.length > 0 ? (
          <UserInfoDesignation>
            <b>Terr:</b> {territory}
          </UserInfoDesignation>
        ) : null}
      </User>
      {userDetails.role_id == 1 || userDetails.role_id == 2 ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="setting:1" onClick={() => redirectToAdmin()}>
                Admin
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
        >
          <Avatar
            className="userInfoIcon"
            size="large"
            icon={<UserOutlined />}
          />
        </Dropdown>
      ) : (
        <Avatar className="userInfoIcon" size="large" icon={<UserOutlined />} />
      )}
    </UserInfoWrapper>
  );
};

export default UserInfo;
