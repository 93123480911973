import { FileExcelOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Popover,
  Radio,
  Row,
  Spin,
  Switch,
  Table,
  Tabs,
} from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";
import TitleSearch from "../../../components/TitleSearch/TitleSearch";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import { GetAPIRequest, GetFileAPIRequest } from "../../../services/api";
import {
  getCurrCustomerType,
  getIndicationType,
  getIsCustomerTablePercent,
  getSelectedGeoTerriroties,
  setCurrCustomerType,
  setIsCustomerTablePercent,
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import CustomerFilter from "./CustomerFilter/CustomerFilter";
import {
  CardViewWrapper,
  CustomerListViewWrapper,
  TableButtonWrapper,
} from "./CustomerListView.styles";
import { Tag } from "antd";
import { useRef } from "react";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
var offset = 0;
var totalCount = 0;
var loadedRecords = 0;
var limit = 100;
var isLimitReached = false;
var currTblData = [];
var hasMoreData = true;
const CustomerListView = (props) => {
  var datesStr = sessionStorage.getItem("customerTblDateRange");
  var datesStrArr = [];
  if (datesStr) {
    datesStrArr = datesStr.split(",");
  }
  if (datesStrArr && datesStrArr.length <= 0) {
    var todayDate = moment().format("YYYY-MM-DD");
    var startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
    datesStrArr = [startDate, todayDate];
    sessionStorage.setItem("customerTblDateRange", `${startDate},${todayDate}`);
  }
  const [dateRange, setDateRange] = useState([
    moment(datesStrArr[0]).isValid() ? moment(datesStrArr[0], dateFormat) : "",
    moment(datesStrArr[1]).isValid() ? moment(datesStrArr[1], dateFormat) : "",
  ]);
  const [dateRangeStr, setDateRangeStr] = useState([
    datesStrArr[0],
    datesStrArr[1],
  ]);
  const [hcpTblInfo, setHcpTblInfo] = useState();
  const [currentTblColumns, setCurrentTblColumns] = useState([]);
  const [currentTblExportColumns, setCurrentTblExportColumns] = useState([]);
  const [currentTblData, setCurrentTblData] = useState([]);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const dispatch = useDispatch();
  const [customerType, setCustomerType] = useState("HCP");
  const [searchKey, setSearchKey] = useState("");
  const [isListLoading, setIsListLoading] = useState(true);
  const isCustomerTablePercent = useSelector(getIsCustomerTablePercent);
  const [isCustomerTblPercent, setIsCustomerTblPercent] = useState(true);
  const navigate = useNavigate();
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  const [filterData, setFilterData] = useState(null);
  const [updateFilterData, setUpdatedFilterData] = useState({});
  const [isAllFilteredRemoved, setIsAllFilteredRemoved] = useState();

  //const [limit, setLimit] = useState(50);
  //const [offSet, setOffset] = useState(0);
  //const [totalCount, setTotalCount] = useState(0);
  // const [loadedRecords, setLoadedRecords] = useState(0);
  const [sort, setSort] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [isHCPAccountSearch, setIsHCPAccountSearch] = useState(false);
  const custmerChildRef = useRef();

  useEffect(() => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    const handleScroll = () => {
      const perc =
        (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;
      if (perc >= 100 && hasMoreData == true) {
        if (!isLimitReached) {
          console.log("perc", perc);
          isLimitReached = true;
          offset = offset + limit;
          loadData();
        }
        //setOffset(offSet => offSet + 50);

        console.log(
          "TODO: Scrolling has reached bottom, load more data, or do whatever...",
          offset
        );
      }
    };
    if (node) {
      node.addEventListener("scroll", handleScroll);
    }
    return () => {
      node.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    if (node) {
      node.scrollTo(0, 0);
    }
    offset = 0;
    totalCount = 0;
    loadedRecords = 0;
    setSearchKey("");
    setCurrentTblData([]);
    // if (isFilterApplied) {
    //   getCustomerListByFilter();
    // }
    // else
    //getCustomerListByFilter()
    if (isFilterApplied) {
      getCustomerListByFilter();
    } else {
      getCustomerList1();
    }
    
    
  }, [indicationType, selectedGeoTerriroties, dateRange, customerType]);

  useEffect(() => { 
    setIsCustomerTblPercent(isCustomerTablePercent);
  }, [isCustomerTablePercent]);
  
  useEffect(() => {
    updateColumns();
  }, [isCustomerTblPercent]);

  const updateColumns = () => {
    if (isCustomerTablePercent) {
      if (customerType == "HCP") {
        setCurrentTblColumns(hcpTblColumnsPercent);
      } else if (customerType == "Parent") {
        setCurrentTblColumns(accountTblColumnsPercent);
      } else if (customerType == "TopParent") {
        setCurrentTblColumns(parentAccountTblColumnsPercent);
      }
    } else {
      if (customerType == "HCP") {
        setCurrentTblColumns(hcpTblColumns);
      } else if (customerType == "Parent") {
        setCurrentTblColumns(accountTblColumns);
      } else if (customerType == "TopParent") {
        setCurrentTblColumns(parentAccountTblColumns);
      }
    }
  };

  const loadData = () => {
    if (searchKey == "") {
      console.log("currTblData", currTblData);
      if (isFilterApplied) {
        getCustomerListByFilter1();
      } else getCustomerList();
    } else {
      searchCustomerList(searchKey);
    }
  };

  const handleSearch = (searchText) => {
    if (searchText == "") setIsHCPAccountSearch(false);
    else setIsHCPAccountSearch(true);
    //custmerChildRef.current.clearAll();
    const node = document.querySelector(".custhcptbl .ant-table-body");
    if (node) {
      node.scrollTo(0, 0);
    }
    totalCount = 0;
    offset = 0;
    loadedRecords = 0;
    setCurrentTblData([]);
    searchCustomerList(searchText);
  };
  const getCustomerList1 = () => {
    setIsListLoading(true);
    var data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isCustomerTblPercent,
      accountType: customerType,
      fromDt: dateRangeStr[0],
      toDt: dateRangeStr[1],
      limit: limit,
      offSet: offset,
    };
    if (sort && sort.key != "") {
      data.sort = sort;
    }
    PostAPIRequest({
      url: `${CONFIG.api.getCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            setHcpTblInfo(response.data);
            loadedRecords = loadedRecords + limit;
            //setCurrentTblData(response.data);
            setCurrentTblData(response.data);
            hasMoreData = response.hasMore;
            currTblData = [...response.data];
            totalCount = response.totalCount;
            updateColumns();
          }
        }
        isLimitReached = false;
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        isLimitReached = false;
        setIsListLoading(false);
      });
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const getCustomerList = () => {
    console.log("getCustomerList");
    var data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isCustomerTblPercent,
      accountType: customerType,
      fromDt: dateRangeStr[0],
      toDt: dateRangeStr[1],
      limit: limit,
      offSet: offset,
    };
    if (sort && sort.key != "") {
      data.sort = sort;
    }

    setIsListLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            loadedRecords = loadedRecords + limit;
            currTblData.push(...response.data);
            console.log("currTblData-", currTblData);
            totalCount = response.totalCount;
            hasMoreData = response.hasMore;
            setCurrentTblData([]);
            setCurrentTblData(currTblData);
          }
        }
        isLimitReached = false;
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        isLimitReached = false;
        setIsListLoading(false);
      });
  };
  const searchCustomerList = (searchKey) => {
    setIsListLoading(true);
    var data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isCustomerTblPercent,
      accountType: customerType,
      fromDt: dateRangeStr[0],
      toDt: dateRangeStr[1],
      q: searchKey,
      limit: limit,
      offSet: offset,
    };
    if (sort && sort.key != "") {
      data.sort = sort;
    }
    PostAPIRequest({
      //url: `${CONFIG.api.getCustomerList}?searchText=${searchKey}`,
      url: `${CONFIG.api.getCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            currTblData = [...response.data];
            setCurrentTblData(response.data);
            totalCount = response.totalCount;
            hasMoreData = response.hasMore;
            loadedRecords = loadedRecords + limit;
          }
        }
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
    /*PostAPIRequest({
      url: `${CONFIG.api.getPrescriberDetailsById1}/${providerId}/uniqueTriggerId/${triggerid}/internal`,
    }).then((response) => {
      if (response) {
        var r = checkUnauthorised(response);
        if (!r.status) {
          setPrescriberInfo(response);
          setPriority(response.priority);
          setSegmentation(response.segmentation);
          loadFunnel(response);
          if (response.dueDate) {
            setTriggerDate(response.dueDate);
          }
        }
      }
    });
  */
  };
  const downloadFile = (guid) => {
    GetFileAPIRequest({
      url: `${CONFIG.api.exportCustomerList}/guid/${guid}`,
    })
      .then((response) => {
        setIsListLoading(false);
        var r = checkUnauthorised(response.data);
        if (!r.status) {
          //const [, filename] = response.headers['content-disposition'].split('filename=');
          //console.log(response);
          const temp = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = temp;
          if(customerType == "HCP"){
            link.setAttribute("download", `HCP.csv`);
          } else if(customerType == "Parent"){
            link.setAttribute("download", `Account.csv`);
          } else if(customerType == "TopParent"){
            link.setAttribute("download", `Parent.csv`);
          } 
          
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkfileprogress = (guid) => {
    GetAPIRequest({
      url: `${CONFIG.api.exportCustomerList}/guid/${guid}`,
    })
      .then((response) => {
        if (response.message) {
          setTimeout(() => {
            checkfileprogress(guid);
          }, 5000);
        } else {
          downloadFile(guid);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
  };
  const exportCustomerList = () => {
    setIsListLoading(true);
    var _cctype = customerType; //sessionStorage.getItem("currCustomerType");
    var selectedTerritories = sessionStorage.getItem("territories") || "";
    var _data = {
      tumorType: indicationType,
      territories: selectedGeoTerriroties,
      isPercentage: isCustomerTblPercent,
      accountType: customerType,
      fromDt: dateRangeStr[0],
      toDt: dateRangeStr[1],
      q: searchKey,
      limit: limit,
      offSet: offset
    };
    var data = {};
    if(isFilterApplied){
      data = {..._data, ...filterData};
    } else {
      data = _data;
    }
    if (sort && sort.key != "") {
      data.sort = sort;
    }
    PostAPIRequest({
      url:`${CONFIG.api.exportCustomerListV2}`,
      data: data
    }).then((response) => {
      if (response && response.guid) {
        checkfileprogress(response.guid);
      }
      //console.log(response.headers);
    })
    .catch((error) => {
      console.log(error);
      setIsListLoading(false);
    });
    /*GetAPIRequest({
      url: `${CONFIG.api.exportCustomerList}?accountType=${_cctype}&fromDt=${dateRangeStr[0]}&toDt=${dateRangeStr[1]}&tumorType=${indicationType}&q=${searchKey}&territories=${selectedTerritories}&isPercentage=${isCustomerTblPercent}`,
    })
      .then((response) => {
        if (response && response.guid) {
          checkfileprogress(response.guid);
        }
        console.log(response.headers);
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });*/
  };
  const sortCustomerList = (type, key, isSort) => {
    setIsListLoading(true);
    PostAPIRequest({
      // url: `${CONFIG.api.getCustomerList}?searchText=${searchKey}`,
      url: `${CONFIG.api.getCustomerList}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        isPercentage: isCustomerTblPercent,
        accountType: customerType,
        fromDt: dateRangeStr[0],
        toDt: dateRangeStr[1],
        q: searchKey,
        limit: limit,
        offSet: offset,
        sort: sort,
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            if (isSort) {
              currTblData = [];
              currTblData = [...response.data];
              setCurrentTblData([]);
              setCurrentTblData(response.data);
            } else {
              currTblData.push(...response.data);
              console.log("currTblData-", currTblData);
              //setCurrentTblData([...currentTblData, ...response.data]);
              setCurrentTblData([]);
              setCurrentTblData(currTblData);
            }
            totalCount = response.totalCount;
            hasMoreData = response.hasMore;
            loadedRecords = loadedRecords + limit;
          }
        }
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
  };
  const goToDetailView = (id) => {
    var _cctype = customerType; //sessionStorage.getItem("currCustomerType");
    if (_cctype == "HCP") {
      navigate(`/physician/${id}`);
    } else if (_cctype == "TopParent") {
      navigate(`/parent-account/${id}`);
    } else if (_cctype == "Parent") {
      navigate(`/account/${id}`);
    }
  };

  const hcpTblColumns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 100,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // sorter: (a, b) => a.id - b.id,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      key: "cllPriority",
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: "mclPriority",
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: "evusheldPriority",
      title: "Evusheld Priority",
      dataIndex: "evusheldPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.evusheldPriority.length - b.evusheldPriority.length,
    },
    {
      key: "inFocusAccount",
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      key: "spUnits",
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: "spNps",
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      key: "calNpsCount",
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNpsCount",
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      key: "bruNpsCount",
      title: "Zanu\nNPS",
      dataIndex: "bruNpsCount",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      key: "ibrNpsCount",
      title: "Ibru\nNPS",
      dataIndex: "ibrNpsCount",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    {
      key: "venNpsCount",
      title: "Ven\nNPS",
      dataIndex: "venNpsCount",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      key: "citNpsCount",
      title: "CIT\nNPS",
      dataIndex: "citNpsCount",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      key: "reached",
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.reached - b.reached,
    },
    {
      title: "Click for details",
      key: "operation",
      width: 100,
      fixed: "right",
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  const hcpTblColumnsPercent = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 100,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      // sorter: (a, b) => a.id - b.id,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //sorter: (a, b) => a.name.length - b.name.length,
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      key: "cllPriority",
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: "mclPriority",
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: "evusheldPriority",
      title: "Evusheld Priority",
      dataIndex: "evusheldPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.evusheldPriority.length - b.evusheldPriority.length,
    },
    {
      key: "inFocusAccount",
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      key: "spUnits",
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: "spNps",
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      key: "calNps",
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNps",
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      key: "bruNps",
      title: "Zanu\nNPS",
      dataIndex: "bruNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      key: "ibrNps",
      title: "Ibru\nNPS",
      dataIndex: "ibrNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    {
      key: "venNps",
      title: "Ven\nNPS",
      dataIndex: "venNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      key: "citNps",
      title: "CIT\nNPS",
      dataIndex: "citNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      key: "reached",
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.reached - b.reached,
    },
    {
      title: "Click for details",
      key: "operation",
      width: 100,
      fixed: "right",
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  const accountTblColumns = [
    {
      key: 0,
      title: "ID",
      dataIndex: "id",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //sorter: (a, b) => a.id - b.id,
    },
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      key: 2,
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: 3,
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      ///   sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: 5,
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      key: 4,
      title: "SD\nUnits",
      dataIndex: "sdUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.sdUnits - b.sdUnits,
    },
    {
      key: 6,
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 64,
      title: "SP+SD Units",
      dataIndex: "totalUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 7,
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      key: 8,
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNpsCount",
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      key: 9,
      title: "Zanu\nNPS",
      dataIndex: "bruNpsCount",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      key: 10,
      title: "Ibru\nNPS",
      dataIndex: "ibrNpsCount",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    {
      key: 12,
      title: "Ven\nNPS",
      dataIndex: "venNpsCount",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      key: 13,
      title: "CIT\nNPS",
      dataIndex: "citNpsCount",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      key: 14,
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.reached - b.reached,
    },
    {
      key: 15,
      title: "Click for details",
      fixed: "right",
      key: "operation",
      width: 100,
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  const accountTblColumnsPercent = [
    {
      key: 0,
      title: "ID",
      dataIndex: "id",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      //sorter: (a, b) => a.id - b.id,
    },
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      key: 2,
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: 3,
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      ///   sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: 5,
      title: "In Focus Account",
      dataIndex: "inFocusAccount",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.inFocusAccount.length - b.inFocusAccount.length,
    },
    {
      key: 4,
      title: "SD\nUnits",
      dataIndex: "sdUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.sdUnits - b.sdUnits,
    },
    {
      key: 6,
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 64,
      title: "SP+SD Units",
      dataIndex: "totalUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 7,
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spNps - b.spNps,
    },
    {
      key: 8,
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNps",
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.calNps - b.calNps,
    },
    {
      key: 9,
      title: "Zanu\nNPS",
      dataIndex: "bruNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.bruNps - b.bruNps,
    },
    {
      key: 10,
      title: "Ibru\nNPS",
      dataIndex: "ibrNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
    },
    {
      key: 12,
      title: "Ven\nNPS",
      dataIndex: "venNps",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.venNps - b.venNps,
    },
    {
      key: 13,
      title: "CIT\nNPS",
      dataIndex: "citNps",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.citNps - b.citNps,
    },
    {
      key: 14,
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      // sorter: (a, b) => a.reached - b.reached,
    },
    {
      key: 15,
      title: "Click for details",
      fixed: "right",
      key: "operation",
      width: 100,
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  const parentAccountTblColumns = [
    {
      key: 0,
      title: " ID",
      dataIndex: "id",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
      width: 120,
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: 4,
      title: "SD\nUnits",
      dataIndex: "sdUnits",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.sdUnits - b.sdUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 6,
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.spUnits - b.spUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 64,
      title: "SP+SD Units",
      dataIndex: "totalUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 7,
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      //sorter: (a, b) => a.spNps - b.spNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 8,
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNpsCount",
      sorter: true,
      //sorter: (a, b) => a.calNps - b.calNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 9,
      title: "Zanu\nNPS",
      dataIndex: "bruNpsCount",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.bruNps - b.bruNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 10,
      title: "Ibru\nNPS",
      dataIndex: "ibrNpsCount",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 12,
      title: "Ven\nNPS",
      dataIndex: "venNpsCount",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.venNps - b.venNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 13,
      title: "CIT\nNPS",
      dataIndex: "citNpsCount",
      width: 60,
      sorter: true,
      //sorter: (a, b) => a.citNps - b.citNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 14,
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      //sorter: (a, b) => a.reached.length - b.reached.length,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 15,
      title: "Click for details",
      fixed: "right",
      key: "operation",
      width: 100,
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  const parentAccountTblColumnsPercent = [
    {
      key: 0,
      title: " ID",
      dataIndex: "id",
      width: 120,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: 1,
      title: "Name",
      dataIndex: "name",
      width: 120,
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: 4,
      title: "SD\nUnits",
      dataIndex: "sdUnits",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.sdUnits - b.sdUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 6,
      title: "SP\nUnits",
      dataIndex: "spUnits",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.spUnits - b.spUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 64,
      title: "SP+SD Units",
      dataIndex: "totalUnits",
      width: 70,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //sorter: (a, b) => a.spUnits - b.spUnits,
    },
    {
      key: 7,
      title: "SP\nNPS",
      dataIndex: "spNps",
      width: 60,
      sorter: true,
      //sorter: (a, b) => a.spNps - b.spNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 8,
      title: "Cal\nNPS",
      width: 60,
      dataIndex: "calNps",
      sorter: true,
      //sorter: (a, b) => a.calNps - b.calNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 9,
      title: "Zanu\nNPS",
      dataIndex: "bruNps",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.bruNps - b.bruNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 10,
      title: "Ibru\nNPS",
      dataIndex: "ibrNps",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.ibrNps - b.ibrNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 12,
      title: "Ven\nNPS",
      dataIndex: "venNps",
      width: 70,
      sorter: true,
      //sorter: (a, b) => a.venNps - b.venNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 13,
      title: "CIT\nNPS",
      dataIndex: "citNps",
      width: 60,
      sorter: true,
      //sorter: (a, b) => a.citNps - b.citNps,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 14,
      title: "Reached?",
      dataIndex: "reached",
      width: 100,
      sorter: true,
      //sorter: (a, b) => a.reached.length - b.reached.length,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      key: 15,
      title: "Click for details",
      fixed: "right",
      key: "operation",
      width: 100,
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    if (node) {
      node.scrollTo(0, 0);
    }
    totalCount = 0;
    offset = 0;
    loadedRecords = 0;
    //setCurrentTblData([]);

    if (Array.isArray(sorter) && sorter.length > 0) {
      let ordertype =
        sorter.order == "ascend"
          ? "asc"
          : sorter.order == "descend"
          ? "desc"
          : "";
      var _sort = [];
      sorter.map((_f) => {
        let ordertype =
          _f.order == "ascend" ? "asc" : _f.order == "descend" ? "desc" : "";
        _sort.push({
          key: _f.field,
          order: ordertype,
        });
      });
      setSort(_sort);
      //sortCustomerList(ordertype, sorter.field, true);
    } else if (sorter.order) {
      let ordertype =
        sorter.order == "ascend"
          ? "asc"
          : sorter.order == "descend"
          ? "desc"
          : "";
      setSort({
        key: sorter.field,
        order: ordertype,
      });
      //sortCustomerList(ordertype, sorter.field, true);
    } else {
      if (isFilterApplied) getCustomerListByFilter();
      else getCustomerList1();
      //getCustomerList1()
    }
  };

  useEffect(() => {
    if (sort) {
      if (isFilterApplied) getCustomerListByFilter();
      else sortCustomerList("", "", true);
    }
  }, [sort]);

  useEffect(() => {
    const node = document.querySelector(".custhcptbl .ant-table-body");
    if (node) {
      node.scrollTo(0, 0);
    }

    offset = 0;
    totalCount = 0;
    loadedRecords = 0;

    setCurrentTblData([]);
    console.log("updatefilterdata", updateFilterData);

    const isEmpty = Object.values(updateFilterData).every(
      (x) => x === null || x === "" || x === undefined || x.length === 0
    );
    if (isEmpty) setSort(null);
    setIsAllFilteredRemoved(isEmpty);

    if (!isHCPAccountSearch) {
      if (isFilterApplied) {
        getCustomerListByFilter();
      } else {
        //getCustomerList1();
      }
    }
  }, [filterData]);

  const isEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
  };

  const getCustomerListByFilter = (data) => {
    if (data == undefined) {
      data = filterData;
    }
    data.accountType = customerType;
    data.tumorType = indicationType;
    data.territories = selectedGeoTerriroties;
    data.fromDt = dateRangeStr[0];
    data.toDt = dateRangeStr[1];
    data.limit = limit;
    data.offSet = offset;
    data.isPercentage = isCustomerTblPercent;
    setFilterData(data);
    if (sort && sort.key != "") {
      data.sort = sort;
    }
    setIsListLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getFilteredCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            setHcpTblInfo(response.data);
            loadedRecords = loadedRecords + limit;

            setCurrentTblData(response.data);
            currTblData = [...response.data];
            totalCount = response.totalCount;
            hasMoreData = response.hasMore;
            updateColumns();
          }
        }
        isLimitReached = false;
        setIsListLoading(false);
      })
      .catch((err) => {});
  };

  const getCustomerListByFilter1 = (data) => {
    //setIsFilterApplied(true);
    if (data == undefined) {
      data = filterData;
    }
    data.accountType = customerType;
    data.tumorType = indicationType;
    data.territories = selectedGeoTerriroties;
    data.fromDt = dateRangeStr[0];
    data.toDt = dateRangeStr[1];
    data.limit = limit;
    data.offSet = offset;
    data.isPercentage = isCustomerTblPercent;
    if (sort && sort.key != "") {
      data.sort = sort;
    }
    setIsListLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getFilteredCustomerList}`,
      data: data,
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            response.data.map((res) => {
              res.key = makeid(12);
            });
            console.log(response);
            loadedRecords = loadedRecords + limit;
            currTblData.push(...response.data);
            console.log("currTblData-", currTblData);
            totalCount = response.totalCount;
            hasMoreData = response.hasMore;
            setCurrentTblData([]);
            setCurrentTblData(currTblData);
          }
        }
        isLimitReached = false;
        setIsListLoading(false);
      })
      .catch((err) => {});
  };
  const updatePercentage = (value) => {
    setIsCustomerTblPercent(value);
  };

  const setFilterDataOnChange = (data) => {
    setIsFilterApplied(true);
    setFilterData(data);
    let updateData = { ...data };
    setUpdatedFilterData(updateData);
  };
  const clearAll = (data) => {
    setIsFilterApplied(false);
    data.accountType = customerType;
    data.tumorType = indicationType;
    data.territories = selectedGeoTerriroties;
    data.fromDt = dateRangeStr[0];
    data.toDt = dateRangeStr[1];
    data.limit = limit;
    data.offSet = offset;
    data.isPercentage = isCustomerTblPercent;
    data.names = [];
    data.cll_priorities = [];
    data.mcl_priorities = [];
    data.evushield_priorities = [];
    data.focused_Account = "";
    data.sp_trx_value = "";
    data.sp_nps_value = "";
    data.sp_trx = "";
    data.sp_nps = "";
    data.acalabrutinib = "";
    data.zanubrutinib = "";
    data.ibrutinib = "";
    data.venetoclax = "";
    data.cit = "";
    data.call_reach = "";
    data.acalabrutinib_value = "";
    data.zanubrutinib_value = "";
    data.ibrutinib_value = "";
    data.venetoclax_value = "";
    data.cit_value = "";
    setFilterData(data);
  };

  return (
    <CustomerListViewWrapper>
      <CardViewWrapper>
        <Spin spinning={isListLoading}>
          <Row>
            <Col xs={12}>
              <div className="timelineHeader">
                <Popover
                  placement="right"
                  content={
                    <div style={{ maxWidth: 500 }}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `<h3>HCPs, Accounts & Parent Accounts</h3><ul><li>HCPs - Claims and SP data at HCP level</li><li>Accounts - SD data at Account Level</li><li>Parent Accounts - Contracting focus / Storytelling</li><li>SP (Specialty Pharmacy) and SD (Specialty Distributor) data from Matrix.</li></ul>`,
                        }}
                      ></p>
                    </div>
                  }
                  title={""}
                  trigger={"click"}
                >
                  Customer List <InfoCircleOutlined />
                </Popover>
              </div>
            </Col>

            <Col xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
               {/* <CustomerFilter
                  ref={custmerChildRef}
                  indicationType={indicationType}
                  customerType={customerType}
                  // getCustomerListByFilter={getCustomerListByFilter}
                  setFilterDataOnChange={setFilterDataOnChange}
                  isCustomerTblPercent={isCustomerTblPercent}
                  dateRange={dateRangeStr}
                  clearAll={clearAll}
                />*/}

                <Switch
                  checkedChildren="Percentage"
                  unCheckedChildren="Percentage"
                  checked={isCustomerTblPercent}
                  onChange={(checked) => {
                    //setIsCustomerTblPercent(checked);
                    dispatch(setIsCustomerTablePercent(checked));
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: 5,
                  marginTop: 15,
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginLeft: 0, marginBottom: 10 }}>
                  <Radio.Group
                    value={customerType}
                    onChange={(e) => {
                      setCurrentTblData([]);
                      dispatch(setCurrCustomerType(e.target.value));
                      setCustomerType(e.target.value);
                    }}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="HCP">HCPs</Radio.Button>
                    <Radio.Button value="Parent">Accounts</Radio.Button>
                    <Radio.Button value="TopParent">
                      Parent Accounts
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  <RangePicker
                    picker="week"
                    allowEmpty={[false, false]}
                    allowClear={false}
                    inputReadOnly={true}
                    value={dateRange}
                    disabledDate={(current) => {
                      return current > moment();
                    }}
                    onChange={(dates, datesStr) => {
                      console.log(datesStr);
                      if (datesStr[0] == "" && datesStr[1] == "") {
                        sessionStorage.setItem("customerTblDateRange", "");
                      } else {
                        sessionStorage.setItem(
                          "customerTblDateRange",
                          datesStr
                        );
                      }
                      setDateRange(dates);
                      setDateRangeStr(datesStr);
                    }}
                    format={dateFormat}
                  />
                </div>

                <TitleSearch
                  value={searchKey}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  onSearch={handleSearch}
                />
                <div
                  style={{
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#294360",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      exportCustomerList();
                      /*const excel = new Excel();
                      excel.setRowHeight(20);
                      excel.setTHeadStyle({
                        background: "FFB4C6E7",
                        color: "FF000000",
                        "text-align": "center",
                      });
   
                      excel
                        .addSheet("HCPs")
                        .addColumns(currentTblExportColumns)
                        .addDataSource(currentTblData)
                        .saveAs("HCPs.xlsx");*/
                    }}
                  >
                    Export
                    <FileExcelOutlined />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {isFilterApplied && !isAllFilteredRemoved && (
            <Row>
              <Col xs={24}>
                <div className="account-filter-details">
                  {filterData != null && (
                    <div>
                      {filterData.names.length > 0 && (
                        <div>
                          {customerType == "HCP" && (
                            <span className="filter-title">
                              Selected HCPs :{" "}
                            </span>
                          )}
                          {customerType == "Parent" && (
                            <span className="filter-title">
                              Selected Accounts :{" "}
                            </span>
                          )}
                          {customerType == "TopParent" && (
                            <span className="filter-title">
                              Selected Parent Accounts :{" "}
                            </span>
                          )}

                          {filterData.names.map((item, index) => {
                            return (
                              <Tag
                                key={item}
                                closable
                                onClose={() => {
                                  custmerChildRef.current.updateFilters(
                                    "ACCOUNT",
                                    item
                                  );
                                  setFilterData({
                                    ...filterData,
                                    names: filterData.names.filter(
                                      (x) => x != item
                                    ),
                                  });
                                  setUpdatedFilterData({
                                    ...updateFilterData,
                                    names: updateFilterData.names.filter(
                                      (x) => x != item
                                    ),
                                  });
                                }}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                        </div>
                      )}

                      {filterData.cll_priorities.length > 0 && (
                        <div>
                          <span className="filter-title">CLL Priorities: </span>
                          {filterData.cll_priorities.map((item, index) => {
                            return (
                              <Tag
                                closable
                                onClose={() => {
                                  custmerChildRef.current.updateFilters(
                                    "CLL",
                                    item
                                  );
                                  setFilterData({
                                    ...filterData,
                                    cll_priorities: filterData.cll_priorities.filter(
                                      (x) => x != item
                                    ),
                                  });
                                  setUpdatedFilterData({
                                    ...updateFilterData,
                                    cll_priorities: updateFilterData.cll_priorities.filter(
                                      (x) => x != item
                                    ),
                                  });
                                }}
                              >
                                {item}
                              </Tag>
                            );
                          })}
                        </div>
                      )}

                      {filterData.mcl_priorities.length > 0 && (
                        <div>
                          <span className="filter-title">MCL Priorities: </span>
                          {filterData.mcl_priorities &&
                            filterData.mcl_priorities.map((item, index) => {
                              return (
                                <Tag
                                  closable
                                  onClose={() => {
                                    custmerChildRef.current.updateFilters(
                                      "MCL",
                                      item
                                    );
                                    setFilterData({
                                      ...filterData,
                                      mcl_priorities: filterData.mcl_priorities.filter(
                                        (x) => x != item
                                      ),
                                    });
                                    setUpdatedFilterData({
                                      ...updateFilterData,
                                      mcl_priorities: updateFilterData.mcl_priorities.filter(
                                        (x) => x != item
                                      ),
                                    });
                                  }}
                                >
                                  {item}
                                </Tag>
                              );
                            })}
                        </div>
                      )}
                      {filterData.evushield_priorities.length > 0 && (
                        <div>
                          <span className="filter-title">
                            Evushield Priorities:{" "}
                          </span>
                          {filterData.evushield_priorities &&
                            filterData.evushield_priorities.map(
                              (item, index) => {
                                return (
                                  <Tag
                                    closable
                                    onClose={() => {
                                      custmerChildRef.current.updateFilters(
                                        "EVUSHELD",
                                        item
                                      );
                                      setFilterData({
                                        ...filterData,
                                        evushield_priorities: filterData.evushield_priorities.filter(
                                          (x) => x != item
                                        ),
                                      });
                                      setUpdatedFilterData({
                                        ...updateFilterData,
                                        evushield_priorities: updateFilterData.evushield_priorities.filter(
                                          (x) => x != item
                                        ),
                                      });
                                    }}
                                  >
                                    {item}
                                  </Tag>
                                );
                              }
                            )}
                        </div>
                      )}

                      {filterData.sp_trx && filterData.sp_trx_value && (
                        <div>
                          <span className="filter-title">SP Units </span>
                          {
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "SPUNITS",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  sp_trx: undefined,
                                  sp_trx_value: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  sp_trx: undefined,
                                  sp_trx_value: undefined,
                                });
                              }}
                            >
                              Type: {filterData.sp_trx} | value:
                              {filterData.sp_trx_value}
                            </Tag>
                          }
                        </div>
                      )}
                      {filterData.sp_nps && filterData.sp_nps_value && (
                        <div>
                          <span className="filter-title">SP NPS: </span>
                          {
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "SPNPS",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  sp_nps: undefined,
                                  sp_nps_value: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  sp_nps: undefined,
                                  sp_nps_value: undefined,
                                });
                              }}
                            >
                              Type: {filterData.sp_nps} | value:
                              {filterData.sp_nps_value}
                            </Tag>
                          }
                        </div>
                      )}
                      {filterData.sd_trx && filterData.sd_trx_value && (
                        <div>
                          <span className="filter-title">SD Units: </span>
                          {
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "SDUNITS",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  sd_trx: undefined,
                                  sd_trx_value: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  sd_trx: undefined,
                                  sd_trx_value: undefined,
                                });
                              }}
                            >
                              Type: {filterData.sd_trx} | value:
                              {filterData.sd_trx_value}
                            </Tag>
                          }
                        </div>
                      )}
                      {filterData.focused_Account && (
                        <div>
                          <span className="filter-title">
                            In focus Account:{" "}
                          </span>
                          {
                            // filterData.focused_Account.map((item, index) => {
                            //   return (
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "FOCUSACCOUNT",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  focused_Account: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  focused_Account: undefined,
                                });
                              }}
                            >
                              {filterData.focused_Account}
                            </Tag>
                            //   )
                            // })
                          }
                        </div>
                      )}
                      {filterData.call_reach && (
                        <div>
                          <span className="filter-title">Reached: </span>
                          {
                            // filterData.call_reach.map((item, index) => {
                            //   return (
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "CALLREACH",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  call_reach: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  call_reach: undefined,
                                });
                              }}
                            >
                              {filterData.call_reach}
                            </Tag>
                            //   )
                            // })
                          }
                        </div>
                      )}

                      {filterData.acalabrutinib &&
                        filterData.acalabrutinib_value && (
                          <div>
                            <span className="filter-title">Cal NPS: </span>
                            {
                              <Tag
                                closable
                                onClose={() => {
                                  custmerChildRef.current.updateFilters(
                                    "CALNPS",
                                    ""
                                  );
                                  setFilterData({
                                    ...filterData,
                                    acalabrutinib: undefined,
                                    acalabrutinib_value: undefined,
                                  });
                                  setUpdatedFilterData({
                                    ...updateFilterData,
                                    acalabrutinib: undefined,
                                    acalabrutinib_value: undefined,
                                  });
                                }}
                              >
                                Type: {filterData.acalabrutinib} | value:
                                {filterData.acalabrutinib_value}
                              </Tag>
                            }
                          </div>
                        )}
                      {filterData.zanubrutinib &&
                        filterData.zanubrutinib_value && (
                          <div>
                            <span className="filter-title">Zanu NPS: </span>
                            {
                              <Tag
                                closable
                                onClose={() => {
                                  custmerChildRef.current.updateFilters(
                                    "ZANUNPS",
                                    ""
                                  );
                                  setFilterData({
                                    ...filterData,
                                    zanubrutinib: undefined,
                                    zanubrutinib_value: undefined,
                                  });
                                  setUpdatedFilterData({
                                    ...updateFilterData,
                                    zanubrutinib: undefined,
                                    zanubrutinib_value: undefined,
                                  });
                                }}
                              >
                                Type: {filterData.zanubrutinib} | value:
                                {filterData.zanubrutinib_value}
                              </Tag>
                            }
                          </div>
                        )}
                      {filterData.ibrutinib && filterData.ibrutinib_value && (
                        <div>
                          <span className="filter-title">Ibru NPS : </span>
                          {
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "IBRUNPS",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  ibrutinib: undefined,
                                  ibrutinib_value: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  ibrutinib: undefined,
                                  ibrutinib_value: undefined,
                                });
                              }}
                            >
                              Type: {filterData.ibrutinib} | value:
                              {filterData.ibrutinib_value}
                            </Tag>
                          }
                        </div>
                      )}
                      {filterData.venetoclax && filterData.venetoclax_value && (
                        <div>
                          <span className="filter-title">Ven NPS: </span>
                          {
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "VENNPS",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  venetoclax: undefined,
                                  venetoclax_value: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  venetoclax: undefined,
                                  venetoclax_value: undefined,
                                });
                              }}
                            >
                              Type: {filterData.venetoclax} | value:
                              {filterData.venetoclax_value}
                            </Tag>
                          }
                        </div>
                      )}
                      {filterData.cit && filterData.cit_value && (
                        <div>
                          <span className="filter-title">CIT NPS: </span>
                          {
                            <Tag
                              closable
                              onClose={() => {
                                custmerChildRef.current.updateFilters(
                                  "CITNPS",
                                  ""
                                );
                                setFilterData({
                                  ...filterData,
                                  cit: undefined,
                                  cit_value: undefined,
                                });
                                setUpdatedFilterData({
                                  ...updateFilterData,
                                  cit: undefined,
                                  cit_value: undefined,
                                });
                              }}
                            >
                              Type: {filterData.cit} | value:
                              {filterData.cit_value}
                            </Tag>
                          }
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24}>
              <Table
                className="custhcptbl"
                showSorterTooltip={false}
                columns={currentTblColumns}
                dataSource={currentTblData}
                pagination={false}
                scroll={{
                  y: window.innerHeight - 330,
                  x: window.innerWidth - 40,
                  scrollToFirstRowOnChange: false,
                }}
                onChange={handleTableChange}
              />
            </Col>
          </Row>
        </Spin>
      </CardViewWrapper>
    </CustomerListViewWrapper>
  );
};

export default CustomerListView;
