import styled from "styled-components";

export const TimelineBlockWrapper = styled.div`
  width: 100%;
  .timelineWrapper {
    width: 100%;
    padding-bottom: 10px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
  }
  .timelineWrapper1 {
    width: 100%;
    padding-bottom: 10px;
    min-height: 100px;
    display: flex;
    flex-direction: row;
  }

  .downArrow {
    font-family: "BigNoodleTitle";
    font-size: 26px;
    position: relative;
    background: #0070c2;
    width: 50px;
    min-height: 100px;
    text-align: center;
    padding-top: 30px;
    color: #ffffff;
    line-height: 36px;
    transform: rotate(180deg);
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .downArrow .title {
    font-size: 32px;
    font-stretch: expanded;
    transform: rotate(180deg);
  }

  .downArrow .subTitle {
    font-size: 22px;
    font-stretch: expanded;
    transform: rotate(180deg);
  }

  .downArrowAfter {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 100%;
    width: 0;
    height: 0;
    border-top: 12px solid #0070c2;
    border-right: 25px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 25px solid transparent;
  }

  .downArrow:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 0px;
    width: 0;
    height: 0;
    border-top: 12px solid #ffffff;
    border-right: 25px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 25px solid transparent;
  }

  .leftBox {
    padding-top: 35px;
    flex: 1;
    min-height: 100px;
  }

  .rightBox {
    padding-top: 35px;
    min-height: 100px;
    flex: 1;
  }

  .rightBox .triangleLeft {
    float: left;
    width: 0;
    height: 0;
    margin: 50px auto;
    border-right: 20px solid #40789b;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }

  .rightBox .triggerDetails {
    width: 90%;
    border-radius: 15px;
    border: 2px solid #40789b;
    float: left;
    padding: 10px;
    word-break: break-all;
    font-size: 12px;
  }

  .rightBox .triggerDetails .triggerHeader {
    width: "100%";
    float: "left";
    padding-top: "5px";
  }
  /*.timelineWrapperH {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 33%;
	align-items: center;
	justify-content: center;
}

.rightArrowWrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 50px;
}

.rightArrow {
	font-family: 'BigNoodleTitle';
	font-size: 26px;
	position: relative;
	background: #0070c2;
	flex: 1;
	width: 100%;
	height: 50px;
	color: #FFFFFF;
	line-height: 36px;
	float: left;
	margin-right: 15px;
	align-items: center;
	display: flex;
    justify-content: center;
}

.rightArrow .title {
	font-size: 32px;
	padding: 0px 10px 0px 0px;
}

.rightArrow .subTitle {
	font-size: 22px;
	padding: 0px 10px 0px 0px ;
}

.rightArrowAfter {
	z-index: 100;
	width: 0;
	height: 0;
	content: '';
	display: block;
	position: absolute;
	left: 100%;
	top: 0;
	border-top: 25px solid transparent;
	border-right: 0px solid transparent;
	border-bottom: 25px solid transparent;
	border-left: 30px solid #0070c2;
	
}

.rightArrow:before {
	width: 0;
	height: 0;
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	border-top: 25px solid transparent;
	border-right: 0px solid transparent;
	border-bottom: 25px solid transparent;
	border-left: 30px solid #FFFFFF;
}

.topBox {
	max-width: 100%;
	display: flex;
    flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	height: 240px;
	transform: rotate(0deg);
	overflow: hidden;
	padding: 0px 10px;
}

.downBox {
	max-width: 100%;
	display: flex;
    flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	height: 240px;
	transform: rotate(0deg);
	overflow: hidden;
	padding: 0px 10px;
}*/
`;
