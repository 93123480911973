import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Popover, Row, Spin, Table, Tabs } from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";
import CONFIG from "../../../constants";
import { PostAPIRequest } from "../../../services";
import {
  getIndicationType,
  getSelectedGeoTerriroties,
} from "../../../store/appSlice";
import { checkUnauthorised } from "../../../utils/cookie-helper";
import {
  CardViewWrapper,
  CustomerListViewWrapper,
  TableButtonWrapper,
} from "./CustomerListView.styles";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const CustomerListView = (props) => {
  var datesStr = sessionStorage.getItem("customerTblDateRange");
  var datesStrArr = [];
  if (datesStr) {
    datesStrArr = datesStr.split(",");
  }
  if (datesStrArr && datesStrArr.length <= 0) {
    var todayDate = moment().format("YYYY-MM-DD");
    var startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
    datesStrArr = [startDate, todayDate];
    sessionStorage.setItem("customerTblDateRange", `${startDate},${todayDate}`);
  }
  const [dateRange, setDateRange] = useState([
    moment(datesStrArr[0]).isValid() ? moment(datesStrArr[0], dateFormat) : "",
    moment(datesStrArr[1]).isValid() ? moment(datesStrArr[1], dateFormat) : "",
  ]);
  let { accountId } = useParams();
  const [dateRangeStr, setDateRangeStr] = useState([
    datesStrArr[0],
    datesStrArr[1],
  ]);
  const [hcpTblInfo, setHcpTblInfo] = useState();
  const [currentTblColumns, setCurrentTblColumns] = useState([]);
  const [currentTblData, setCurrentTblData] = useState([]);
  const [customerType, setCustomerType] = useState("");
  const [isListLoading, setIsListLoading] = useState(true);
  const [isCustomerTblPercent, setIsCustomerTblPercent] = useState(false);
  const navigate = useNavigate();
  const indicationType = useSelector(getIndicationType);
  const selectedGeoTerriroties = useSelector(getSelectedGeoTerriroties);
  useEffect(() => {
    getCustomerList();
  }, [
    indicationType,
    selectedGeoTerriroties,
    isCustomerTblPercent,
    customerType,
    dateRange,
  ]);

  const getCustomerList = () => {
    setIsListLoading(true);
    PostAPIRequest({
      url: `${CONFIG.api.getTopParentCustomerList}`,
      data: {
        tumorType: indicationType,
        territories: selectedGeoTerriroties,
        isPercentage: isCustomerTblPercent,
        accountType: "TopParent",
        fromDt: dateRangeStr[0],
        toDt: dateRangeStr[1],
        limit: 1000,
        offSet: 0,
        top_parent_az_id: accountId,
      },
    })
      .then((response) => {
        if (response) {
          var r = checkUnauthorised(response);
          if (!r.status) {
            var spUnits = 0;
            var sdUnits = 0;
            response.data.map((res) => {
              res.key = makeid(12);
              spUnits = res.spUnits || 0;
              sdUnits = res.sdUnits || 0;
              res.spsdUnits = spUnits + sdUnits;
            });
            console.log(response);
            setHcpTblInfo(response.data);
            setCurrentTblData(response.data);
            setCurrentTblColumns(accountTblColumns);
          }
        }
        setIsListLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsListLoading(false);
      });
  };
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const goToDetailView = (id) => {
    navigate(`/account/${id}`);
  };

  const accountTblColumns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      width: 100,
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: 120,
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (text) => (
        <div
          style={{
            textAlign: "left",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      key: "cllPriority",
      title: "Cal-CLL Priority",
      dataIndex: "cllPriority",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.cllPriority.length - b.cllPriority.length,
    },
    {
      key: "mclPriority",
      title: "Cal-MCL Priority",
      dataIndex: "mclPriority",
      width: 60,
      sorter: true,
      sortDirections: ["descend", "ascend", "descend"],
      //  sorter: (a, b) => a.mclPriority.length - b.mclPriority.length,
    },
    {
      key: "spsdUnits",
      title: "SP/SD TRx",
      width: 50,
      dataIndex: "spsdUnits",
      sorter: (a, b) => a.spsdUnits - b.spsdUnits,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Click for details",
      key: "operation",
      width: 50,
      fixed: "right",
      render: (data, record) => (
        <div
          onClick={() => {
            goToDetailView(record.id);
          }}
        >
          <TableButtonWrapper>View</TableButtonWrapper>
        </div>
      ),
    },
  ];

  return (
    <CustomerListViewWrapper>
      <CardViewWrapper>
        <Spin spinning={isListLoading}>
          <Row>
            <Col xs={12} style={{ paddingBottom: 10 }}>
              <div className="timelineHeader">
                <Popover
                  placement="right"
                  content={
                    <div style={{ maxWidth: 500 }}>Affiliated Accounts</div>
                  }
                  title={""}
                  trigger={"click"}
                >
                  Affiliated Accounts <InfoCircleOutlined />
                </Popover>
              </div>
            </Col>
            <Col xs={12} style={{ paddingBottom: 10 }}>
              <div className="datePicker">
                <RangePicker
                  picker="week"
                  allowEmpty={[false, false]}
                  allowClear={false}
                  inputReadOnly={true}
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                  value={dateRange}
                  onChange={(dates, datesStr) => {
                    console.log(datesStr);
                    setDateRange(dates);
                    setDateRangeStr(datesStr);
                  }}
                  format={dateFormat}
                />
              </div>
            </Col>
            <Col xs={24}>
              <Table
                className="hcptbl"
                showSorterTooltip={false}
                columns={currentTblColumns}
                dataSource={currentTblData}
                pagination={false}
                scroll={{
                  y: 360,
                  x: window.innerWidth / 2 - 40,
                  scrollToFirstRowOnChange: false,
                }}
              />
            </Col>
          </Row>
        </Spin>
      </CardViewWrapper>
    </CustomerListViewWrapper>
  );
};

export default CustomerListView;
