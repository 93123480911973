import React, { useEffect, useState } from "react";
import "./TimelineBlock.styles.jsx";
import { TimelineBlockWrapper } from "./TimelineBlock.styles.jsx";
import TimelineTriggerType from "./TriggerType/TimelineTriggerType";

const TimelineBlock = (props) => {
  const monthColor = {
    jan: "#0070c2",
    feb: "#006881",
    mar: "#396301",
    apr: "#738900",
    may: "#b3b000",
    jun: "#ffb60f",
    jul: "#ff9e0f",
    aug: "#f67800",
    sep: "#e81400",
    oct: "#ba0001",
    nov: "#bb0073",
    dec: "#71379e",
  };

  const [moduleColor, setModuleColor] = useState("#e81400");

  useEffect(() => {
    setModuleColor(monthColor[props.month]);
  }, [props.month]);

  const getArrowHeight = () => {
    /*if(props.actData){
      var pp_activities_count =  (props.actData.pp_activities && Array.isArray(props.actData.pp_activities)) ? props.actData.pp_activities.length : 0;
      var npp_activities_count = (props.actData.npp_activities && Array.isArray(props.actData.npp_activities)) ? props.actData.npp_activities.length : 0;
      if(pp_activities_count >= npp_activities_count){
        return(pp_activities_count* 40 + 100);
      } else {
        return npp_activities_count*40 + 100;
      }
    }*/
    return props.maxNoActivities * 40 + 100;
  };

  return (
    <TimelineBlockWrapper>
      {
        <div className="timelineWrapper1">
          <div className="leftBox">
            {props.actData &&
              props.actData.triggers &&
              props.actData.triggers.map((d, idx) => (
                <TimelineTriggerType
                  key={idx}
                  text={d.name}
                  color="#830059"
                  triggerType="pp"
                  isActive={d.value}
                  count={d.count}
                  info={d.info || ""}
                />
              ))}
            {props.actData &&
              props.actData.pp_activities &&
              props.actData.pp_activities.map((d, idx) => (
                <TimelineTriggerType
                  key={idx}
                  text={d.name}
                  color={props.activities[d.name]}
                  triggerType="pp"
                  isActive={
                    d.product &&
                    d.product != "" &&
                    (d.product.includes("Calquence") ||
                      d.product.includes("CALQUENCE"))
                  }
                  count={d.count}
                  info={d.info || ""}
                />
              ))}
          </div>
          <div
            className="downArrow"
            style={{
              height: getArrowHeight(),
              background: moduleColor,
            }}
          >
            <div className="title">{props.month}</div>
            <div className="subTitle">{props.year}</div>
            <div
              className="downArrowAfter"
              style={{ borderTop: `10px solid ${moduleColor}` }}
            ></div>
          </div>
          <div className="rightBox">
            {props.actData &&
              props.actData.npp_activities &&
              props.actData.npp_activities.map((d, idx) => (
                <TimelineTriggerType
                  key={idx}
                  text={d.name}
                  color={props.activities[d.name]}
                  triggerType="npp"
                  isActive={false}
                  count={d.count}
                  info={d.info || ""}
                />
              ))}
          </div>
        </div>
      }
      {/*
        <div className="timelineWrapperH">
          <div className="topBox">
          {props.actData &&
              props.actData.triggers &&
              props.actData.triggers.map((d, idx) => (
                <TimelineTriggerType
                  key={idx}
                  text={"Trigger - " + d.name}
                  color="#830059"
                  triggerType="pp"
                  isActive={d.value}
                  count={d.count}
                  info={d.info || ""}
                />
              ))}
            {props.actData &&
              props.actData.pp_activities &&
              props.actData.pp_activities.map((d, idx) => (
                <TimelineTriggerType
                  key={idx}
                  text={d.name}
                  color={props.activities[d.name]}
                  triggerType="pp"
                  isActive={d.value}
                  count={d.count}
                  info={d.info || ""}
                />
              ))}
          </div>
          <div className="rightArrowWrapper">
            <div className="rightArrow" style={{ background: moduleColor }}>
              <div className="title">{props.month}</div>
              <div className="subTitle">{props.year}</div>

              <div
                className="rightArrowAfter"
                style={{ borderLeft: `30px solid ${moduleColor}` }}
              ></div>
            </div>
          </div>
          <div className="downBox">
            
            {props.actData &&
              props.actData.npp_activities &&
              props.actData.npp_activities.map((d, idx) => (
                <TimelineTriggerType
                  key={idx}
                  text={d.name}
                  color={props.activities[d.name]}
                  triggerType="npp"
                  isActive={d.value}
                  count={d.count}
                  info={d.info || ""}
                />
              ))}
          </div>
        </div>
      */}
    </TimelineBlockWrapper>
  );
};

export default TimelineBlock;
